import { Box, CircularProgress, Grid, Stack, Typography } from '@mui/material'
import { MediaItem } from '../../hooks/useMedia'
import MediaThumbnail from '../MediaThumbnail/MediaThumbnail'

interface MediaGridProps {
    mediaItems: MediaItem[]
    isLoading: boolean
    onItemClick: (item: MediaItem) => void
}

const MediaGrid = ({ mediaItems, isLoading, onItemClick }: MediaGridProps) => {
    if (isLoading) {
        return (
            <Stack alignItems="center" justifyContent="center" height="100%" py={4}>
                <CircularProgress />
            </Stack>
        )
    }

    if (!mediaItems.length) {
        return (
            <Stack alignItems="center" justifyContent="center" height="100%" py={4}>
                <Typography color="text.secondary">No media files found</Typography>
            </Stack>
        )
    }

    return (
        <Box sx={{ p: 2, overflowY: 'auto' }}>
            <Grid container spacing={1}>
                {mediaItems.map((item) => (
                    <Grid 
                        key={item.id} 
                        item 
                        xs={4}
                        sm={3}
                        md={2}
                        lg={1}
                        sx={{
                            aspectRatio: '1',
                        }}
                    >
                        <Box
                            onClick={() => onItemClick(item)}
                            sx={{
                                width: '100%',
                                height: '100%',
                                cursor: 'pointer'
                            }}
                        >
                            <MediaThumbnail
                                item={{
                                    id: item.id,
                                    type: item.type,
                                    name: item.name,
                                    date: item.date,
                                    size: item.size,
                                    url: item.url
                                }}
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    transition: 'transform 0.2s ease-in-out',
                                    '&:hover': {
                                        transform: 'scale(1.05)'
                                    }
                                }}
                            />
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    )
}

export default MediaGrid 