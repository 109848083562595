import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import SettingsIcon from '@mui/icons-material/Settings'
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Dialog,
    IconButton,
    InputAdornment,
    Snackbar,
    Stack,
    Tab,
    Tabs,
    TextField,
    Typography,
    useTheme
} from '@mui/material'
import { QueryClient, useQuery, useQueryClient } from '@tanstack/react-query'
import { format } from 'date-fns-tz'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { requestSession } from '../../api/session'
import { checkAccountStatus, createConnectAccount, disconnectAccount } from '../../api/stripe'
import { getCurrentUser, updateAvailability, updateUser, type User } from '../../api/user'
import { useMessage } from '../../contexts/MessageContext'
import { routeEnum } from '../../routes'
import { ConversationResponse } from '../../types/conversation'
import { api } from '../../util/api'
import { uploadFile } from '../../util/fileUpload'
import EditSession from '../EditSession/EditSession'
import StripeConnect from '../StripeConnect/StripeConnect'
import UserAvatar from '../UserAvatar/UserAvatar'
import AvailabilityModal from './AvailabilityModal'
import { TimeZone, timeZones } from './TimeZoneModal'

interface ProfileFormData {
    firstName: string
    lastName: string
    newProfilePicture?: {
        file?: File
        previewUrl?: string
        key?: string
        url?: string
    }
    stripeConnected: boolean
    availabilityData?: any
    rate?: number
}

interface ProfileHeaderProps {
    title: string
    leftIcon: React.ReactNode
    rightIcon: React.ReactNode
    onLeftClick?: () => void
    onRightClick?: () => void
    avatar: {
        src?: string | null
        alt?: string
        userId?: string
    }
    showEditButton?: boolean
    isUploading?: boolean
    onAvatarEdit?: () => void
}

const ProfileHeader = ({
    title,
    leftIcon,
    rightIcon,
    onLeftClick,
    onRightClick,
    avatar,
    showEditButton,
    isUploading,
    onAvatarEdit
}: ProfileHeaderProps) => {
    const theme = useTheme()

    return (
        <>
            {/* Background with gradient and arc */}
            <Box
                sx={{
                    width: '100%',
                    height: 150,
                    background: 'linear-gradient(45deg, #000000 0%, #1a1a1a 100%)',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    overflow: 'hidden',
                    '&::after': {
                        content: '""',
                        position: 'absolute',
                        bottom: 0,
                        left: '-10%',
                        width: '120%',
                        height: 60,
                        background: theme.palette.background.default,
                        borderRadius: '50% 50% 0 0',
                        transform: 'translateY(50%)',
                    }
                }}
            />

            {/* Header */}
            <Stack
                direction="row"
                width="100%"
                justifyContent="space-between"
                px={3}
                pt={2}
                sx={{ position: 'relative', zIndex: 1 }}
            >
                <IconButton onClick={onLeftClick} sx={{ color: 'white' }}>
                    {leftIcon}
                </IconButton>
                <Typography
                    variant="h6"
                    sx={{
                        color: 'white',
                        letterSpacing: '1px',
                        fontSize: '1rem',
                        fontWeight: 500
                    }}
                >
                    {title}
                </Typography>
                <IconButton onClick={onRightClick} sx={{ color: 'white' }}>
                    {rightIcon}
                </IconButton>
            </Stack>

            {/* Content Container */}
            <Stack
                alignItems="center"
                spacing={2}
                sx={{
                    width: '100%',
                    maxWidth: 600,
                    position: 'relative',
                    zIndex: 1,
                    mt: '15px',
                    px: 2
                }}
            >
                {/* Avatar */}
                <Box
                    sx={{
                        position: 'relative',
                        mb: 2,
                        '&::after': {
                            content: '""',
                            position: 'absolute',
                            top: -3,
                            left: -3,
                            right: -3,
                            bottom: -3,
                            borderRadius: '50%',
                            background: theme.palette.background.default,
                            zIndex: -1
                        }
                    }}
                >
                    <UserAvatar
                        src={avatar.src}
                        alt={avatar.alt}
                        userId={avatar.userId}
                        size={100}
                        showOnlineStatus={false}
                    />
                    {showEditButton && (
                        <IconButton
                            onClick={onAvatarEdit}
                            disabled={isUploading}
                            sx={{
                                position: 'absolute',
                                bottom: -4,
                                right: -4,
                                bgcolor: 'background.paper',
                                boxShadow: 2,
                                width: 32,
                                height: 32,
                                '&:hover': {
                                    bgcolor: 'background.paper',
                                    transform: 'scale(1.05)'
                                },
                                transition: 'transform 0.2s',
                                zIndex: 2
                            }}
                        >
                            {isUploading ? (
                                <CircularProgress size={16} />
                            ) : (
                                <AddAPhotoIcon sx={{ fontSize: 16 }} />
                            )}
                        </IconButton>
                    )}
                </Box>
            </Stack>
        </>
    )
}

const ViewMode = ({ user, onEdit, isOwnProfile, onClose, currentTab, setCurrentTab }: { user: any, onEdit: () => void, isOwnProfile: boolean, onClose: () => void, currentTab: number, setCurrentTab: React.Dispatch<React.SetStateAction<number>> }) => {
    const navigate = useNavigate()
    const [isEditSessionOpen, setIsEditSessionOpen] = useState(false)
    const queryClient = useQueryClient()
    const { openMessage } = useMessage()

    // Convert backend availability format to the same format as EditMode
    const availabilityData = React.useMemo(() => {
        if (!user?.availability || user.availability.length === 0) return null;

        const firstAvailability = user.availability[0];
        const dayTimeRanges: { [key: number]: { start: number; end: number } } = {};
        const selectedDays: number[] = [];
        let useSameHours = true;
        let prevStart = firstAvailability.startHour;
        let prevEnd = firstAvailability.endHour;

        user.availability.forEach((avail: { dayOfWeek: number; startHour: number; endHour: number; timeZoneId: string }) => {
            selectedDays.push(avail.dayOfWeek);
            dayTimeRanges[avail.dayOfWeek] = {
                start: avail.startHour,
                end: avail.endHour
            };
            // Check if all days have the same hours
            if (avail.startHour !== prevStart || avail.endHour !== prevEnd) {
                useSameHours = false;
            }
        });

        const findTimeZoneName = (ianaId: string): TimeZone => {
            for (const [_, zones] of Object.entries(timeZones)) {
                const zone = zones.find(z => z.ianaId === ianaId)
                if (zone) {
                    return zone
                }
            }
            // Fallback to using date-fns-tz if no match found
            return {
                name: format(new Date(), 'zzz', { timeZone: ianaId }),
                shortName: format(new Date(), 'z', { timeZone: ianaId }),
                ianaId: ianaId,
                offset: 0 // Default offset since we can't easily determine it
            }
        }

        return {
            selectedDays: selectedDays.sort((a, b) => a - b),
            dayTimeRanges,
            selectedTimeZone: findTimeZoneName(firstAvailability.timeZoneId),
            useSameHours
        };
    }, [user?.availability]);

    const formatTimeRange = (start: number, end: number) => {
        const formatHour = (hour: number) => {
            if (hour === 0) return '12 AM'
            if (hour < 12) return `${hour} AM`
            if (hour === 12) return '12 PM'
            return `${hour - 12} PM`
        }
        return `${formatHour(start)} - ${formatHour(end)}`
    }

    const getDayLabel = (dayNum: number) => {
        const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
        return days[dayNum]
    }

    const handleMessage = async () => {
        try {
            // Try to find an existing conversation
            const response = await api.get<ConversationResponse>(`/messages/find/${user.id}`)
            // Use openMessage function from context
            openMessage({
                conversationId: response.id,
                user: {
                    id: user.id,
                    name: user.name,
                    avatar: user.avatar,
                    stripeConnected: user.stripeConnected
                }
            })
            onClose()
        } catch (error) {
            // If no conversation exists, create a new one
            openMessage({
                conversationId: `temp-${user.id}`,
                user: {
                    id: user.id,
                    name: user.name,
                    avatar: user.avatar,
                    stripeConnected: user.stripeConnected
                }
            })
            onClose()
        }
    }

    const handleRequestSession = async (title: string) => {
        try {
            const session = await requestSession({
                title,
                participantIds: [user.id]
            })
            queryClient.invalidateQueries({ queryKey: ['sessions'] })
            navigate(`${routeEnum.SESSION.replace(':sessionId', session.id)}`)
        } catch (error) {
            console.error('Error requesting session:', error)
        }
    }

    return (
        <Stack width="100%" alignItems="center" spacing={0}>
            <ProfileHeader
                title=""
                leftIcon={<ArrowBackIcon />}
                rightIcon={isOwnProfile ? <SettingsIcon /> : <React.Fragment />}
                onLeftClick={() => onClose()}
                onRightClick={isOwnProfile ? onEdit : undefined}
                avatar={{
                    src: user?.avatar,
                    alt: user?.name,
                    userId: user?.id
                }}
            />

            <Stack pt={2}>
                <Stack gap={2}>
                    <Stack spacing={1} alignItems="center">
                        <Typography variant="h5" fontWeight={600}>
                            {user?.name}
                        </Typography>
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ letterSpacing: '0.5px' }}
                        >
                            Artist & Producer
                        </Typography>
                    </Stack>
                </Stack>

                {/* Action Buttons */}
                {!isOwnProfile && (
                    <Stack
                        direction="row"
                        spacing={2}
                        justifyContent="center"
                        sx={{ my: 2 }}
                    >
                        <Button
                            variant="contained"
                            onClick={() => setIsEditSessionOpen(true)}
                            sx={{
                                px: 4,
                                borderRadius: 3,
                                bgcolor: '#000',
                                '&:hover': {
                                    bgcolor: '#222'
                                }
                            }}
                        >
                            BOOK
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={handleMessage}
                            sx={{
                                px: 4,
                                borderRadius: 3,
                                borderColor: '#000',
                                color: '#000',
                                '&:hover': {
                                    borderColor: '#222',
                                    bgcolor: 'rgba(0,0,0,0.04)'
                                }
                            }}
                        >
                            MESSAGE
                        </Button>
                    </Stack>
                )}
            </Stack>

            {/* Tabs */}
            <Box sx={{ width: '100%', mt: 2 }}>
                <Tabs
                    value={currentTab}
                    onChange={(_, newValue) => setCurrentTab(newValue)}
                    centered
                    sx={{
                        borderBottom: 1,
                        borderColor: 'divider',
                        '& .MuiTab-root': {
                            textTransform: 'none',
                            fontWeight: 500,
                            fontSize: '1rem'
                        }
                    }}
                >
                    <Tab label="About" />
                    <Tab label="Availability & Rates" />
                    {isOwnProfile && <Tab label="Payments" />}
                </Tabs>
            </Box>

            {/* Tab Content */}
            <Box sx={{ width: '100%', mt: 3, px: 3 }}>
                {currentTab === 0 && (
                    <Stack spacing={3}>
                        <Box sx={{
                            width: '100%',
                            maxWidth: 600,
                            borderRadius: 2,
                            overflow: 'hidden',
                            mt: 2
                        }}>
                            <iframe
                                style={{ borderRadius: '12px' }}
                                src="https://open.spotify.com/embed/artist/12SYNgrdkLLVNosJh5REUt?utm_source=generator&theme=0"
                                width="100%"
                                height="352"
                                frameBorder="0"
                                allowFullScreen
                                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                                loading="lazy"
                            />
                        </Box>
                    </Stack>
                )}
                {currentTab === 1 && (
                    <Stack spacing={4} width="100%" maxWidth={600} mx="auto">
                        <Stack spacing={3}>
                            <Typography variant="h6" fontWeight={500}>
                                Availability
                            </Typography>
                            {availabilityData ? (
                                <Box sx={{
                                    p: 3,
                                    borderRadius: 2,
                                    bgcolor: 'grey.50',
                                    border: '1px solid',
                                    borderColor: 'divider'
                                }}>
                                    <Stack spacing={2}>
                                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                                            <Typography variant="subtitle1" fontWeight={500}>
                                                {availabilityData.useSameHours ? 'Available Hours' : 'Available Hours by Day'}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {availabilityData.selectedTimeZone.name} ({availabilityData.selectedTimeZone.shortName})
                                            </Typography>
                                        </Stack>
                                        {availabilityData.useSameHours ? (
                                            <Typography>
                                                {availabilityData.selectedDays.length === 5 && availabilityData.selectedDays.every((d: number) => d >= 1 && d <= 5)
                                                    ? "Monday - Friday"
                                                    : availabilityData.selectedDays.length === 7
                                                        ? "Every day"
                                                        : availabilityData.selectedDays
                                                            .map((d: number) => getDayLabel(d))
                                                            .join(", ")}: {formatTimeRange(
                                                                availabilityData.dayTimeRanges[availabilityData.selectedDays[0]].start,
                                                                availabilityData.dayTimeRanges[availabilityData.selectedDays[0]].end
                                                            )}
                                            </Typography>
                                        ) : (
                                            <Stack spacing={1}>
                                                {availabilityData.selectedDays.map((day: number) => (
                                                    <Stack key={day} direction="row" spacing={1}>
                                                        <Typography sx={{ width: 40 }}>{getDayLabel(day)}</Typography>
                                                        <Typography>
                                                            {formatTimeRange(
                                                                availabilityData.dayTimeRanges[day].start,
                                                                availabilityData.dayTimeRanges[day].end
                                                            )}
                                                        </Typography>
                                                    </Stack>
                                                ))}
                                            </Stack>
                                        )}
                                    </Stack>
                                </Box>
                            ) : (
                                <Typography color="text.secondary">
                                    No availability set
                                </Typography>
                            )}
                        </Stack>

                        <Stack spacing={3}>
                            <Typography variant="h6" fontWeight={500}>
                                Hourly Rate
                            </Typography>
                            {user?.rate ? (
                                <Box sx={{
                                    p: 3,
                                    borderRadius: 2,
                                    bgcolor: 'grey.50',
                                    border: '1px solid',
                                    borderColor: 'divider'
                                }}>
                                    <Typography variant="h5" fontWeight={600} sx={{ display: 'flex', alignItems: 'center' }}>
                                        ${user.rate}
                                        <Typography variant="body1" color="text.secondary" sx={{ ml: 1 }}>
                                            per hour
                                        </Typography>
                                    </Typography>
                                </Box>
                            ) : (
                                <Typography color="text.secondary">
                                    No rate set
                                </Typography>
                            )}
                        </Stack>
                    </Stack>
                )}
                {currentTab === 2 && isOwnProfile && (
                    <Stack spacing={3} mb={4} width="100%">
                        <Typography
                            variant="h6"
                            sx={{
                                mb: 2,
                                fontSize: '1.1rem',
                                fontWeight: 500
                            }}
                        >
                            Payment Settings
                        </Typography>
                        <StripeConnect
                            isConnected={user?.stripeConnected || false}
                            onConnect={async () => ({ accountId: '', accountLink: '' })}
                            onDisconnect={async () => ({ success: true, message: '' })}
                            loading={false}
                        />
                    </Stack>
                )}
            </Box>

            {/* EditSession Modal */}
            <EditSession
                open={isEditSessionOpen}
                onClose={() => setIsEditSessionOpen(false)}
                initialParticipants={[{
                    id: user.id,
                    name: user.name,
                    avatar: user.avatar,
                    stripeConnected: user.stripeConnected
                }]}
                onSubmit={handleRequestSession}
            />
        </Stack>
    )
}

interface EditModeProps {
    user: any
    formData: ProfileFormData
    setFormData: (data: ProfileFormData | ((prev: ProfileFormData) => ProfileFormData)) => void
    isUploading: boolean
    uploadError: string | null
    handleFileSelect: (event: React.ChangeEvent<HTMLInputElement>) => void
    handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    onSave: () => void
    onCancel: () => void
    fileInputRef: React.RefObject<HTMLInputElement>
    queryClient: QueryClient
    setIsEditing: (value: boolean) => void
    initialTab: number
    setCurrentTab: React.Dispatch<React.SetStateAction<number>>
    refetchProfile: () => Promise<any>
}

const EditMode = ({
    user,
    formData,
    setFormData,
    isUploading,
    uploadError,
    handleFileSelect,
    handleInputChange,
    onSave,
    fileInputRef,
    queryClient,
    setIsEditing,
    initialTab,
    setCurrentTab,
    refetchProfile
}: EditModeProps) => {
    const [isConnectingStripe, setIsConnectingStripe] = useState(false)
    const [isAvailabilityModalOpen, setIsAvailabilityModalOpen] = useState(false)
    const [snackbar, setSnackbar] = useState<{
        open: boolean;
        message: string;
        severity: 'success' | 'error' | 'info';
    }>({
        open: false,
        message: '',
        severity: 'info'
    })

    const handleExitEdit = async () => {
        // First invalidate and refetch the queries
        await Promise.all([
            queryClient.invalidateQueries({ queryKey: ['user', user?.id] }),
            queryClient.invalidateQueries({ queryKey: ['currentUser'] })
        ]);
        
        // Wait for the refetch to complete
        await refetchProfile();
        
        // Only after the refetch is complete, update the state
        setIsEditing(false);
    };

    const formatTimeRange = (start: number, end: number) => {
        const formatHour = (hour: number) => {
            if (hour === 0) return '12 AM'
            if (hour < 12) return `${hour} AM`
            if (hour === 12) return '12 PM'
            return `${hour - 12} PM`
        }
        return `${formatHour(start)} - ${formatHour(end)}`
    }

    const getDayLabel = (dayNum: number) => {
        const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
        return days[dayNum]
    }

    const handleStripeConnect = async () => {
        setIsConnectingStripe(true)
        try {
            const response = await createConnectAccount()
            return {
                accountId: response.accountId,
                accountLink: response.accountLink
            }
        } catch (error) {
            console.error('Failed to create Stripe Connect account:', error)
            setSnackbar({
                open: true,
                message: 'Failed to connect bank account',
                severity: 'error'
            })
            throw error
        } finally {
            setIsConnectingStripe(false)
        }
    }

    const handleStripeDisconnect = async () => {
        setIsConnectingStripe(true)
        try {
            const response = await disconnectAccount()
            if (response.success) {
                setFormData(prev => ({
                    ...prev,
                    stripeConnected: false
                }))
                setSnackbar({
                    open: true,
                    message: 'Bank account disconnected successfully',
                    severity: 'success'
                })
                await queryClient.invalidateQueries({ queryKey: ['currentUser'] })
                setIsEditing(false) // Close edit mode after successful disconnect
            } else {
                throw new Error(response.message)
            }
        } catch (error) {
            console.error('Failed to disconnect Stripe account:', error)
            setSnackbar({
                open: true,
                message: 'Failed to disconnect bank account',
                severity: 'error'
            })
        } finally {
            setIsConnectingStripe(false)
        }
    }

    const handleFormSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        onSave()
    }

    return (
        <>
            <Stack
                component="form"
                onSubmit={handleFormSubmit}
                width="100%"
                alignItems="center"
                spacing={0}
                p={0}
                pb={3}
            >
                <ProfileHeader
                    title=""
                    leftIcon={<ArrowBackIcon />}
                    rightIcon={
                        <Typography
                            variant="button"
                            sx={{
                                fontSize: '0.875rem',
                                fontWeight: 500,
                                letterSpacing: '0.5px'
                            }}
                        >
                            SAVE
                        </Typography>
                    }
                    onLeftClick={handleExitEdit}
                    onRightClick={onSave}
                    avatar={{
                        src: formData.newProfilePicture?.previewUrl || formData.newProfilePicture?.url || user?.avatar,
                        alt: user?.name,
                        userId: user?.id
                    }}
                    showEditButton
                    isUploading={isUploading}
                    onAvatarEdit={() => fileInputRef.current?.click()}
                />

                <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileSelect}
                    style={{ display: 'none' }}
                    accept="image/*"
                />

                {/* Tabs */}
                <Box sx={{ width: '100%', mt: 2 }}>
                    <Tabs
                        value={initialTab}
                        onChange={(_, newValue) => setCurrentTab(newValue)}
                        centered
                        sx={{
                            borderBottom: 1,
                            borderColor: 'divider',
                            '& .MuiTab-root': {
                                textTransform: 'none',
                                fontWeight: 500,
                                fontSize: '1rem'
                            }
                        }}
                    >
                        <Tab label="About" />
                        <Tab label="Availability & Rates" />
                        <Tab label="Payments" />
                    </Tabs>
                </Box>

                <Stack pt={4} width="100%" alignItems="center">
                    <Stack
                        alignItems="center"
                        spacing={3}
                        sx={{
                            width: '100%',
                            maxWidth: 600,
                            px: 3
                        }}
                    >
                        {uploadError && (
                            <Typography color="error" variant="caption" textAlign="center">
                                {uploadError}
                            </Typography>
                        )}

                        {initialTab === 0 && (
                            <Stack spacing={2.5} width="100%">
                                <TextField
                                    label="First Name"
                                    name="firstName"
                                    value={formData.firstName}
                                    onChange={handleInputChange}
                                    fullWidth
                                    variant="outlined"
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            bgcolor: 'rgba(0, 0, 0, 0.02)',
                                            borderRadius: 2,
                                            '&:hover': {
                                                bgcolor: 'rgba(0, 0, 0, 0.04)'
                                            }
                                        }
                                    }}
                                />
                                <TextField
                                    label="Last Name"
                                    name="lastName"
                                    value={formData.lastName}
                                    onChange={handleInputChange}
                                    fullWidth
                                    variant="outlined"
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            bgcolor: 'rgba(0, 0, 0, 0.02)',
                                            borderRadius: 2,
                                            '&:hover': {
                                                bgcolor: 'rgba(0, 0, 0, 0.04)'
                                            }
                                        }
                                    }}
                                />
                            </Stack>
                        )}

                        {initialTab === 1 && (
                            <Stack spacing={4} width="100%" maxWidth={600} mx="auto">
                                <Stack spacing={3}>
                                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                                        <Typography variant="h6" fontWeight={500}>
                                            Availability
                                        </Typography>
                                        <Button
                                            variant="outlined"
                                            onClick={() => setIsAvailabilityModalOpen(true)}
                                            sx={{
                                                borderRadius: 2,
                                                borderColor: 'divider',
                                                color: 'text.primary',
                                                '&:hover': {
                                                    borderColor: 'text.primary',
                                                    bgcolor: 'rgba(0, 0, 0, 0.04)'
                                                }
                                            }}
                                        >
                                            Manage your availability
                                        </Button>
                                    </Stack>
                                    {formData.availabilityData ? (
                                        <Box sx={{
                                            p: 3,
                                            borderRadius: 2,
                                            bgcolor: 'grey.50',
                                            border: '1px solid',
                                            borderColor: 'divider'
                                        }}>
                                            <Stack spacing={2}>
                                                <Stack direction="row" justifyContent="space-between" alignItems="center">
                                                    <Typography variant="subtitle1" fontWeight={500}>
                                                        {formData.availabilityData.useSameHours ? 'Available Hours' : 'Available Hours by Day'}
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary">
                                                        {formData.availabilityData.selectedTimeZone.name} ({formData.availabilityData.selectedTimeZone.shortName})
                                                    </Typography>
                                                </Stack>
                                                {formData.availabilityData.useSameHours ? (
                                                    <Typography>
                                                        {formData.availabilityData.selectedDays.length === 5 && formData.availabilityData.selectedDays.every((d: number) => d >= 1 && d <= 5)
                                                            ? "Monday - Friday"
                                                            : formData.availabilityData.selectedDays.length === 7
                                                                ? "Every day"
                                                                : formData.availabilityData.selectedDays
                                                                    .map((d: number) => getDayLabel(d))
                                                                    .join(", ")}: {formatTimeRange(
                                                                        formData.availabilityData.dayTimeRanges[formData.availabilityData.selectedDays[0]].start,
                                                                        formData.availabilityData.dayTimeRanges[formData.availabilityData.selectedDays[0]].end
                                                                    )}
                                                    </Typography>
                                                ) : (
                                                    <Stack spacing={1}>
                                                        {formData.availabilityData.selectedDays.map((day: number) => (
                                                            <Stack key={day} direction="row" spacing={1}>
                                                                <Typography sx={{ width: 40 }}>{getDayLabel(day)}</Typography>
                                                                <Typography>
                                                                    {formatTimeRange(
                                                                        formData.availabilityData.dayTimeRanges[day].start,
                                                                        formData.availabilityData.dayTimeRanges[day].end
                                                                    )}
                                                                </Typography>
                                                            </Stack>
                                                        ))}
                                                    </Stack>
                                                )}
                                            </Stack>
                                        </Box>
                                    ) : (
                                        <Typography color="text.secondary"></Typography>
                                    )}
                                </Stack>

                                <Stack spacing={3}>
                                    <Typography variant="h6" fontWeight={500}>
                                        Hourly Rate
                                    </Typography>
                                    <TextField
                                        label="Rate (USD per hour)"
                                        name="rate"
                                        type="number"
                                        value={formData.rate || ''}
                                        onChange={handleInputChange}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                        }}
                                        fullWidth
                                        variant="outlined"
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                bgcolor: 'rgba(0, 0, 0, 0.02)',
                                                borderRadius: 2,
                                                '&:hover': {
                                                    bgcolor: 'rgba(0, 0, 0, 0.04)'
                                                }
                                            }
                                        }}
                                    />
                                </Stack>
                            </Stack>
                        )}

                        {initialTab === 2 && (
                            <Stack spacing={3} width="100%">
                                <Typography
                                    variant="h6"
                                    sx={{
                                        mb: 2,
                                        fontSize: '1.1rem',
                                        fontWeight: 500
                                    }}
                                >
                                    Payment Settings
                                </Typography>
                                <StripeConnect
                                    isConnected={formData.stripeConnected || false}
                                    onConnect={handleStripeConnect}
                                    onDisconnect={handleStripeDisconnect}
                                    loading={isConnectingStripe}
                                />
                            </Stack>
                        )}
                    </Stack>
                </Stack>
            </Stack>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
                    severity={snackbar.severity}
                    sx={{ width: '100%' }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>

            <AvailabilityModal
                open={isAvailabilityModalOpen}
                onClose={() => setIsAvailabilityModalOpen(false)}
                onSave={(data) => {
                    setFormData(prev => ({
                        ...prev,
                        availabilityData: data
                    }))
                    setIsAvailabilityModalOpen(false)
                }}
            />
        </>
    )
}

interface ExtendedUser extends User {
    firstName: string
    lastName: string
    rate?: number
}

interface ProfileModalProps {
    open: boolean
    onClose: () => void
    userId?: string
    isEditing?: boolean
}

const ProfileModal = ({ open, onClose, userId, isEditing: initialIsEditing = false }: ProfileModalProps) => {
    const queryClient = useQueryClient()
    const fileInputRef = useRef<HTMLInputElement>(null)
    const [isUploading, setIsUploading] = useState(false)
    const [uploadError, setUploadError] = useState<string | null>(null)
    const [isEditing, setIsEditing] = useState(initialIsEditing)
    const [currentTab, setCurrentTab] = useState(0)
    const [searchParams] = useSearchParams()
    const [isProcessingStripe, setIsProcessingStripe] = useState(false)
    const [snackbar, setSnackbar] = useState<{
        open: boolean;
        message: string;
        severity: 'success' | 'error' | 'info';
    }>({
        open: false,
        message: '',
        severity: 'info'
    })
    const [formData, setFormData] = useState<ProfileFormData>({
        firstName: '',
        lastName: '',
        stripeConnected: false
    })
    const navigate = useNavigate()

    // Fetch current user for comparison
    const { data: currentUser } = useQuery({
        queryKey: ['currentUser'],
        queryFn: getCurrentUser
    })

    // Fetch profile data based on userId
    const { data: profileUser, isLoading, error, refetch: refetchProfile } = useQuery<ExtendedUser>({
        queryKey: ['user', userId],
        queryFn: async () => {
            if (!userId) {
                const currentUser = await getCurrentUser()
                return {
                    ...currentUser,
                    firstName: currentUser.name.split(' ')[0],
                    lastName: currentUser.name.split(' ')[1]
                }
            }
            // Get the user profile
            const user = await api.get<User>(`/users/${userId}`)
            return {
                ...user,
                firstName: user.name.split(' ')[0],
                lastName: user.name.split(' ')[1]
            }
        },
        enabled: open,
        refetchOnMount: true,
        refetchOnWindowFocus: true
    })

    const isOwnProfile = Boolean(!userId || (currentUser && profileUser && currentUser.id === profileUser.id))

    // Handle Stripe Connect redirect
    useEffect(() => {
        const accountId = searchParams.get('accountId')
        if (accountId) {
            setIsProcessingStripe(true)
            const checkAccount = async () => {
                try {
                    const isReady = await checkAccountStatus(accountId)
                    if (isReady) {
                        setSnackbar({
                            open: true,
                            message: 'Bank account connected successfully!',
                            severity: 'success'
                        })
                        // Invalidate the user query to fetch fresh data
                        await queryClient.invalidateQueries({ queryKey: ['currentUser'] })
                        // Clear the accountId from the URL
                        navigate('/profile/edit', { replace: true })
                    } else {
                        setSnackbar({
                            open: true,
                            message: 'Bank account connection incomplete. Please try again.',
                            severity: 'error'
                        })
                    }
                } catch (error) {
                    console.error('Failed to check account status:', error)
                    setSnackbar({
                        open: true,
                        message: 'Failed to verify bank account connection.',
                        severity: 'error'
                    })
                } finally {
                    setIsProcessingStripe(false)
                }
            }
            checkAccount()
        }
    }, [searchParams, navigate, queryClient])

    // Update editing state when route changes
    useEffect(() => {
        setIsEditing(initialIsEditing)
    }, [initialIsEditing])

    useEffect(() => {
        if (profileUser) {
            console.log('User data received:', {
                stripeConnected: profileUser.stripeConnected,
                name: profileUser.name,
                id: profileUser.id,
                rate: profileUser.rate,
                availability: profileUser.availability
            })

            // Convert backend availability format to AvailabilityModal format
            let availabilityData: any = null
            if (profileUser.availability && profileUser.availability.length > 0) {
                const firstAvailability = profileUser.availability[0]
                const dayTimeRanges: { [key: number]: { start: number; end: number } } = {}
                const selectedDays: number[] = []
                let useSameHours = true
                let prevStart = firstAvailability.startHour
                let prevEnd = firstAvailability.endHour

                profileUser.availability.forEach(avail => {
                    selectedDays.push(avail.dayOfWeek)
                    dayTimeRanges[avail.dayOfWeek] = {
                        start: avail.startHour,
                        end: avail.endHour
                    }
                    // Check if all days have the same hours
                    if (avail.startHour !== prevStart || avail.endHour !== prevEnd) {
                        useSameHours = false
                    }
                })

                const findTimeZoneName = (ianaId: string): TimeZone => {
                    for (const [_, zones] of Object.entries(timeZones)) {
                        const zone = zones.find(z => z.ianaId === ianaId)
                        if (zone) {
                            return zone
                        }
                    }
                    // Fallback to using date-fns-tz if no match found
                    return {
                        name: format(new Date(), 'zzz', { timeZone: ianaId }),
                        shortName: format(new Date(), 'z', { timeZone: ianaId }),
                        ianaId: ianaId,
                        offset: 0 // Default offset since we can't easily determine it
                    }
                }

                availabilityData = {
                    selectedDays: selectedDays.sort((a, b) => a - b),
                    dayTimeRanges,
                    selectedTimeZone: findTimeZoneName(firstAvailability.timeZoneId),
                    useSameHours
                }
            }

            setFormData(prev => ({
                ...prev,
                firstName: profileUser.firstName || '',
                lastName: profileUser.lastName || '',
                stripeConnected: Boolean(profileUser.stripeConnected),
                availabilityData,
                rate: profileUser.rate
            }))
        }
    }, [profileUser])

    const handleFileSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0]
        if (!file) return

        // Reset input value to allow selecting the same file again
        event.target.value = ''

        if (file.size > 10 * 1024 * 1024) { // 10MB limit
            setUploadError('File size must be less than 10MB')
            return
        }

        if (!file.type.startsWith('image/')) {
            setUploadError('Please select an image file')
            return
        }

        setUploadError(null)

        // Create a preview URL for the selected file
        const previewUrl = URL.createObjectURL(file)

        setFormData(prev => ({
            ...prev,
            newProfilePicture: {
                file,
                previewUrl
            }
        }))
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        setFormData(prev => ({
            ...prev,
            [name]: value || '' // Ensure empty string instead of undefined
        }))
    }

    const handleCancel = () => {
        setIsEditing(false)
        onClose()
    }

    const handleEdit = () => {
        setIsEditing(true)
    }

    const handleSave = async () => {
        try {
            setIsUploading(true)

            // Upload the image if there's a new file selected
            let profilePictureKey = undefined
            if (formData.newProfilePicture?.file) {
                const uploadResponse = await uploadFile(formData.newProfilePicture.file, 'profile-pictures')
                profilePictureKey = uploadResponse.key
            }

            // Save user profile data
            await updateUser({
                firstName: formData.firstName,
                lastName: formData.lastName,
                profilePicture: profilePictureKey || formData.newProfilePicture?.key,
                rate: formData.rate
            })

            // Save availability data if it exists
            if (formData.availabilityData) {
                await updateAvailability(formData.availabilityData)
            }

            // Invalidate queries
            await Promise.all([
                queryClient.invalidateQueries({ queryKey: ['currentUser'] }),
                queryClient.invalidateQueries({ queryKey: ['user', userId] })
            ]);
            
            // Wait for the refetch to complete
            await refetchProfile();

            // Clean up the preview URL to prevent memory leaks
            if (formData.newProfilePicture?.previewUrl) {
                URL.revokeObjectURL(formData.newProfilePicture.previewUrl)
            }

            setFormData(prev => ({
                ...prev,
                newProfilePicture: undefined
            }))
            
            // Only after refetch is complete, update state and close
            setIsEditing(false)
            onClose()
        } catch (error) {
            console.error('Failed to update profile:', error)
        } finally {
            setIsUploading(false)
        }
    }

    if (isProcessingStripe) {
        return (
            <Dialog
                open={open}
                onClose={onClose}
                fullScreen
                PaperProps={{
                    sx: {
                        bgcolor: 'background.default'
                    }
                }}
            >
                <Stack
                    height="100vh"
                    alignItems="center"
                    justifyContent="center"
                    spacing={3}
                    sx={{ bgcolor: 'background.default' }}
                >
                    <CircularProgress size={40} />
                    <Typography variant="body1" color="text.secondary">
                        Processing bank account connection...
                    </Typography>
                </Stack>
                <Snackbar
                    open={snackbar.open}
                    autoHideDuration={6000}
                    onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
                >
                    <Alert
                        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
                        severity={snackbar.severity}
                    >
                        {snackbar.message}
                    </Alert>
                </Snackbar>
            </Dialog>
        )
    }

    if (isLoading) {
        return (
            <Dialog
                open={open}
                onClose={onClose}
                fullScreen
                PaperProps={{
                    sx: {
                        bgcolor: 'background.default'
                    }
                }}
            >
                <Stack
                    height="100vh"
                    alignItems="center"
                    justifyContent="center"
                    spacing={3}
                    sx={{ bgcolor: 'background.default' }}
                >
                    <CircularProgress size={40} />
                </Stack>
            </Dialog>
        )
    }

    if (error) {
        return (
            <Dialog
                open={open}
                onClose={onClose}
                fullScreen
                PaperProps={{
                    sx: {
                        bgcolor: 'background.default'
                    }
                }}
            >
                <Stack p={3}>
                    <Typography color="error">Error loading profile</Typography>
                </Stack>
            </Dialog>
        )
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullScreen
            PaperProps={{
                sx: {
                    bgcolor: 'background.default'
                }
            }}
        >
            {isEditing && isOwnProfile ? (
                <EditMode
                    user={profileUser}
                    formData={formData}
                    setFormData={setFormData}
                    isUploading={isUploading}
                    uploadError={uploadError}
                    handleFileSelect={handleFileSelect}
                    handleInputChange={handleInputChange}
                    onSave={handleSave}
                    onCancel={handleCancel}
                    fileInputRef={fileInputRef}
                    queryClient={queryClient}
                    setIsEditing={setIsEditing}
                    initialTab={currentTab}
                    setCurrentTab={setCurrentTab}
                    refetchProfile={refetchProfile}
                />
            ) : (
                <ViewMode
                    user={profileUser}
                    onEdit={handleEdit}
                    isOwnProfile={isOwnProfile}
                    onClose={onClose}
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                />
            )}

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
            >
                <Alert
                    onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
                    severity={snackbar.severity}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Dialog>
    )
}

export default ProfileModal 