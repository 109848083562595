import AddIcon from '@mui/icons-material/Add'
import SearchIcon from '@mui/icons-material/Search'
import {
    Box,
    CircularProgress,
    Fab,
    InputAdornment,
    List,
    Stack,
    TextField,
    Typography
} from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { getSessions } from '../../api/session'
import ConversationListItem from '../../components/ConversationListItem/ConversationListItem'
import { useMessage } from '../../contexts/MessageContext'
import { useConversations } from '../../hooks/useConversations'
import { ConversationDto, ParticipantDto } from '../../types/conversation'
import { getCurrentUserId } from '../../util/api'

const Messages = () => {
    // All hooks must be called before any conditional returns
    const { conversations, isLoading: isLoadingConversations, error: conversationsError } = useConversations()
    const { data: sessions = [], isLoading: isLoadingSessions } = useQuery({
        queryKey: ['sessions'],
        queryFn: getSessions
    })
    const navigate = useNavigate()
    const currentUserId = getCurrentUserId()
    const [searchQuery, setSearchQuery] = useState('')
    const { openMessage, openCreateMessage } = useMessage()
    const searchParams = useSearchParams()[0]

    // Effect for handling direct navigation
    useEffect(() => {
        const messageId = searchParams.get('messageId')
        if (messageId && conversations) {
            const conversation = conversations.find((c: ConversationDto) => c.id === messageId)
            if (conversation) {
                handleConversationClick(conversation)
            }
        }
    }, [searchParams, conversations, sessions, currentUserId, openMessage])

    const handleConversationClick = (conversation: ConversationDto) => {
        const session = sessions.find(s => s.chatId === conversation.id)
        if (session) {
            openMessage({
                conversationId: conversation.id,
                title: session.title,
                participants: session.participants
            })
        } else {
            const otherParticipant = conversation.participants.find(p => p.id !== currentUserId)
            if (otherParticipant) {
                openMessage({
                    conversationId: conversation.id,
                    user: {
                        id: otherParticipant.id,
                        name: otherParticipant.name,
                        avatar: otherParticipant.avatar,
                        stripeConnected: otherParticipant.stripeConnected ?? false
                    },
                    participants: conversation.participants
                })
            }
        }
    }

    // Filter conversations based on search query
    const filteredConversations = conversations?.filter((conversation: ConversationDto) => {
        const otherParticipant = conversation.participants.find((p: ParticipantDto) => p.id !== currentUserId)
        return otherParticipant?.name.toLowerCase().includes(searchQuery.toLowerCase())
    })

    // Handle authentication
    useEffect(() => {
        if (!currentUserId) {
            navigate('/login')
        }
    }, [currentUserId, navigate])

    if (!currentUserId) {
        return null
    }

    if (isLoadingConversations || isLoadingSessions) {
        return (
            <Stack height="100%" alignItems="center" justifyContent="center">
                <CircularProgress />
            </Stack>
        )
    }

    if (conversationsError) {
        return (
            <Stack height="100%" alignItems="center" justifyContent="center">
                <Typography color="error">Failed to load conversations</Typography>
            </Stack>
        )
    }

    return (
        <Stack height="100%" position="relative">
            {/* Search Bar */}
            <Box sx={{ p: 2, pb: 1, bgcolor: 'background.paper' }}>
                <TextField
                    fullWidth
                    placeholder="Search messages"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon color="action" />
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            borderRadius: 3,
                            bgcolor: 'action.hover',
                            '& fieldset': {
                                border: 'none'
                            }
                        }
                    }}
                />
            </Box>

            {/* Conversations List */}
            <List sx={{ flex: 1, overflow: 'auto' }}>
                {filteredConversations?.map((conversation) => {
                    const hasUnreadMessage = conversation.lastMessage &&
                        conversation.lastMessage.senderId !== currentUserId &&
                        !conversation.lastMessage.isRead

                    return (
                        <Box
                            key={conversation.id}
                            sx={{
                                bgcolor: hasUnreadMessage ? 'action.hover' : 'transparent',
                                transition: 'background-color 0.2s ease'
                            }}
                        >
                            <ConversationListItem
                                conversation={conversation}
                                onClick={() => handleConversationClick(conversation)}
                            />
                        </Box>
                    )
                })}
            </List>

            <Fab
                color="primary"
                aria-label="add"
                onClick={openCreateMessage}
                sx={{
                    position: 'absolute',
                    bottom: 16,
                    right: 16,
                    zIndex: 10
                }}
            >
                <AddIcon />
            </Fab>
        </Stack>
    )
}

export default Messages 