// Log the environment variables to debug
console.log('Environment Variables:', {
    VITE_API_URL: import.meta.env.VITE_API_URL,
    MODE: import.meta.env.MODE,
    DEV: import.meta.env.DEV,
    PROD: import.meta.env.PROD,
})

// Ensure the API URL is properly formatted
const formatApiUrl = (url: string) => {
    // Remove trailing slashes
    return url.replace(/\/+$/, '')
}

const apiUrl = formatApiUrl(import.meta.env.VITE_API_URL || '')

if (!apiUrl) {
    throw new Error('API URL is not configured. Please set VITE_API_URL in your environment variables.')
}

// Log the final configuration
console.log('Final Configuration:', {
    apiUrl,
    wsUrl: `${apiUrl.replace('http://', 'ws://').replace('https://', 'wss://')}/ws`
})

export const config = {
    apiUrl,
    wsUrl: `${apiUrl.replace('http://', 'ws://').replace('https://', 'wss://')}/ws`
} as const

export type Config = typeof config 