import { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import ProfileModal from '../../components/Profile/ProfileModal'

const Profile = () => {
    const { userId } = useParams()
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const [isOpen, setIsOpen] = useState(true)
    const isEditing = searchParams.get('edit') === 'true'

    const handleClose = () => {
        setIsOpen(false)
        navigate('/')
    }

    useEffect(() => {
        setIsOpen(true)
    }, [userId])

    return (
        <ProfileModal
            open={isOpen}
            onClose={handleClose}
            userId={userId}
            isEditing={isEditing}
        />
    )
}

export default Profile 