import { AvatarGroup, styled } from '@mui/material'

export const CollaboratorAvatarGroup = styled(AvatarGroup)(() => ({
    '& .MuiAvatar-root': {
        width: 24,
        height: 24,
        fontSize: '0.75rem',
        border: '1.5px solid white',
        bgcolor: '#666',
    },
    '& .MuiAvatar-root:first-of-type': {
        zIndex: 0,
    },
    '& .MuiAvatar-root:not(:first-of-type)': {
        marginLeft: '-10px',
        zIndex: 1,
    }
})) 