import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import SearchIcon from '@mui/icons-material/Search'
import {
    Avatar,
    Box,
    CircularProgress,
    Dialog,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Pagination,
    Stack,
    TextField,
    Typography
} from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useState, useEffect } from 'react'
import { getUsers, searchUsers, type UserSearchResult } from '../../api/user'
import { ConversationResponse } from '../../types/conversation'
import { api } from '../../util/api'

interface CreateMessageProps {
    open: boolean
    onClose: () => void
    onOpenMessage: (params: { conversationId: string, user?: UserSearchResult }) => void
}

const CreateMessage = ({ open, onClose, onOpenMessage }: CreateMessageProps) => {
    const [searchQuery, setSearchQuery] = useState('')
    const [page, setPage] = useState(0)
    const pageSize = 20
    const [shouldClose, setShouldClose] = useState(false)

    useEffect(() => {
        if (shouldClose) {
            // Only close after the state has been updated and message modal is mounted
            const timer = setTimeout(() => {
                onClose()
                setShouldClose(false)
            }, 300)
            return () => clearTimeout(timer)
        }
    }, [shouldClose, onClose])

    const { data: searchResults = [], isLoading: isSearchLoading } = useQuery({
        queryKey: ['users', 'search', searchQuery],
        queryFn: () => searchUsers(searchQuery),
        enabled: searchQuery.length >= 2,
    })

    const { data: usersPage, isLoading: isUsersLoading } = useQuery({
        queryKey: ['users', 'paginated', page, pageSize],
        queryFn: () => getUsers(page, pageSize),
        enabled: searchQuery.length < 2,
    })

    const handleUserClick = async (user: UserSearchResult) => {
        console.debug('CreateMessage: User clicked:', user)
        try {
            // Try to find an existing conversation
            console.debug('CreateMessage: Attempting to find existing conversation with user:', user.id)
            const response = await api.get<ConversationResponse>(`/messages/find/${user.id}`)
            console.debug('CreateMessage: Found existing conversation:', response)
            onOpenMessage({
                conversationId: response.id,
                user
            })
        } catch (error) {
            // If no conversation exists, just pass the user info with a temporary ID
            console.debug('CreateMessage: No existing conversation found, creating temporary conversation')
            onOpenMessage({
                conversationId: `temp-${user.id}`,
                user
            })
        }
        // Set state to trigger closing after message modal is mounted
        setShouldClose(true)
    }

    const handlePageChange = (_event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value - 1) // Convert 1-based to 0-based
    }

    const isLoading = isSearchLoading || isUsersLoading
    const users = searchQuery.length >= 2 ? searchResults : usersPage?.content || []
    const showPagination = !searchQuery && usersPage && !usersPage.empty

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={onClose}
        >
            <Stack sx={{ height: '100dvh' }}>
                {/* Safe Area Top Spacer */}
                <Box sx={{ height: 'env(safe-area-inset-top)' }} />

                {/* Header */}
                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={2}
                    sx={{
                        px: 2,
                        height: 81,
                        borderBottom: 1,
                        borderColor: 'divider',
                        bgcolor: 'background.paper',
                        flexShrink: 0,
                        backdropFilter: 'blur(10px)',
                        WebkitBackdropFilter: 'blur(10px)',
                        zIndex: 10
                    }}
                >
                    <IconButton onClick={onClose} edge="start">
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" sx={{ flex: 1 }}>
                        New Message
                    </Typography>
                </Stack>

                {/* Search Bar */}
                <Box sx={{ p: 2, bgcolor: 'background.paper' }}>
                    <TextField
                        fullWidth
                        placeholder="Search users"
                        value={searchQuery}
                        onChange={(e) => {
                            setSearchQuery(e.target.value)
                            setPage(0) // Reset page when searching
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon color="action" />
                                </InputAdornment>
                            ),
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                borderRadius: 3,
                                bgcolor: 'action.hover',
                                '& fieldset': {
                                    border: 'none'
                                }
                            }
                        }}
                    />
                </Box>

                {/* User List */}
                <List sx={{ flex: 1, overflow: 'auto' }}>
                    {isLoading ? (
                        <Stack alignItems="center" pt={4}>
                            <CircularProgress />
                        </Stack>
                    ) : users.length === 0 ? (
                        <Stack alignItems="center" pt={4}>
                            <Typography color="text.secondary">
                                {searchQuery.length >= 2 ? 'No users found' : 'Start typing to search users'}
                            </Typography>
                        </Stack>
                    ) : (
                        users.map(user => (
                            <ListItem
                                key={user.id}
                                onClick={() => handleUserClick(user)}
                                sx={{
                                    cursor: 'pointer',
                                    '&:hover': {
                                        bgcolor: 'action.hover'
                                    }
                                }}
                            >
                                <ListItemAvatar>
                                    <Avatar
                                        src={user.avatar || undefined}
                                        alt={user.name}
                                        sx={{
                                            width: 50,
                                            height: 50,
                                            border: '2px solid white',
                                            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)'
                                        }}
                                    >
                                        {user.name[0]}
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Typography variant="body1" fontWeight={500}>
                                                {user.name}
                                            </Typography>
                                            {/* <OnlineStatus userId={user.id} size="small" /> */}
                                        </Stack>
                                    }
                                />
                            </ListItem>
                        ))
                    )}
                </List>

                {showPagination && (
                    <Box sx={{ py: 2, display: 'flex', justifyContent: 'center', bgcolor: 'background.paper' }}>
                        <Pagination
                            count={usersPage.totalPages}
                            page={page + 1}
                            onChange={handlePageChange}
                            color="primary"
                        />
                    </Box>
                )}

                {/* Safe Area Bottom Spacer */}
                <Box sx={{ height: 'env(safe-area-inset-bottom)' }} />
            </Stack>
        </Dialog>
    )
}

export default CreateMessage 