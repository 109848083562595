import AlbumIcon from '@mui/icons-material/Album'
import GroupIcon from '@mui/icons-material/Group'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import { Box, Card, Grid, Stack, Typography, useTheme } from '@mui/material'
import { alpha } from '@mui/material/styles'

const statsData = [
    {
        title: 'Total Sessions',
        value: '24',
        icon: <AlbumIcon />,
        trend: '+12%',
        color: '#4CAF50'
    },
    {
        title: 'Active Clients',
        value: '18',
        icon: <GroupIcon />,
        trend: '+8%',
        color: '#2196F3'
    },
    {
        title: 'Avg. Session Rate',
        value: '$180',
        icon: <TrendingUpIcon />,
        trend: '+15%',
        color: '#9C27B0'
    }
]

const Dashboard = () => {
    const theme = useTheme()

    return (
        <Box sx={{ p: 3 }}>
            {/* Main Earnings Card */}
            <Card
                sx={{
                    p: 3,
                    mb: 3,
                    background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
                    color: 'white'
                }}
            >
                <Stack spacing={1}>
                    <Typography variant="overline" sx={{ opacity: 0.8 }}>
                        Total Earnings
                    </Typography>
                    <Typography variant="h3" fontWeight={700}>
                        $14,400
                    </Typography>
                    <Typography variant="body2" sx={{ opacity: 0.8 }}>
                        +22% from last month
                    </Typography>
                </Stack>
            </Card>

            {/* Stats Cards */}
            <Grid container spacing={2} sx={{ mb: 3 }}>
                {statsData.map((stat, index) => (
                    <Grid item xs={12} md={4} key={index}>
                        <Card
                            sx={{
                                p: 2,
                                display: 'flex',
                                alignItems: 'center',
                                gap: 2
                            }}
                        >
                            <Box
                                sx={{
                                    width: 48,
                                    height: 48,
                                    borderRadius: 2,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    bgcolor: alpha(stat.color, 0.1),
                                    color: stat.color
                                }}
                            >
                                {stat.icon}
                            </Box>
                            <Stack spacing={0.5}>
                                <Typography variant="body2" color="text.secondary">
                                    {stat.title}
                                </Typography>
                                <Typography variant="h6" fontWeight={600}>
                                    {stat.value}
                                </Typography>
                                <Typography
                                    variant="caption"
                                    sx={{ color: 'success.main' }}
                                >
                                    {stat.trend} this month
                                </Typography>
                            </Stack>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    )
}

export default Dashboard 