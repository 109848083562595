import { Box, CircularProgress, Avatar as MuiAvatar, styled } from '@mui/material'
import { useEffect, useState } from 'react'
import { getPlaceholderUrl, getThumbnailUrl } from '../../util/fileUpload'

interface UserAvatarProps {
    src?: string | null
    alt?: string
    userId?: string
    size?: number
    showOnlineStatus?: boolean
    className?: string
    noBorder?: boolean
}

const StyledAvatar = styled(MuiAvatar, {
    shouldForwardProp: (prop) => prop !== 'size' && prop !== 'noBorder'
})<{ size?: number, noBorder?: boolean }>(({ theme, size, noBorder }) => ({
    width: size || 40,
    height: size || 40,
    border: noBorder ? 'none' : `2px solid ${theme.palette.background.paper}`,
    boxShadow: noBorder ? 'none' : '0 2px 8px rgba(0, 0, 0, 0.15)',
    position: 'relative',
    fontSize: `${(size || 40) * 0.4}px`, // Scale font size with avatar size
    fontWeight: 500
}))

const getInitials = (name: string) => {
    return name
        .split(' ')
        .map(part => part[0])
        .join('')
        .toUpperCase()
        .slice(0, 2)
}

export const UserAvatar = ({
    src,
    alt,
    userId,
    size = 40,
    className,
    noBorder = false
}: UserAvatarProps) => {
    const [isLoading, setIsLoading] = useState(true)
    const [isPlaceholderLoaded, setIsPlaceholderLoaded] = useState(false)
    const [hasError, setHasError] = useState(false)
    const [avatarUrl, setAvatarUrl] = useState<string | undefined>(src || undefined)

    useEffect(() => {
        if (!src && userId) {
            console.log('Fetching avatar for userId:', userId);
            // Fetch avatar URL using userId
            fetch(`${import.meta.env.VITE_API_URL}/users/${userId}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })
            .then(response => {
                console.log('Avatar response:', response);
                return response.json();
            })
            .then(data => {
                console.log('Avatar data:', data);
                if (data.avatar) {
                    setAvatarUrl(data.avatar);
                    setHasError(false);
                }
            })
            .catch(error => {
                console.error('Error fetching avatar:', error);
                setHasError(true);
            });
        }
    }, [src, userId]);

    // Only process the image if we have a valid source URL and no error occurred
    const imageUrl = avatarUrl && !hasError ? avatarUrl : undefined;
    const placeholderUrl = imageUrl ? getPlaceholderUrl(imageUrl) : undefined;
    const optimizedUrl = imageUrl ? getThumbnailUrl(imageUrl, size * 2, size * 2) : undefined;

    console.log('UserAvatar render:', { userId, src, avatarUrl, imageUrl, hasError });

    const handleImageError = () => {
        setHasError(true)
        setIsLoading(false)
    }

    return (
        <Box position="relative" display="inline-block">
            <StyledAvatar
                size={size}
                className={className}
                noBorder={noBorder}
                sx={{
                    bgcolor: !imageUrl ? '#000000' : undefined,
                    overflow: 'hidden'
                }}
            >
                {/* Loading Spinner */}
                {isLoading && imageUrl && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: 3,
                            bgcolor: 'action.hover'
                        }}
                    >
                        <CircularProgress
                            size={size * 0.4}
                            sx={{ color: 'primary.main' }}
                        />
                    </Box>
                )}

                {/* Blurred placeholder */}
                {imageUrl && (
                    <Box
                        component="img"
                        src={placeholderUrl}
                        alt=""
                        loading="eager"
                        onError={handleImageError}
                        onLoad={() => setIsPlaceholderLoaded(true)}
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            filter: 'blur(8px)',
                            transform: 'scale(1.1)',
                            opacity: isLoading && isPlaceholderLoaded ? 1 : 0,
                            transition: 'opacity 0.2s ease-in-out',
                            zIndex: 1
                        }}
                    />
                )}

                {/* Main image */}
                {imageUrl ? (
                    <Box
                        component="img"
                        src={optimizedUrl}
                        alt={alt || ''}
                        loading="lazy"
                        onError={handleImageError}
                        onLoad={() => setIsLoading(false)}
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            opacity: isLoading ? 0 : 1,
                            transition: 'opacity 0.2s ease-in-out',
                            zIndex: 2
                        }}
                    />
                ) : (
                    alt && getInitials(alt)
                )}
            </StyledAvatar>

            {/* {showOnlineStatus && userId && (
                <OnlineIndicatorWrapper>
                    <OnlineStatus userId={userId} size="small" showText={false} />
                </OnlineIndicatorWrapper>
            )} */}
        </Box>
    )
}

export default UserAvatar 