import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { routeEnum } from '../routes'
import { api } from '../util/api'

interface FileInfo {
    fileKey: string
    fileName: string
    fileType: string
    fileSize: number
}

interface PhoneVerificationRequest {
    countryCode: number
    phoneNumber: string
}

interface VerifyCodeRequest extends PhoneVerificationRequest {
    code: string
}

interface RegisterRequest extends PhoneVerificationRequest {
    firstName: string
    lastName: string
    profilePicture?: FileInfo
}

interface AuthResponse {
    token: string
    success: boolean
    error?: string
    shouldRegister?: boolean
}

export const useAuth = () => {
    const navigate = useNavigate()

    const { mutate: requestVerificationCode, isPending: isRequestingCode, error: requestError } = useMutation({
        mutationFn: async (request: PhoneVerificationRequest) => {
            const response = await api.post<AuthResponse>('/auth/request-code', request)
            if (!response.success) {
                throw new Error(response.error || 'Failed to send verification code')
            }
            return response
        },
        onSuccess: (data) => {
            if (data.shouldRegister) {
                // Store phone data and redirect to register
                navigate(routeEnum.REGISTER)
            } else {
                // Existing user, go to verification
                navigate(routeEnum.VERIFY_CODE)
            }
        },
        onError: (error) => {
            console.error('Verification code request error:', error)
        }
    })

    const { mutate: verifyCode, isPending: isVerifying, error: verifyError } = useMutation({
        mutationFn: async (request: VerifyCodeRequest) => {
            const response = await api.post<AuthResponse>('/auth/verify', request)
            if (!response.success) {
                throw new Error(response.error || 'Verification failed')
            }
            return response
        },
        onSuccess: (data) => {
            if (data.token) {
                localStorage.setItem('token', data.token)
                sessionStorage.removeItem('phoneData')
                navigate(routeEnum.MESSAGES)
            }
        },
        onError: (error) => {
            console.error('Verification error:', error)
        }
    })

    const { mutateAsync: verifyRegistrationCode, isPending: isVerifyingRegistration, error: verifyRegistrationError } = useMutation({
        mutationFn: async (request: VerifyCodeRequest) => {
            const response = await api.post<AuthResponse>('/auth/verify-registration', request)
            console.log('Verification response:', response)
            if (!response.success) {
                throw new Error(response.error || 'Verification failed')
            }
            return response
        },
        onError: (error) => {
            console.error('Registration verification error:', error)
            throw error
        }
    })

    const { mutate: register, isPending: isRegistering, error: registerError } = useMutation({
        mutationFn: async (request: RegisterRequest) => {
            const response = await api.post<AuthResponse>('/auth/register', request)
            if (!response.success) {
                throw new Error(response.error || 'Registration failed')
            }
            return response
        },
        onSuccess: (data) => {
            if (data.token) {
                localStorage.setItem('token', data.token)
                sessionStorage.removeItem('phoneData')
                sessionStorage.removeItem('registrationData')
                navigate(routeEnum.MESSAGES)
            }
        },
        onError: (error) => {
            console.error('Registration error:', error)
        }
    })

    const checkToken = () => {
        const token = localStorage.getItem('token')
        return !!token
    }

    return {
        requestVerificationCode,
        verifyCode,
        verifyRegistrationCode,
        register,
        logout: () => {
            localStorage.removeItem('token')
            navigate(routeEnum.LOGIN)
        },
        loading: isRequestingCode || isVerifying || isVerifyingRegistration || isRegistering,
        error: requestError?.message || verifyError?.message || verifyRegistrationError?.message || registerError?.message,
        isAuthenticated: checkToken()
    }
}