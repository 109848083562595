import { useConversations } from './useConversations'
import { getCurrentUserId } from '../util/api'

export const useUnreadCount = () => {
    const { conversations, isLoading, error } = useConversations()
    const currentUserId = getCurrentUserId()

    const totalUnreadCount = conversations?.reduce((total, conversation) => {
        // Only count messages from other users that are unread
        const lastMessage = conversation.lastMessage
        if (lastMessage && 
            lastMessage.senderId !== currentUserId && 
            !lastMessage.isRead) {
            return total + 1
        }
        return total
    }, 0) || 0

    return {
        unreadCount: totalUnreadCount,
        isLoading,
        error
    }
}

export default useUnreadCount 