import SearchIcon from '@mui/icons-material/Search'
import {
    Box,
    CircularProgress,
    InputAdornment,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Pagination,
    Stack,
    TextField,
    Typography
} from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { getUsers, searchUsers, type UserSearchResult } from '../../api/user'
import { useNavigate } from 'react-router-dom'
import UserAvatar from '../../components/UserAvatar/UserAvatar'

const Discovery = () => {
    const [searchQuery, setSearchQuery] = useState('')
    const [page, setPage] = useState(0)
    const pageSize = 20
    const navigate = useNavigate()

    const { data: searchResults = [], isLoading: isSearchLoading } = useQuery({
        queryKey: ['users', 'search', searchQuery],
        queryFn: () => searchUsers(searchQuery),
        enabled: searchQuery.length >= 2,
    })

    const { data: usersPage, isLoading: isUsersLoading } = useQuery({
        queryKey: ['users', 'paginated', page, pageSize],
        queryFn: () => getUsers(page, pageSize),
        enabled: searchQuery.length < 2,
    })

    const handleUserClick = (user: UserSearchResult) => {
        navigate(`/profile/${user.id}`)
    }

    const handlePageChange = (_event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value - 1) // Convert 1-based to 0-based
    }

    const isLoading = isSearchLoading || isUsersLoading
    const users = searchQuery.length >= 2 ? searchResults : usersPage?.content || []
    const showPagination = !searchQuery && usersPage && !usersPage.empty

    return (
        <Stack height="100%" sx={{ bgcolor: 'background.default' }}>
            {/* Search Bar */}
            <Box sx={{ p: 2, bgcolor: 'background.paper' }}>
                <TextField
                    fullWidth
                    placeholder="Search artists"
                    value={searchQuery}
                    onChange={(e) => {
                        setSearchQuery(e.target.value)
                        setPage(0) // Reset page when searching
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon color="action" />
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            borderRadius: 3,
                            bgcolor: 'action.hover',
                            '& fieldset': {
                                border: 'none'
                            }
                        }
                    }}
                />
            </Box>

            {/* User List */}
            <List sx={{ flex: 1, overflow: 'auto' }}>
                {isLoading ? (
                    <ListItem>
                        <CircularProgress />
                    </ListItem>
                ) : users.length === 0 ? (
                    <Stack alignItems="center" pt={4}>
                        <Typography color="text.secondary">
                            {searchQuery.length >= 2 ? 'No artists found' : 'Start typing to discover artists'}
                        </Typography>
                    </Stack>
                ) : (
                    users.map(user => (
                        <ListItem
                            key={user.id}
                            onClick={() => handleUserClick(user)}
                            sx={{
                                cursor: 'pointer',
                                '&:hover': {
                                    bgcolor: 'rgba(0, 0, 0, 0.04)'
                                }
                            }}
                        >
                            <ListItemAvatar>
                                <UserAvatar
                                    src={user.avatar}
                                    alt={user.name}
                                    userId={user.id}
                                    size={50}
                                    noBorder
                                />
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Stack direction="row" spacing={1} alignItems="center" sx={{ pl: 1 }}>
                                        <Typography variant="body1" fontWeight={500}>
                                            {user.name}
                                        </Typography>
                                    </Stack>
                                }
                            />
                        </ListItem>
                    ))
                )}
            </List>

            {showPagination && (
                <Box sx={{ py: 2, display: 'flex', justifyContent: 'center', bgcolor: 'background.paper' }}>
                    <Pagination
                        count={usersPage.totalPages}
                        page={page + 1}
                        onChange={handlePageChange}
                        color="primary"
                    />
                </Box>
            )}
        </Stack>
    )
}

export default Discovery 