import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { CssBaseline, ThemeProvider as MuiThemeProvider } from '@mui/material'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import { ThemeProvider, useTheme } from './contexts/ThemeContext'
import { MessageProvider } from './contexts/MessageContext'
import { createAppTheme } from './styles/theme'
import './styles/global.css'
import './styles/fonts.css'

const ThemedApp = () => {
    const { mode } = useTheme()
    const theme = createAppTheme(mode)

    return (
        <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <App />
        </MuiThemeProvider>
    )
}

const container = document.getElementById('root')
if (!container) {
    throw new Error('No root element found')
}

const queryClient = new QueryClient()
const root = createRoot(container)

root.render(
    <StrictMode>
        <BrowserRouter>
            <QueryClientProvider client={queryClient}>
                <ThemeProvider>
                    <MessageProvider>
                        <ThemedApp />
                    </MessageProvider>
                </ThemeProvider>
            </QueryClientProvider>
        </BrowserRouter>
    </StrictMode>
)

