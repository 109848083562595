import { lazy, Suspense } from 'react'
import { createBrowserRouter, Navigate } from 'react-router-dom'
import LoadingScreen from './components/LoadingScreen/LoadingScreen'
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute'
import MainLayout from './layout/MainLayout/MainLayout'
import Dashboard from './pages/Dashboard/Dashboard'
import Discovery from './pages/Discovery/Discovery'
import Media from './pages/Media/Media'
import Messages from './pages/Messages/Messages'
import Payment from './pages/Payment/Payment'
import Profile from './pages/Profile/Profile'
import Session from './pages/Session/Session'

const Login = lazy(() => import('./pages/Login/Login'))
const VerifyCode = lazy(() => import('./pages/Login/VerifyCode'))
const Register = lazy(() => import('./pages/Register/Register'))
const Landing = lazy(() => import('./pages/Landing/Landing'))
const Sessions = lazy(() => import('./pages/Sessions/Sessions'))

export const routeEnum = {
    DISCOVERY: '/',
    MESSAGES: '/messages',
    LOGIN: '/login',
    VERIFY_CODE: '/verify-code',
    REGISTER: '/register',
    LANDING: '/landing',
    PROFILE: '/profile',
    PROFILE_VIEW: '/profile/:userId',
    MEDIA: '/media',
    SESSIONS: '/sessions',
    SESSION: '/session/:sessionId',
    PAYMENT: '/payment/:sessionId',
    DASHBOARD: '/dashboard'
} as const

const ProtectedMainLayout = ({ children }: { children: React.ReactNode }) => (
    <ProtectedRoute>
        <MainLayout>
            {children}
        </MainLayout>
    </ProtectedRoute>
)

export const routes = [
    {
        path: routeEnum.DISCOVERY,
        element: (
            <ProtectedMainLayout>
                <Discovery />
            </ProtectedMainLayout>
        )
    },
    {
        path: routeEnum.MESSAGES,
        element: (
            <ProtectedMainLayout>
                <Messages />
            </ProtectedMainLayout>
        )
    },
    {
        path: routeEnum.LOGIN,
        element: (
            <Suspense fallback={<LoadingScreen />}>
                <Login />
            </Suspense>
        )
    },
    {
        path: routeEnum.VERIFY_CODE,
        element: (
            <Suspense fallback={<LoadingScreen />}>
                <VerifyCode />
            </Suspense>
        )
    },
    {
        path: routeEnum.REGISTER,
        element: (
            <Suspense fallback={<LoadingScreen />}>
                <Register />
            </Suspense>
        )
    },
    {
        path: routeEnum.LANDING,
        element: (
            <Suspense fallback={<LoadingScreen />}>
                <Landing />
            </Suspense>
        )
    },
    {
        path: routeEnum.PROFILE,
        element: (
            <ProtectedMainLayout>
                <Profile />
            </ProtectedMainLayout>
        )
    },
    {
        path: routeEnum.PROFILE_VIEW,
        element: (
            <ProtectedMainLayout>
                <Profile />
            </ProtectedMainLayout>
        )
    },
    {
        path: routeEnum.MEDIA,
        element: (
            <ProtectedMainLayout>
                <Media />
            </ProtectedMainLayout>
        )
    },
    {
        path: routeEnum.SESSIONS,
        element: (
            <ProtectedMainLayout>
                <Sessions />
            </ProtectedMainLayout>
        )
    },
    {
        path: routeEnum.SESSION,
        element: (
            <ProtectedMainLayout>
                <Session />
            </ProtectedMainLayout>
        )
    },
    {
        path: routeEnum.PAYMENT,
        element: (
            <ProtectedMainLayout>
                <Payment />
            </ProtectedMainLayout>
        )
    },
    {
        path: routeEnum.DASHBOARD,
        element: (
            <ProtectedMainLayout>
                <Dashboard />
            </ProtectedMainLayout>
        )
    },
    {
        path: '*',
        element: <Navigate to={routeEnum.DISCOVERY} replace />
    }
]

export const router = createBrowserRouter(routes)