import { Box, CircularProgress, IconButton, Modal } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { MediaItem } from '../../hooks/useMedia'
import AudioPlayer from '../AudioPlayer/AudioPlayer'
import { isAudioFile, isImageFile, getThumbnailUrl, getPlaceholderUrl } from '../../util/fileUpload'
import Image from '../Image/Image'
import { useState } from 'react'

interface MediaPreviewProps {
    open: boolean
    onClose: () => void
    item: MediaItem | null
}

const MediaPreview = ({ open, onClose, item }: MediaPreviewProps) => {
    const [isImageLoading, setIsImageLoading] = useState(true)
    const [isPlaceholderLoaded, setIsPlaceholderLoaded] = useState(false)

    if (!item || !item.url) return null

    return (
        <Modal
            open={open}
            onClose={onClose}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '& .MuiBackdrop-root': {
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    backdropFilter: 'blur(10px)'
                }
            }}
        >
            <Box
                sx={{
                    position: 'relative',
                    outline: 'none',
                    maxWidth: '90vw',
                    maxHeight: '90vh'
                }}
            >
                <IconButton
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        top: -48,
                        right: 0,
                        color: 'white'
                    }}
                >
                    <CloseIcon />
                </IconButton>

                {isAudioFile(item.type) && (
                    <AudioPlayer
                        url={item.url}
                        title={item.name}
                    />
                )}

                {isImageFile(item.type) && (
                    <Box
                        sx={{
                            position: 'relative',
                            maxWidth: '90vw',
                            maxHeight: '90vh',
                            borderRadius: 2,
                            overflow: 'hidden',
                            boxShadow: '0 8px 32px rgba(0,0,0,0.2)',
                            bgcolor: 'rgba(0, 0, 0, 0.2)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        {/* Loading Spinner */}
                        {isImageLoading && (
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    zIndex: 3
                                }}
                            >
                                <CircularProgress 
                                    size={40}
                                    sx={{ color: 'white' }}
                                />
                            </Box>
                        )}

                        {/* Blurred placeholder */}
                        <Image
                            src={getPlaceholderUrl(item.url)}
                            alt="Loading..."
                            fit="contain"
                            loading="eager"
                            onLoad={() => setIsPlaceholderLoaded(true)}
                            sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                filter: 'blur(20px)',
                                transform: 'scale(1.1)',
                                opacity: isImageLoading && isPlaceholderLoaded ? 1 : 0,
                                transition: 'opacity 0.3s ease-in-out'
                            }}
                        />

                        {/* Main image */}
                        <Image
                            src={getThumbnailUrl(item.url, 1500, 1500)}
                            alt={item.name}
                            fit="contain"
                            loading="eager"
                            onLoad={() => setIsImageLoading(false)}
                            sx={{
                                maxWidth: '100%',
                                maxHeight: '90vh',
                                opacity: isImageLoading ? 0 : 1,
                                transition: 'opacity 0.3s ease-in-out',
                                zIndex: 2
                            }}
                        />
                    </Box>
                )}
            </Box>
        </Modal>
    )
}

export default MediaPreview 