import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import AudioFileIcon from '@mui/icons-material/AudioFile'
import ImageIcon from '@mui/icons-material/Image'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import SearchIcon from '@mui/icons-material/Search'
import VideoFileIcon from '@mui/icons-material/VideoFile'
import {
    Box,
    Card,
    CardActionArea,
    IconButton,
    InputAdornment,
    Stack,
    TextField,
    Typography
} from '@mui/material'
import { useEffect, useState } from 'react'
import MediaGrid from '../../components/MediaGrid/MediaGrid'
import MediaPreview from '../../components/MediaPreview/MediaPreview'
import MediaTable from '../../components/MediaTable/MediaTable'
import MediaViewToggle, { MediaViewType } from '../../components/MediaViewToggle/MediaViewToggle'
import { MediaItem, useMedia } from '../../hooks/useMedia'
import { isAudioFile, isImageFile, isVideoFile } from '../../util/fileUpload'

type MediaCategory = 'images' | 'audio' | 'video' | 'other'

interface CategoryInfo {
    title: string
    icon: React.ReactNode
    filter: (item: MediaItem) => boolean
}

const CATEGORIES: Record<MediaCategory, CategoryInfo> = {
    audio: {
        title: 'Audio',
        icon: <AudioFileIcon sx={{ fontSize: 40 }} />,
        filter: (item) => isAudioFile(item.type)
    },
    images: {
        title: 'Pictures',
        icon: <ImageIcon sx={{ fontSize: 40 }} />,
        filter: (item) => isImageFile(item.type)
    },
    video: {
        title: 'Video',
        icon: <VideoFileIcon sx={{ fontSize: 40 }} />,
        filter: (item) => isVideoFile(item.type)
    },
    other: {
        title: 'Other Files',
        icon: <InsertDriveFileIcon sx={{ fontSize: 40 }} />,
        filter: (item) => !isImageFile(item.type) && !isAudioFile(item.type) && !isVideoFile(item.type)
    }
}

const Media = () => {
    const [viewType, setViewType] = useState<MediaViewType>('grid')
    const [selectedMedia, setSelectedMedia] = useState<MediaItem | null>(null)
    const [selectedCategory, setSelectedCategory] = useState<MediaCategory | null>(null)
    const {
        mediaItems,
        isLoading,
        error,
        sortField,
        sortDirection,
        searchQuery,
        handleSort,
        setSearchQuery,
        fetchMediaItems
    } = useMedia()

    useEffect(() => {
        console.debug('Media page mounted, fetching items')
        fetchMediaItems()
    }, [])

    const handleMediaClick = (item: MediaItem) => {
        setSelectedMedia(item)
    }

    const categorizedItems = Object.entries(CATEGORIES).reduce((acc, [key, category]) => {
        acc[key as MediaCategory] = mediaItems.filter(category.filter)
        return acc
    }, {} as Record<MediaCategory, MediaItem[]>)

    if (error) {
        return (
            <Stack p={3}>
                <Typography color="error">Failed to load media files</Typography>
            </Stack>
        )
    }

    const renderCategoryView = () => (
        <Stack spacing={1} sx={{ p: 2 }}>
            {Object.entries(CATEGORIES).map(([key, category]) => {
                const items = categorizedItems[key as MediaCategory]
                if (items.length === 0) return null

                return (
                    <Card
                        key={key}
                        elevation={0}
                        sx={{
                            bgcolor: 'action.hover',
                            borderRadius: 2,
                            overflow: 'hidden'
                        }}
                    >
                        <CardActionArea
                            onClick={() => setSelectedCategory(key as MediaCategory)}
                            sx={{
                                py: 2,
                                px: 3,
                                display: 'flex',
                                alignItems: 'center',
                                gap: 2
                            }}
                        >
                            {category.icon}
                            <Stack direction="row" alignItems="center" spacing={1} flex={1}>
                                <Typography variant="h6" sx={{ fontSize: '1.1rem' }}>
                                    {category.title}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    sx={{ ml: 'auto' }}
                                >
                                    {items.length} {items.length === 1 ? 'file' : 'files'}
                                </Typography>
                            </Stack>
                        </CardActionArea>
                    </Card>
                )
            })}
        </Stack>
    )

    const renderCategoryContent = () => {
        if (!selectedCategory) return null

        const categoryItems = categorizedItems[selectedCategory]
        const category = CATEGORIES[selectedCategory]

        return (
            <>
                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={2}
                    sx={{
                        p: 2,
                        bgcolor: 'background.paper'
                    }}
                >
                    <IconButton onClick={() => setSelectedCategory(null)}>
                        <ArrowBackIcon />
                    </IconButton>
                    <TextField
                        fullWidth
                        placeholder={`Search ${category.title.toLowerCase()}`}
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon color="action" />
                                </InputAdornment>
                            ),
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                borderRadius: 3,
                                bgcolor: 'action.hover',
                                '& fieldset': {
                                    border: 'none'
                                }
                            }
                        }}
                    />
                    <MediaViewToggle
                        view={viewType}
                        onChange={setViewType}
                    />
                </Stack>

                {viewType === 'table' ? (
                    <MediaTable
                        mediaItems={categoryItems}
                        isLoading={isLoading}
                        sortField={sortField}
                        sortDirection={sortDirection}
                        handleSort={handleSort}
                        onItemClick={handleMediaClick}
                    />
                ) : (
                    <MediaGrid
                        mediaItems={categoryItems}
                        isLoading={isLoading}
                        onItemClick={handleMediaClick}
                    />
                )}
            </>
        )
    }

    return (
        <Stack height="100%" sx={{ bgcolor: 'background.default' }}>
            {/* Header */}
            {!selectedCategory && (
                <Box sx={{ bgcolor: 'background.paper' }}>
                    <Box sx={{ p: 2 }}>
                        <TextField
                            fullWidth
                            placeholder="Search files"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon color="action" />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: 3,
                                    bgcolor: 'action.hover',
                                    '& fieldset': {
                                        border: 'none'
                                    }
                                }
                            }}
                        />
                    </Box>
                </Box>
            )}

            {/* Content */}
            {selectedCategory ? renderCategoryContent() : renderCategoryView()}

            {/* Media Preview Modal */}
            <MediaPreview
                open={!!selectedMedia}
                onClose={() => setSelectedMedia(null)}
                item={selectedMedia}
            />
        </Stack>
    )
}

export default Media 