import { createTheme, type PaletteMode, type Components, type Theme } from '@mui/material'

declare module '@mui/material/styles' {
    interface TypographyVariants {
        btnPrimary: React.CSSProperties
        btnSecondary: React.CSSProperties
    }

    interface TypographyVariantsOptions {
        btnPrimary?: React.CSSProperties
        btnSecondary?: React.CSSProperties
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsVariantOverrides {
        glass: true
        'glass-compact': true
    }
}

declare module '@mui/material/IconButton' {
    interface IconButtonPropsVariantOverrides {
        glass: true
    }
}

const getDesignTokens = (mode: PaletteMode) => ({
    palette: {
        mode,
        ...(mode === 'light' 
            ? {
                // Light mode
                primary: {
                    main: '#000000',
                    light: '#262626',
                    dark: '#000000',
                    contrastText: 'white',
                },
                secondary: {
                    main: '#000000',
                    light: '#262626',
                    dark: '#000000',
                    contrastText: 'white',
                },
                background: {
                    default: 'white',
                    paper: 'white',
                },
                text: {
                    primary: '#000000',
                    secondary: '#262626',
                    disabled: '#525252',
                },
                divider: 'rgba(0, 0, 0, 0.12)',
                action: {
                    active: '#000000',
                    hover: 'rgba(0, 0, 0, 0.08)',
                    selected: 'rgba(0, 0, 0, 0.16)',
                    disabled: 'rgba(0, 0, 0, 0.3)',
                    disabledBackground: 'rgba(0, 0, 0, 0.12)',
                },
            }
            : {
                // Dark mode
                primary: {
                    main: '#6366f1',
                    light: '#818cf8',
                    dark: '#4f46e5',
                    contrastText: '#ffffff',
                },
                secondary: {
                    main: '#ffffff',
                    light: '#ffffff',
                    dark: '#e2e2e2',
                    contrastText: '#000000',
                },
                background: {
                    default: '#000000',
                    paper: '#121212',
                },
                text: {
                    primary: '#ffffff',
                    secondary: '#a3a3a3',
                    disabled: '#525252',
                },
                divider: 'rgba(255, 255, 255, 0.12)',
                action: {
                    active: '#ffffff',
                    hover: 'rgba(255, 255, 255, 0.08)',
                    selected: 'rgba(255, 255, 255, 0.16)',
                    disabled: 'rgba(255, 255, 255, 0.3)',
                    disabledBackground: 'rgba(255, 255, 255, 0.12)',
                },
            }),
    },
    typography: {
        fontFamily: '"IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif',
        h1: {
            fontFamily: '"IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif',
            fontWeight: 700,
            fontSize: '6rem',
            lineHeight: 1.1,
            letterSpacing: '-0.04em',
        },
        h2: {
            fontFamily: '"IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif',
            fontWeight: 600,
            fontSize: '4rem',
            lineHeight: 1.1,
            letterSpacing: '-0.04em',
        },
        h3: {
            fontFamily: '"IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif',
            fontWeight: 500,
            fontSize: '3rem',
            lineHeight: 1.2,
        },
        h4: {
            fontFamily: '"IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif',
            fontWeight: 500,
            fontSize: '2.5rem',
            lineHeight: 1.2,
        },
        h5: {
            fontFamily: '"IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif',
            fontWeight: 500,
            fontSize: '2rem',
            lineHeight: 1.2,
        },
        h6: {
            fontFamily: '"IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif',
            fontWeight: 500,
            fontSize: '1.5rem',
            lineHeight: 1.2,
        },
        subtitle1: {
            fontFamily: '"IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif',
            fontWeight: 500,
            fontSize: '1.25rem',
            lineHeight: 1.5,
        },
        subtitle2: {
            fontFamily: '"IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif',
            fontWeight: 500,
            fontSize: '1.125rem',
            lineHeight: 1.5,
        },
        body1: {
            fontFamily: '"IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif',
            fontWeight: 400,
            fontSize: '1rem',
            lineHeight: 1.5,
        },
        body2: {
            fontFamily: '"IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif',
            fontWeight: 400,
            fontSize: '0.875rem',
            lineHeight: 1.5,
        },
        button: {
            fontFamily: '"IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif',
            fontWeight: 500,
            fontSize: '0.875rem',
            lineHeight: 1.75,
            textTransform: 'none' as const,
        },
        btnPrimary: {
            fontFamily: '"IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif',
            fontWeight: 500,
            fontSize: '1.15rem',
            lineHeight: 1.75,
            textTransform: 'none' as const,
            letterSpacing: '0.02em',
        },
        btnSecondary: {
            fontFamily: '"IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif',
            fontWeight: 500,
            fontSize: '0.875rem',
            lineHeight: 1.75,
            textTransform: 'none' as const,
            letterSpacing: '0.02em',
        },
        caption: {
            fontFamily: '"IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif',
            fontWeight: 400,
            fontSize: '0.75rem',
            lineHeight: 1.5,
        },
        overline: {
            fontFamily: '"IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif',
            fontWeight: 500,
            fontSize: '0.75rem',
            lineHeight: 2.5,
            textTransform: 'uppercase' as const,
            letterSpacing: '0.08em',
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                html: {
                    height: '100%',
                },
                body: {
                    height: '100%',
                    margin: 0,
                    backgroundColor: mode === 'light' ? 'white' : '#000000',
                },
                '#root': {
                    height: '100%',
                },
                '.app': {
                    height: '100%',
                },
            },
        },
        MuiButton: {
            defaultProps: {
                disableRipple: true,
            },
            styleOverrides: {
                root: () => ({
                    borderRadius: '8px',
                    textTransform: 'none',
                    fontWeight: 500,
                    padding: '16px',
                    minHeight: '48px',
                }),
                containedPrimary: () => ({
                    '&:hover': {
                        backgroundColor: mode === 'light' ? '#262626' : '#4f46e5',
                    },
                }),
                textPrimary: () => ({
                    color: mode === 'light' ? '#000000' : '#ffffff',
                    '&:hover': {
                        backgroundColor: mode === 'light' 
                            ? 'rgba(0, 0, 0, 0.08)' 
                            : 'rgba(255, 255, 255, 0.08)',
                    },
                }),
            },
            variants: [
                {
                    props: { variant: 'glass' },
                    style: {
                        backgroundColor: 'rgba(0, 0, 0, 0.65)',
                        backdropFilter: 'blur(10px)',
                        borderRadius: 40,
                        padding: '8px 16px',
                        '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.75)',
                        },
                        '&:active': {
                            backgroundColor: 'rgba(0, 0, 0, 0.85)',
                        },
                        '& .MuiTypography-root': {
                            color: '#fff',
                        },
                        '& .MuiSvgIcon-root': {
                            color: '#fff',
                        }
                    },
                },
                {
                    props: { variant: 'glass-compact' },
                    style: {
                        backgroundColor: 'rgba(0, 0, 0, 0.65)',
                        backdropFilter: 'blur(10px)',
                        borderRadius: 40,
                        padding: '4px 16px',
                        height: 36,
                        minHeight: 'auto',
                        minWidth: 'auto',
                        whiteSpace: 'nowrap',
                        '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.75)',
                        },
                        '&:active': {
                            backgroundColor: 'rgba(0, 0, 0, 0.85)',
                        },
                        '& .MuiTypography-root': {
                            color: '#fff',
                            whiteSpace: 'nowrap',
                        },
                        '& .MuiSvgIcon-root': {
                            color: '#fff',
                        }
                    },
                },
            ],
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-root': {
                        borderRadius: '8px',
                        '& input[type="password"]': {
                            fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif',
                            WebkitTextSecurity: 'disc',
                        },
                        '& fieldset': {
                            borderColor: mode === 'light' 
                                ? 'rgba(0, 0, 0, 0.23)' 
                                : 'rgba(255, 255, 255, 0.23)',
                        },
                        '&:hover fieldset': {
                            borderColor: mode === 'light'
                                ? 'rgba(0, 0, 0, 0.5)'
                                : 'rgba(255, 255, 255, 0.5)',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: mode === 'light' ? '#000000' : '#6366f1',
                        },
                    },
                    '& .MuiInputLabel-root': {
                        color: mode === 'light'
                            ? 'rgba(0, 0, 0, 0.7)'
                            : 'rgba(255, 255, 255, 0.7)',
                        '&.Mui-focused': {
                            color: mode === 'light' ? '#000000' : '#6366f1',
                        },
                    },
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: '12px',
                    backgroundColor: mode === 'light' ? 'white' : '#121212',
                },
            },
        },
        MuiIconButton: {
            defaultProps: {
                disableRipple: true,
            },
            variants: [
                {
                    props: { variant: 'glass' } as any,
                    style: {
                        backgroundColor: 'rgba(0, 0, 0, 0.65)',
                        backdropFilter: 'blur(10px)',
                        padding: '10px',
                        '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.75)',
                        },
                        '&:active': {
                            backgroundColor: 'rgba(0, 0, 0, 0.85)',
                        },
                        '& .MuiSvgIcon-root': {
                            color: '#fff',
                            fontSize: '1.5rem'
                        }
                    },
                },
            ],
        },
    } satisfies Components<Theme>,
})

export const createAppTheme = (mode: PaletteMode) => createTheme(getDesignTokens(mode)) 