import { Box, Stack, Typography } from '@mui/material'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import LoadingButton from '@mui/lab/LoadingButton'
import { useState, useEffect } from 'react'

interface StripeConnectProps {
    isConnected: boolean
    onConnect: () => Promise<{ accountId: string, accountLink: string }>
    onDisconnect: () => void
    loading?: boolean
}

const StripeConnect = ({ isConnected, onConnect, onDisconnect, loading = false }: StripeConnectProps) => {
    const [isConnecting, setIsConnecting] = useState(false)

    useEffect(() => {
        // Handle postMessage events from Stripe
        const handleMessage = (event: MessageEvent) => {
            if (event.data === 'stripe-connect-success') {
                // Refresh the page to update the status
                window.location.reload()
            }
        }

        window.addEventListener('message', handleMessage)
        return () => window.removeEventListener('message', handleMessage)
    }, [])

    const handleConnect = async () => {
        setIsConnecting(true)
        try {
            const { accountLink } = await onConnect()
            // Open in the same window - keep loading state active
            window.location.href = accountLink
        } catch (error) {
            console.error('Failed to start Stripe Connect flow:', error)
            setIsConnecting(false)
        }
    }

    return (
        <Stack spacing={2} width="100%">
            <Stack 
                spacing={3}
                sx={{
                    bgcolor: 'background.paper',
                    p: 3,
                    borderRadius: 2,
                    border: '1px solid',
                    borderColor: isConnected ? 'success.main' : 'divider'
                }}
            >
                <Stack alignItems="center" spacing={2}>
                    <Box
                        sx={{
                            width: 56,
                            height: 56,
                            borderRadius: 2,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            bgcolor: isConnected ? 'success.main' : 'action.selected'
                        }}
                    >
                        {isConnected ? (
                            <CheckCircleIcon sx={{ color: 'white', fontSize: 32 }} />
                        ) : (
                            <AccountBalanceIcon sx={{ color: 'text.secondary', fontSize: 32 }} />
                        )}
                    </Box>
                    <Stack spacing={1} alignItems="center">
                        <Typography variant="h6" fontWeight={600} textAlign="center">
                            {isConnected ? 'Bank Account Connected' : 'Connect Bank Account'}
                        </Typography>
                        <Typography 
                            variant="body1" 
                            color="text.secondary" 
                            textAlign="center"
                            sx={{ maxWidth: 320 }}
                        >
                            {isConnected 
                                ? 'Your bank account is connected and ready to receive payments'
                                : 'Connect your bank account to receive payments from sessions'
                            }
                        </Typography>
                    </Stack>
                </Stack>

                <LoadingButton
                    variant={isConnected ? 'outlined' : 'contained'}
                    color={isConnected ? 'error' : 'primary'}
                    onClick={isConnected ? onDisconnect : handleConnect}
                    loading={isConnected ? loading : isConnecting}
                    size="large"
                    loadingPosition="center"
                    sx={{
                        py: 1.5,
                        width: '100%',
                        maxWidth: 280,
                        alignSelf: 'center'
                    }}
                >
                    <Typography variant="btnPrimary">
                        {isConnected ? 'Disconnect Bank Account' : 'Connect Bank Account'}
                    </Typography>
                </LoadingButton>
            </Stack>
            
            {!isConnected && (
                <Typography 
                    variant="body2" 
                    color="text.secondary"
                    sx={{ 
                        textAlign: 'center',
                        maxWidth: 400,
                        mx: 'auto'
                    }}
                >
                    You'll be redirected to Stripe to complete the bank account connection process. 
                    This is secure and only takes a few minutes.
                </Typography>
            )}
        </Stack>
    )
}

export default StripeConnect 