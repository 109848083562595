import { Stack } from '@mui/material'
import { useRef, useState, useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import type { MessageDto } from '../../types/conversation'
import { api } from '../../util/api'
import BottomNav from '../../components/BottomNav/BottomNav'
import CreateMessage from '../../components/CreateMessage/CreateMessage'
import Message from '../../pages/Message/Message'
import Header from '../Header/Header'
import { useMessage } from '../../contexts/MessageContext'

interface MainLayoutProps {
    children: React.ReactNode
}

const MainLayout = ({ children }: MainLayoutProps) => {
    const layoutRef = useRef<HTMLDivElement>(null)
    const queryClient = useQueryClient()
    const { messageState, isCreateMessageOpen, closeMessage, closeCreateMessage, openMessage } = useMessage()
    const [isMessageModalOpen, setIsMessageModalOpen] = useState(false)

    useEffect(() => {
        if (messageState) {
            setIsMessageModalOpen(true)
        }
    }, [messageState])

    const handleSendMessage = async (content: string, fileInfo?: {
        fileKey: string
        fileName: string
        fileType: string
        fileSize: number
    }) => {
        if (!messageState?.user) return

        try {
            // Create new conversation and send first message
            const response = await api.post<MessageDto>(`/messages/new/${messageState.user.id}`, {
                content,
                fileKey: fileInfo?.fileKey,
                fileName: fileInfo?.fileName,
                fileType: fileInfo?.fileType,
                fileSize: fileInfo?.fileSize
            })

            console.log('response', response)

            // Get the conversation details after creating it
            const conversationResponse = await api.get<{ id: string }>(`/messages/find/${messageState.user.id}`)

            // Update message state with the new conversation ID
            openMessage({
                conversationId: conversationResponse.id,
                user: messageState.user
            })
        } catch (error) {
            console.error('Error creating new conversation:', error)
        }
    }

    const handleCloseMessage = () => {
        // First set the modal to close
        setIsMessageModalOpen(false)
        // Then after animation, clean up the state
        setTimeout(() => {
            queryClient.invalidateQueries({ queryKey: ['conversations'] })
            closeMessage()
        }, 300)
    }

    return (
        <Stack
            ref={layoutRef}
            data-layout="main"
            height="100dvh"
            width="100%"
        >
            {/* App Structure */}
            <Stack flex={1} minHeight={0}>
                {/* Header - Fixed height */}
                <Header />

                {/* Main Content - Flexible height with scroll */}
                <Stack
                    flex={1}
                    minHeight={0}
                    sx={{
                        bgcolor: 'background.default',
                        overflow: 'auto'
                    }}
                >
                    {children}
                </Stack>

                {/* Bottom Navigation - Fixed height */}
                <BottomNav />
            </Stack>

            {/* Modals - Rendered outside the main flow */}
            <CreateMessage
                open={isCreateMessageOpen}
                onClose={closeCreateMessage}
                onOpenMessage={openMessage}
            />
            {messageState && (
                <Message
                    conversationId={messageState.conversationId}
                    open={isMessageModalOpen}
                    onClose={handleCloseMessage}
                    user={messageState.user}
                    title={messageState.title}
                    participants={messageState.participants}
                    onSend={handleSendMessage}
                />
            )}
        </Stack>
    )
}

export default MainLayout 