import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import GridViewIcon from '@mui/icons-material/GridView'
import TableRowsIcon from '@mui/icons-material/TableRows'

export type MediaViewType = 'table' | 'grid'

interface MediaViewToggleProps {
    view: MediaViewType
    onChange: (view: MediaViewType) => void
}

export const MediaViewToggle = ({ view, onChange }: MediaViewToggleProps) => {
    return (
        <ToggleButtonGroup
            value={view}
            exclusive
            onChange={(_, newView) => {
                if (newView !== null) {
                    onChange(newView)
                }
            }}
            size="small"
            sx={{
                '& .MuiToggleButton-root': {
                    border: 'none',
                    borderRadius: 1,
                    px: 1,
                    '&.Mui-selected': {
                        bgcolor: 'action.selected',
                        '&:hover': {
                            bgcolor: 'action.selected'
                        }
                    }
                }
            }}
        >
            <ToggleButton value="table">
                <TableRowsIcon sx={{ fontSize: 20 }} />
            </ToggleButton>
            <ToggleButton value="grid">
                <GridViewIcon sx={{ fontSize: 20 }} />
            </ToggleButton>
        </ToggleButtonGroup>
    )
}

export default MediaViewToggle 