import { api } from '../util/api'

export type SessionRole = 'HOST' | 'MODERATOR' | 'PARTICIPANT'

export enum SessionStatus {
    REQUESTED = 'REQUESTED',
    ACTIVE = 'ACTIVE',
    COMPLETED = 'COMPLETED'
}

export interface SessionDto {
    id: string
    title: string
    status: SessionStatus
    participants: {
        id: string
        name: string
        avatar?: string | null
        role: SessionRole
    }[]
    createdAt: string
    updatedAt: string
    chatId?: string | null
}

export interface CreateSessionRequest {
    title: string
    participantIds: string[]
    status?: SessionStatus
}

export interface UpdateSessionRequest {
    title?: string
    participantIds?: string[]
    status?: SessionStatus
}

export const getSessions = async (): Promise<SessionDto[]> => {
    return api.get<SessionDto[]>('/sessions')
}

export const getSession = async (sessionId: string): Promise<SessionDto> => {
    return api.get<SessionDto>(`/sessions/${sessionId}`)
}

export const createSession = async (request: CreateSessionRequest): Promise<SessionDto> => {
    return api.post<SessionDto>('/sessions', request)
}

export const updateSession = async (sessionId: string, request: UpdateSessionRequest): Promise<SessionDto> => {
    return api.put<SessionDto>(`/sessions/${sessionId}`, request)
}

export const requestSession = async (request: CreateSessionRequest): Promise<SessionDto> => {
    const payload = {
        ...request,
        status: 'REQUESTED' as const
    }
    return api.post<SessionDto>('/sessions', payload)
} 