import { Box, CircularProgress, Stack, SxProps, Theme, Typography } from '@mui/material'
import { MediaItem } from '../../hooks/useMedia'
import { isImageFile, isAudioFile, isVideoFile, getThumbnailUrl, getPlaceholderUrl } from '../../util/fileUpload'
import MusicNoteIcon from '@mui/icons-material/MusicNote'
import VideoFileIcon from '@mui/icons-material/VideoFile'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import { useState } from 'react'

interface MediaThumbnailProps {
    item: MediaItem
    sx?: SxProps<Theme>
}

const MediaThumbnail = ({ item, sx }: MediaThumbnailProps) => {
    const [isImageLoading, setIsImageLoading] = useState(true)
    const [isPlaceholderLoaded, setIsPlaceholderLoaded] = useState(false)

    const getFileIcon = () => {
        if (isAudioFile(item.type)) {
            return <MusicNoteIcon sx={{ fontSize: 32, color: 'primary.main' }} />
        }
        if (isVideoFile(item.type)) {
            return <VideoFileIcon sx={{ fontSize: 32, color: 'error.main' }} />
        }
        if (item.type.toLowerCase().includes('pdf')) {
            return <PictureAsPdfIcon sx={{ fontSize: 32, color: 'warning.main' }} />
        }
        return <InsertDriveFileIcon sx={{ fontSize: 32, color: 'text.secondary' }} />
    }

    if (isImageFile(item.type)) {
        const imageUrl = item.url || '/placeholders/image.png'
        const placeholderUrl = getPlaceholderUrl(imageUrl)
        const optimizedUrl = getThumbnailUrl(imageUrl, 200, 200)

        return (
            <Box
                sx={{
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                    borderRadius: 1,
                    overflow: 'hidden',
                    bgcolor: 'background.paper',
                    border: 1,
                    borderColor: 'divider',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    ...sx
                }}
            >
                {/* Loading Spinner */}
                {isImageLoading && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: 3,
                            bgcolor: 'action.hover'
                        }}
                    >
                        <CircularProgress size={24} />
                    </Box>
                )}

                {/* Blurred placeholder */}
                <Box
                    component="img"
                    src={placeholderUrl}
                    alt="Loading..."
                    loading="eager"
                    onLoad={() => setIsPlaceholderLoaded(true)}
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        filter: 'blur(8px)',
                        transform: 'scale(1.1)',
                        opacity: isImageLoading && isPlaceholderLoaded ? 1 : 0,
                        transition: 'opacity 0.2s ease-in-out',
                        zIndex: 1
                    }}
                />

                {/* Main image */}
                <Box
                    component="img"
                    src={optimizedUrl}
                    alt={item.name}
                    loading="lazy"
                    onLoad={() => setIsImageLoading(false)}
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        opacity: isImageLoading ? 0 : 1,
                        transition: 'opacity 0.2s ease-in-out',
                        zIndex: 2
                    }}
                />
            </Box>
        )
    }

    return (
        <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
                width: '100%',
                height: '100%',
                borderRadius: 1,
                bgcolor: 'background.default',
                border: 1,
                borderColor: 'divider',
                p: 1,
                ...sx
            }}
        >
            {getFileIcon()}
            <Typography
                variant="caption"
                align="center"
                sx={{
                    mt: 0.5,
                    fontSize: '0.75rem',
                    lineHeight: 1.2,
                    maxHeight: '2.4em',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    wordBreak: 'break-all',
                    px: 0.5
                }}
            >
                {item.name}
            </Typography>
        </Stack>
    )
}

export default MediaThumbnail 