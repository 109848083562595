import { config } from '../config'

if (!config.apiUrl) {
    throw new Error('API URL is not configured. Please check your environment variables.')
}

const handleAuthError = (status: number) => {
    if (status === 401 || status === 403) {
        console.debug('Authentication error, clearing token and redirecting to login')
        localStorage.removeItem('token')
        window.location.href = '/login'
    }
}

const getAuthHeader = () => {
    const token = localStorage.getItem('token')
    return token ? `Bearer ${token}` : ''
}

const decodeToken = (token: string) => {
    try {
        const base64Url = token.split('.')[1]
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => 
            '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        ).join(''))
        return JSON.parse(jsonPayload)
    } catch (error) {
        console.error('Error decoding token:', error)
        return null
    }
}

export const getCurrentUserId = (): string | null => {
    const token = localStorage.getItem('token')
    if (!token) return null
    
    const decoded = decodeToken(token)
    return decoded?.userId || null
}

export const api = {
    async post<T>(endpoint: string, data: unknown): Promise<T> {
        // Ensure endpoint starts with a forward slash and trim any trailing spaces
        const cleanEndpoint = endpoint.startsWith('/') ? endpoint : `/${endpoint}`.trim()
        
        // Remove any trailing slashes from the API URL
        const baseUrl = config.apiUrl.toString().replace(/\/+$/, '')
        const url = `${baseUrl}${cleanEndpoint}`
        
        console.debug('Making POST request to:', url)
        const authHeader = getAuthHeader()
        console.debug('Using Authorization header:', authHeader.substring(0, 40) + '...')
        
        const response = await fetch(url, {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': authHeader
            },
            body: JSON.stringify(data),
            credentials: 'include'
        })

        console.debug('POST response status:', response.status)
        
        // Handle authentication errors
        handleAuthError(response.status)
        
        const responseData = await response.json()
        
        if (!response.ok) {
            console.error('POST request failed:', responseData.error || 'An error occurred')
            throw new Error(responseData.error || 'An error occurred')
        }
        
        return responseData
    },

    async get<T>(endpoint: string): Promise<T> {
        // Ensure endpoint starts with a forward slash and trim any trailing spaces
        const cleanEndpoint = endpoint.startsWith('/') ? endpoint : `/${endpoint}`.trim()
        
        // Remove any trailing slashes from the API URL
        const baseUrl = config.apiUrl.toString().replace(/\/+$/, '')
        const url = `${baseUrl}${cleanEndpoint}`

        console.debug('Making GET request to:', url)
        const authHeader = getAuthHeader()

        if (!authHeader) {
            console.error('No authentication token found')
            localStorage.removeItem('token')
            window.location.href = '/login'
            throw new Error('No authentication token found')
        }
        
        const response = await fetch(url, {
            method: 'GET',
            headers: { 
                'Authorization': authHeader
            },
            credentials: 'include'
        })

        console.debug('GET response status:', response.status)
        
        // Handle authentication errors
        handleAuthError(response.status)
        
        const responseData = await response.json()
        
        if (!response.ok) {
            console.error('GET request failed:', responseData.error || 'An error occurred')
            throw new Error(responseData.error || 'An error occurred')
        }
        
        return responseData
    },

    put: async <T>(endpoint: string, data: unknown): Promise<T> => {
        // Ensure endpoint starts with a forward slash and trim any trailing spaces
        const cleanEndpoint = endpoint.startsWith('/') ? endpoint : `/${endpoint}`.trim()
        
        // Remove any trailing slashes from the API URL
        const baseUrl = config.apiUrl.toString().replace(/\/+$/, '')
        const url = `${baseUrl}${cleanEndpoint}`

        console.debug('Making PUT request to:', url)
        const authHeader = getAuthHeader()
        console.debug('Using Authorization header:', authHeader.substring(0, 40) + '...')

        if (!authHeader) {
            console.error('No authentication token found')
            localStorage.removeItem('token')
            window.location.href = '/login'
            throw new Error('No authentication token found')
        }
        
        const response = await fetch(url, {
            method: 'PUT',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': authHeader
            },
            body: JSON.stringify(data),
            credentials: 'include'
        })

        console.debug('PUT response status:', response.status)
        
        // Handle authentication errors
        handleAuthError(response.status)
        
        const responseData = await response.json()
        
        if (!response.ok) {
            console.error('PUT request failed:', responseData.error || 'An error occurred')
            throw new Error(responseData.error || 'An error occurred')
        }
        
        return responseData
    }
}