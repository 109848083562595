import MessageIcon from '@mui/icons-material/Message'
import PermMediaIcon from '@mui/icons-material/PermMedia'
import PodcastsIcon from '@mui/icons-material/Podcasts'
import PublicIcon from '@mui/icons-material/Public'
import ShowChartIcon from '@mui/icons-material/ShowChart'
import { Badge, BottomNavigation, BottomNavigationAction, Paper } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { useUnreadCount } from '../../hooks/useUnreadCount'
import { useRequestCount } from '../../hooks/useRequestCount'

const BottomNav = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const { unreadCount } = useUnreadCount()
    const { requestCount } = useRequestCount()

    const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
        navigate(newValue)
    }

    return (
        <Paper
            sx={{
                position: 'sticky',
                bottom: 0,
                left: 0,
                right: 0,
                elevation: 3,
                zIndex: 10,
                height: 65
            }}
            elevation={3}
        >
            <BottomNavigation
                value={location.pathname}
                onChange={handleChange}
                sx={{
                    height: '100%',
                    '& .MuiBottomNavigationAction-root': {
                        minWidth: 'auto',
                        padding: 0,
                        '& .MuiSvgIcon-root': {
                            fontSize: 28
                        }
                    }
                }}
            >
                <BottomNavigationAction
                    value="/dashboard"
                    icon={<ShowChartIcon />}
                />
                <BottomNavigationAction
                    value="/"
                    icon={<PublicIcon />}
                />
                <BottomNavigationAction
                    value="/sessions"
                    icon={
                        <Badge
                            badgeContent={requestCount}
                            color="primary"
                            max={99}
                            sx={{
                                '& .MuiBadge-badge': {
                                    right: -3,
                                    top: 3
                                }
                            }}
                        >
                            <PodcastsIcon />
                        </Badge>
                    }
                />
                <BottomNavigationAction
                    value="/messages"
                    icon={
                        <Badge
                            badgeContent={unreadCount}
                            color="primary"
                            max={99}
                            sx={{
                                '& .MuiBadge-badge': {
                                    right: -3,
                                    top: 3
                                }
                            }}
                        >
                            <MessageIcon />
                        </Badge>
                    }
                />
                <BottomNavigationAction
                    value="/media"
                    icon={<PermMediaIcon />}
                />
            </BottomNavigation>
        </Paper>
    )
}

export default BottomNav 