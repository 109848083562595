import { Box, CircularProgress, IconButton, Stack, Typography } from '@mui/material'
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded'
import PauseRoundedIcon from '@mui/icons-material/PauseRounded'
import SkipNextRoundedIcon from '@mui/icons-material/SkipNextRounded'
import SkipPreviousRoundedIcon from '@mui/icons-material/SkipPreviousRounded'
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import { useEffect, useRef, useState } from 'react'

interface AudioPlayerProps {
    url: string
    title: string
    artist?: string
    coverUrl?: string
}

const AudioPlayer = ({ url, title, artist = 'Unknown Artist', coverUrl }: AudioPlayerProps) => {
    const [isPlaying, setIsPlaying] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState<string | null>(null)
    const [progress, setProgress] = useState(0)
    const [currentTime, setCurrentTime] = useState(0)
    const [duration, setDuration] = useState(0)
    const audioRef = useRef<HTMLAudioElement>(null)
    const progressBarRef = useRef<HTMLDivElement>(null)

    const cleanTitle = (title: string) => {
        // Remove common audio file extensions
        return title.replace(/\.(m4a|mp3|wav|aac|ogg|wma|aiff?)$/i, '')
    }

    useEffect(() => {
        const audio = audioRef.current
        if (!audio) return

        const handleTimeUpdate = () => {
            setCurrentTime(audio.currentTime)
            const progress = (audio.currentTime / audio.duration) * 100
            setProgress(progress)
        }

        const handleLoadedMetadata = () => {
            setDuration(audio.duration)
        }

        const handleEnded = () => {
            setIsPlaying(false)
            setProgress(0)
            setCurrentTime(0)
        }

        const handleLoadStart = () => {
            setIsLoading(true)
            setError(null)
        }

        const handleCanPlay = () => {
            setIsLoading(false)
            setError(null)
        }

        const handleError = () => {
            setIsLoading(false)
            setError('Failed to load audio file')
            setIsPlaying(false)
        }

        audio.addEventListener('timeupdate', handleTimeUpdate)
        audio.addEventListener('ended', handleEnded)
        audio.addEventListener('loadstart', handleLoadStart)
        audio.addEventListener('canplay', handleCanPlay)
        audio.addEventListener('error', handleError)
        audio.addEventListener('loadedmetadata', handleLoadedMetadata)

        audio.load()

        return () => {
            audio.removeEventListener('timeupdate', handleTimeUpdate)
            audio.removeEventListener('ended', handleEnded)
            audio.removeEventListener('loadstart', handleLoadStart)
            audio.removeEventListener('canplay', handleCanPlay)
            audio.removeEventListener('error', handleError)
            audio.removeEventListener('loadedmetadata', handleLoadedMetadata)
        }
    }, [url])

    const handlePlayPause = async () => {
        if (!audioRef.current || isLoading) return

        try {
            if (isPlaying) {
                await audioRef.current.pause()
            } else {
                await audioRef.current.play()
            }
            setIsPlaying(!isPlaying)
        } catch (err) {
            console.error('Playback error:', err)
            setError('Failed to play audio')
            setIsPlaying(false)
        }
    }

    const handleProgressBarClick = (event: React.MouseEvent<HTMLDivElement>) => {
        if (!audioRef.current || !progressBarRef.current || isLoading) return

        const rect = progressBarRef.current.getBoundingClientRect()
        const x = event.clientX - rect.left
        const width = rect.width
        const percentage = (x / width) * 100
        const time = (percentage / 100) * audioRef.current.duration

        audioRef.current.currentTime = time
        setProgress(percentage)
    }

    const formatTime = (time: number) => {
        const minutes = Math.floor(time / 60)
        const seconds = Math.floor(time % 60)
        return `${minutes}:${seconds.toString().padStart(2, '0')}`
    }

    return (
        <Stack
            sx={{
                width: '100%',
                maxWidth: 400,
                aspectRatio: '9/16',
                bgcolor: 'rgba(0, 0, 0, 0.9)',
                backdropFilter: 'blur(30px)',
                WebkitBackdropFilter: 'blur(30px)',
                borderRadius: 4,
                p: 3,
                color: 'white'
            }}
            spacing={4}
        >
            <audio ref={audioRef} src={url} preload="auto" />

            {/* Cover Art */}
            <Box
                sx={{
                    width: '100%',
                    aspectRatio: '1/1',
                    borderRadius: 3,
                    overflow: 'hidden',
                    boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
                    bgcolor: 'rgba(255,255,255,0.1)',
                    backgroundImage: coverUrl ? `url(${coverUrl})` : 'none',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                {!coverUrl && (
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            background: 'linear-gradient(45deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0.05) 100%)',
                        }}
                    >
                        <MusicNoteRoundedIcon 
                            sx={{ 
                                fontSize: 120,
                                color: 'rgba(255,255,255,0.3)',
                                filter: 'drop-shadow(0 4px 8px rgba(0,0,0,0.2))'
                            }} 
                        />
                    </Box>
                )}
            </Box>

            {/* Title and Artist */}
            <Stack spacing={0.5} alignItems="center">
                <Typography variant="h6" align="center" sx={{ fontWeight: 600, color: 'white' }}>
                    {cleanTitle(title)}
                </Typography>
                <Typography variant="body2" sx={{ color: 'rgba(255,255,255,0.7)' }}>
                    {artist}
                </Typography>
                {error && (
                    <Typography variant="caption" color="error">
                        {error}
                    </Typography>
                )}
            </Stack>

            {/* Progress Bar */}
            <Stack spacing={1} width="100%">
                <Box
                    ref={progressBarRef}
                    onClick={handleProgressBarClick}
                    sx={{
                        width: '100%',
                        height: 4,
                        bgcolor: 'rgba(255,255,255,0.2)',
                        borderRadius: 2,
                        cursor: isLoading ? 'default' : 'pointer',
                        position: 'relative',
                        opacity: isLoading ? 0.5 : 1,
                        '&:hover': {
                            '& .progress-bar': {
                                bgcolor: '#1890ff'
                            }
                        }
                    }}
                >
                    <Box
                        className="progress-bar"
                        sx={{
                            position: 'absolute',
                            left: 0,
                            top: 0,
                            height: '100%',
                            width: `${progress}%`,
                            bgcolor: 'white',
                            borderRadius: 2,
                            transition: 'background-color 0.2s'
                        }}
                    />
                </Box>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="caption" sx={{ color: 'rgba(255,255,255,0.7)' }}>
                        {formatTime(currentTime)}
                    </Typography>
                    <Typography variant="caption" sx={{ color: 'rgba(255,255,255,0.7)' }}>
                        -{formatTime(duration - currentTime)}
                    </Typography>
                </Stack>
            </Stack>

            {/* Controls */}
            <Stack direction="row" spacing={3} justifyContent="center" alignItems="center">
                <IconButton
                    sx={{
                        color: 'white',
                        '&:hover': { color: '#1890ff' },
                        '&.Mui-disabled': { color: 'rgba(255,255,255,0.3)' }
                    }}
                >
                    <SkipPreviousRoundedIcon sx={{ fontSize: 36 }} />
                </IconButton>
                <IconButton
                    onClick={handlePlayPause}
                    disabled={isLoading}
                    sx={{
                        color: 'white',
                        bgcolor: 'rgba(255,255,255,0.1)',
                        '&:hover': {
                            bgcolor: 'rgba(255,255,255,0.2)'
                        },
                        '&.Mui-disabled': {
                            bgcolor: 'rgba(255,255,255,0.05)',
                            color: 'rgba(255,255,255,0.3)'
                        },
                        width: 64,
                        height: 64
                    }}
                >
                    {isLoading ? (
                        <CircularProgress size={28} color="inherit" />
                    ) : isPlaying ? (
                        <PauseRoundedIcon sx={{ fontSize: 36 }} />
                    ) : (
                        <PlayArrowRoundedIcon sx={{ fontSize: 36 }} />
                    )}
                </IconButton>
                <IconButton
                    sx={{
                        color: 'white',
                        '&:hover': { color: '#1890ff' },
                        '&.Mui-disabled': { color: 'rgba(255,255,255,0.3)' }
                    }}
                >
                    <SkipNextRoundedIcon sx={{ fontSize: 36 }} />
                </IconButton>
            </Stack>
        </Stack>
    )
}

export default AudioPlayer 