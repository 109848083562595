import { useQuery } from '@tanstack/react-query'
import { api } from '../util/api'
import { ConversationDto } from '../types/conversation'
import { useNavigate } from 'react-router-dom'
import { routeEnum } from '../routes'

export const useConversations = () => {
    const navigate = useNavigate()

    const { data, isLoading, error } = useQuery<ConversationDto[]>({
        queryKey: ['conversations'],
        queryFn: () => api.get('/messages'),
        retry: (failureCount, error) => {
            // Don't retry on authentication errors
            if (error instanceof Error && error.message === 'No authentication token found') {
                navigate(routeEnum.LOGIN)
                return false
            }
            // Retry up to 3 times for other errors
            return failureCount < 3
        },
        refetchOnWindowFocus: true,
        staleTime: 30000 // Consider data stale after 30 seconds
    })

    return {
        conversations: data,
        isLoading: Boolean(isLoading),
        error: error?.message,
        hasError: !!error
    }
} 