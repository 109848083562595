import { api } from '../util/api'
import { User, UserSearchResult } from '../types/user'

export type { User, UserSearchResult }

export interface PageResponse<T> {
    content: T[]
    totalPages: number
    totalElements: number
    size: number
    number: number
    first: boolean
    last: boolean
    empty: boolean
}

export interface UpdateUserRequest {
    firstName?: string
    lastName?: string
    profilePicture?: string
    rate?: number
}

export interface TimeRange {
    start: number
    end: number
}

export interface TimeZone {
    name: string
    shortName?: string
}

export interface UpdateAvailabilityRequest {
    selectedDays: number[]
    dayTimeRanges: { [key: number]: TimeRange }
    selectedTimeZone: TimeZone
    useSameHours: boolean
}

export const searchUsers = async (query: string): Promise<UserSearchResult[]> => {
    return api.get<UserSearchResult[]>(`/users/search?query=${encodeURIComponent(query)}`)
}

export const getUsers = async (page: number = 0, size: number = 20): Promise<PageResponse<UserSearchResult>> => {
    return api.get<PageResponse<UserSearchResult>>(`/users?page=${page}&size=${size}`)
}

export const getCurrentUser = async (): Promise<User> => {
    return api.get<User>('/users/me')
}

export const updateUser = async (request: UpdateUserRequest): Promise<User> => {
    return api.put<User>('/users/me', request)
}

export const updateAvailability = async (request: UpdateAvailabilityRequest): Promise<void> => {
    return api.put('/users/me/availability', request)
} 