import { createContext, ReactNode, useContext, useState } from 'react';
import type { UserSearchResult } from '../api/user';

type MessageState = {
    conversationId: string;
    user?: UserSearchResult;
    title?: string;
    participants?: any[];
} | null;

interface MessageContextType {
    messageState: MessageState;
    openMessage: (params: { conversationId: string, user?: UserSearchResult, title?: string, participants?: any[] }) => void;
    openCreateMessage: () => void;
    closeMessage: () => void;
    closeCreateMessage: () => void;
    isCreateMessageOpen: boolean;
}

const MessageContext = createContext<MessageContextType | undefined>(undefined)

export const MessageProvider = ({ children }: { children: ReactNode }) => {
    const [messageState, setMessageState] = useState<MessageState>(null)
    const [isCreateMessageOpen, setIsCreateMessageOpen] = useState(false)

    const openMessage = ({ conversationId, user, title, participants }: {
        conversationId: string,
        user?: UserSearchResult,
        title?: string,
        participants?: any[]
    }) => {
        setMessageState({
            conversationId,
            user,
            title,
            participants
        })
    }

    const openCreateMessage = () => {
        setIsCreateMessageOpen(true)
    }

    const closeMessage = () => {
        setMessageState(null)
    }

    const closeCreateMessage = () => {
        setIsCreateMessageOpen(false)
    }

    return (
        <MessageContext.Provider value={{
            messageState,
            openMessage,
            openCreateMessage,
            closeMessage,
            closeCreateMessage,
            isCreateMessageOpen
        }}>
            {children}
        </MessageContext.Provider>
    )
}

export const useMessage = () => {
    const context = useContext(MessageContext)
    if (context === undefined) {
        throw new Error('useMessage must be used within a MessageProvider')
    }
    return context
} 