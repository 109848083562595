import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import MessageIcon from '@mui/icons-material/Message'
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary'
import SettingsIcon from '@mui/icons-material/Settings'
import {
    AppBar,
    Avatar,
    AvatarGroup,
    Box,
    Button,
    Chip,
    CircularProgress,
    IconButton,
    Stack,
    Toolbar,
    Typography
} from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getSession } from '../../api/session'
import EditSession from '../../components/EditSession/EditSession'
import { routeEnum } from '../../routes'
import Message from '../../pages/Message/Message'
import { useQueryClient } from '@tanstack/react-query'
import MediaModal from '../../components/MediaModal/MediaModal'

const SESSION_STEPS = [
    'Requested',
    'In Progress',
    'Complete'
] as const

const Session = () => {
    const navigate = useNavigate()
    const { sessionId } = useParams<{ sessionId: string }>()
    const [isEditSessionOpen, setIsEditSessionOpen] = useState(false)
    const [isChatOpen, setIsChatOpen] = useState(false)
    const [isMediaModalOpen, setIsMediaModalOpen] = useState(false)

    const queryClient = useQueryClient()

    const { data: session, isLoading } = useQuery({
        queryKey: ['session', sessionId],
        queryFn: () => sessionId ? getSession(sessionId) : Promise.reject('No session ID'),
        enabled: !!sessionId
    })

    // Get the active step based on session status
    const getActiveStep = (status: string) => {
        switch (status) {
            case 'REQUESTED':
                return 0
            case 'ACTIVE':
                return 1
            case 'COMPLETED':
                return 2
            default:
                return 0
        }
    }

    const activeStep = session ? getActiveStep(session.status) : 0

    const handleBack = () => {
        navigate(routeEnum.SESSIONS)
    }

    const handleMediaClick = () => {
        setIsMediaModalOpen(true)
    }

    const handleChatClick = () => {
        setIsChatOpen(true)
    }

    const handleEditSession = () => {
        setIsEditSessionOpen(true)
    }

    if (isLoading) {
        return (
            <Stack height="100%" alignItems="center" justifyContent="center">
                <CircularProgress />
            </Stack>
        )
    }

    if (!session) {
        return (
            <Stack height="100%" alignItems="center" justifyContent="center">
                <Typography>Session not found</Typography>
            </Stack>
        )
    }

    // Find the host participant
    const host = session.participants.find(p => p.role === 'HOST')

    return (
        <>
            <Stack height="100%">
                {/* Custom Header */}
                <AppBar
                    position="sticky"
                    elevation={0}
                    sx={{
                        bgcolor: 'background.paper',
                        borderBottom: '1px solid',
                        borderColor: 'divider'
                    }}
                >
                    <Toolbar sx={{
                        justifyContent: 'space-between',
                        minHeight: 56,
                        px: 2
                    }}>
                        <IconButton
                            edge="start"
                            onClick={handleBack}
                            sx={{ color: 'text.primary' }}
                        >
                            <ArrowBackIcon />
                        </IconButton>

                        <Typography
                            variant="subtitle1"
                            component="div"
                            sx={{
                                fontWeight: 600,
                                color: 'text.primary',
                                textAlign: 'center',
                                flex: 1,
                                mx: 2,
                                py: 2,
                                overflow: 'hidden',
                                display: '-webkit-box',
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: 'vertical',
                                lineHeight: 1.2
                            }}
                        >
                            {session.title}
                        </Typography>

                        <IconButton
                            edge="end"
                            onClick={handleEditSession}
                            sx={{ color: 'text.primary' }}
                        >
                            <SettingsIcon />
                        </IconButton>
                    </Toolbar>

                    {/* Quick Actions */}
                    <Stack
                        direction="row"
                        spacing={1}
                        sx={{
                            px: 2,
                            py: 1.5,
                            borderTop: '1px solid',
                            borderColor: 'divider'
                        }}
                    >
                        <Button
                            onClick={handleMediaClick}
                            startIcon={<PhotoLibraryIcon />}
                            variant="outlined"
                            size="small"
                            sx={{
                                borderRadius: 2,
                                flex: 1,
                                py: 1
                            }}
                        >
                            Media
                        </Button>
                        <Button
                            onClick={handleChatClick}
                            startIcon={<MessageIcon />}
                            variant="outlined"
                            size="small"
                            sx={{
                                borderRadius: 2,
                                flex: 1,
                                py: 1
                            }}
                        >
                            Chat
                        </Button>
                    </Stack>

                    {/* Subheader with Status and Participants */}
                    <Box
                        sx={{
                            px: 2,
                            py: 1.5,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            borderTop: '1px solid',
                            borderColor: 'divider'
                        }}
                    >
                        <Stack direction="row" spacing={1} alignItems="center">
                            <AvatarGroup
                                max={4}
                                sx={{
                                    '& .MuiAvatar-root': {
                                        width: 32,
                                        height: 32,
                                        fontSize: 14,
                                        border: '2px solid',
                                        borderColor: 'background.paper'
                                    }
                                }}
                            >
                                {session.participants.map((participant) => (
                                    <Avatar
                                        key={participant.id}
                                        alt={participant.name}
                                        src={participant.avatar || undefined}
                                        sx={{
                                            bgcolor: 'primary.main'
                                        }}
                                    >
                                        {participant.name[0]}
                                    </Avatar>
                                ))}
                            </AvatarGroup>
                            {host && (
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 0.5
                                    }}
                                >
                                    with {host.name}
                                </Typography>
                            )}
                        </Stack>

                        <Chip
                            label={SESSION_STEPS[activeStep]}
                            size="small"
                            sx={{
                                borderRadius: 1.5,
                                textTransform: 'capitalize',
                                fontWeight: 500
                            }}
                        />
                    </Box>

                    {/* Session Progress */}
                    <Box
                        sx={{
                            px: 2,
                            py: 1.5,
                            borderTop: '1px solid',
                            borderColor: 'divider'
                        }}
                    >
                        <Box
                            sx={{
                                position: 'relative',
                                display: 'flex',
                                alignItems: 'center',
                                height: 32,
                                bgcolor: 'grey.100',
                                borderRadius: 16,
                                overflow: 'hidden'
                            }}
                        >
                            {/* Progress Fill for each step */}
                            {SESSION_STEPS.map((_, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        position: 'absolute',
                                        left: `${(index / SESSION_STEPS.length) * 100}%`,
                                        top: 0,
                                        height: '100%',
                                        width: `${100 / SESSION_STEPS.length}%`,
                                        bgcolor: index <= activeStep ? 'primary.main' : 'transparent',
                                        transition: 'background-color 0.3s ease',
                                        borderRight: index < SESSION_STEPS.length - 1 ? '1px solid rgba(255, 255, 255, 0.3)' : 'none'
                                    }}
                                />
                            ))}

                            {/* Step Labels */}
                            <Stack
                                direction="row"
                                sx={{
                                    position: 'relative',
                                    width: '100%',
                                    height: '100%',
                                    zIndex: 1
                                }}
                            >
                                {SESSION_STEPS.map((step, index) => (
                                    <Box
                                        key={step}
                                        sx={{
                                            flex: 1,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            borderRight: index < SESSION_STEPS.length - 1 ? '1px solid' : 'none',
                                            borderColor: 'rgba(0, 0, 0, 0.12)'
                                        }}
                                    >
                                        <Typography
                                            variant="caption"
                                            sx={{
                                                fontWeight: index === activeStep ? 600 : 400,
                                                color: index <= activeStep ? 'white' : 'text.secondary',
                                                fontSize: '0.75rem',
                                                lineHeight: 1
                                            }}
                                        >
                                            {step}
                                        </Typography>
                                    </Box>
                                ))}
                            </Stack>
                        </Box>
                    </Box>
                </AppBar>

                {/* Main Content */}
                <Stack
                    spacing={3}
                    p={3}
                    maxWidth={600}
                    mx="auto"
                    sx={{
                        flex: 1,
                        overflowY: 'auto'
                    }}
                >
                </Stack>
            </Stack>

            <EditSession
                open={isEditSessionOpen}
                onClose={() => setIsEditSessionOpen(false)}
                initialSession={session}
            />
            
            {/* Session Chat */}
            <Message
                conversationId={session.chatId || `temp-session-${session.id}`}
                open={isChatOpen}
                onClose={() => setIsChatOpen(false)}
                title={session.title}
                participants={session.participants}
                onSend={async (content: string, fileInfo?: {
                    fileKey: string
                    fileName: string
                    fileType: string
                    fileSize: number
                }) => {
                    try {
                        // Create session chat if it doesn't exist
                        const response = await fetch(`${import.meta.env.VITE_API_URL}/sessions/${session.id}/chat`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${localStorage.getItem('token')}`
                            },
                            body: JSON.stringify({
                                content,
                                fileKey: fileInfo?.fileKey,
                                fileName: fileInfo?.fileName,
                                fileType: fileInfo?.fileType,
                                fileSize: fileInfo?.fileSize
                            })
                        });
                        
                        if (!response.ok) {
                            throw new Error('Failed to create session chat');
                        }
                        
                        // Refetch session to get the new chatId
                        queryClient.invalidateQueries({ queryKey: ['session', session.id] });
                    } catch (error) {
                        console.error('Error creating session chat:', error);
                    }
                }}
            />

            {/* Shared Media Modal */}
            <MediaModal
                open={isMediaModalOpen}
                onClose={() => setIsMediaModalOpen(false)}
                conversationId={session.chatId || `temp-session-${session.id}`}
            />
        </>
    )
}

export default Session 