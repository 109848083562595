import { Close as CloseIcon } from '@mui/icons-material'
import {
    Alert,
    Autocomplete,
    Avatar,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Stack,
    TextField,
    Typography
} from '@mui/material'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { createSession, updateSession, type SessionDto, type UpdateSessionRequest } from '../../api/session'
import { searchUsers, type UserSearchResult } from '../../api/user'
import ModalHeader from '../common/ModalHeader'

interface EditSessionProps {
    open: boolean
    onClose: () => void
    initialSession?: SessionDto
    initialParticipants?: UserSearchResult[]
    onSubmit?: (title: string) => Promise<void>
}

interface UpdateSessionMutationVariables {
    sessionId: string
    request: UpdateSessionRequest
}

const EditSession = ({ open, onClose, initialSession, initialParticipants, onSubmit }: EditSessionProps) => {
    const [title, setTitle] = useState('')
    const [searchQuery, setSearchQuery] = useState('')
    const [selectedParticipants, setSelectedParticipants] = useState<UserSearchResult[]>([])
    const [error, setError] = useState<string | null>(null)
    const queryClient = useQueryClient()

    // Reset form when opening/closing or when initialSession changes
    useEffect(() => {
        if (open) {
            if (initialSession) {
                setTitle(initialSession.title)
                setSelectedParticipants(
                    initialSession.participants
                        .filter(p => p.role !== 'HOST')
                        .map(p => ({
                            id: p.id,
                            name: p.name,
                            avatar: p.avatar || null,
                            stripeConnected: false
                        }))
                )
            } else if (initialParticipants) {
                setSelectedParticipants(initialParticipants)
            }
        } else {
            setTitle('')
            setSelectedParticipants([])
            setError(null)
        }
    }, [open, initialSession, initialParticipants])

    const { data: searchResults = [], isLoading } = useQuery({
        queryKey: ['users', 'search', searchQuery],
        queryFn: () => searchUsers(searchQuery),
        enabled: searchQuery.length >= 2,
    })

    const createSessionMutation = useMutation({
        mutationFn: createSession,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['sessions'] })
            handleClose()
        },
        onError: (err) => {
            setError('Failed to create session. Please try again.')
            console.error('Error creating session:', err)
        }
    })

    const updateSessionMutation = useMutation<SessionDto, Error, UpdateSessionMutationVariables>({
        mutationFn: ({ sessionId, request }) => updateSession(sessionId, request),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['sessions'] })
            if (initialSession) {
                queryClient.invalidateQueries({ queryKey: ['session', initialSession.id] })
            }
            handleClose()
        },
        onError: (err) => {
            setError('Failed to update session. Please try again.')
            console.error('Error updating session:', err)
        }
    })

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        if (!title.trim()) {
            setError('Please enter a session name')
            return
        }
        if (selectedParticipants.length === 0) {
            setError('Please select at least one participant')
            return
        }

        try {
            if (initialSession) {
                await updateSessionMutation.mutateAsync({
                    sessionId: initialSession.id,
                    request: {
                        title: title.trim(),
                        participantIds: selectedParticipants.map(p => p.id)
                    }
                })
            } else if (onSubmit) {
                await onSubmit(title.trim())
                handleClose()
            } else {
                await createSessionMutation.mutateAsync({
                    title: title.trim(),
                    participantIds: selectedParticipants.map(p => p.id)
                })
            }
        } catch (error) {
            console.error('Error submitting session:', error)
        }
    }

    const handleClose = () => {
        setTitle('')
        setSelectedParticipants([])
        setError(null)
        onClose()
    }

    const isPending = createSessionMutation.isPending || updateSessionMutation.isPending

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullScreen
            PaperProps={{
                sx: {
                    bgcolor: 'background.default'
                }
            }}
        >
            <ModalHeader onClose={handleClose} />

            <DialogContent>
                <Stack
                    component="form"
                    onSubmit={handleSubmit}
                    spacing={4}
                    sx={{
                        maxWidth: 600,
                        width: '100%',
                        mx: 'auto',
                        mt: 4,
                        px: 2
                    }}
                >
                    <Stack spacing={1}>
                        <Typography variant="h5" fontWeight={600}>
                            {initialSession ? 'Edit Session' : onSubmit ? 'Book New Session' : 'Create New Session'}
                        </Typography>
                        <Typography variant="body1" color="text.secondary">
                            {initialSession 
                                ? 'Update session details and participants' 
                                : onSubmit
                                    ? 'Request a new session with the artist'
                                    : 'Create a new session and invite participants'
                            }
                        </Typography>
                    </Stack>

                    <TextField
                        label="Session Name"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        fullWidth
                        required
                        placeholder="e.g., Production Session, Mix Review, Vocal Recording"
                        variant="outlined"
                        error={!!error && !title.trim()}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                bgcolor: 'rgba(0, 0, 0, 0.02)',
                                borderRadius: 2,
                                '&:hover': {
                                    bgcolor: 'rgba(0, 0, 0, 0.04)'
                                }
                            }
                        }}
                    />

                    <Stack spacing={1}>
                        <Typography variant="subtitle1" fontWeight={500}>
                            Participants
                        </Typography>
                        <Autocomplete
                            multiple
                            options={searchResults}
                            getOptionLabel={(option) => option.name}
                            value={selectedParticipants}
                            onChange={(_, newValue) => setSelectedParticipants(newValue)}
                            onInputChange={(_, newInputValue) => setSearchQuery(newInputValue)}
                            loading={isLoading}
                            filterOptions={(x) => x}
                            noOptionsText={
                                searchQuery.length < 2
                                    ? "Start typing to search users"
                                    : "No users found"
                            }
                            renderOption={(props, option) => (
                                <Stack component="li" {...props} direction="row" spacing={2} alignItems="center">
                                    <Avatar
                                        src={option.avatar || undefined}
                                        alt={option.name}
                                    >
                                        {option.name[0]}
                                    </Avatar>
                                    <Typography>{option.name}</Typography>
                                </Stack>
                            )}
                            renderTags={() => null}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Search and select participants"
                                    variant="outlined"
                                    error={!!error && selectedParticipants.length === 0}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                            </>
                                        ),
                                    }}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            bgcolor: 'rgba(0, 0, 0, 0.02)',
                                            borderRadius: 2,
                                            '&:hover': {
                                                bgcolor: 'rgba(0, 0, 0, 0.04)'
                                            }
                                        }
                                    }}
                                />
                            )}
                            clearIcon={null}
                            disableClearable
                        />
                        {selectedParticipants.length > 0 && (
                            <Stack spacing={3}>
                                <Stack direction="row" alignItems="center" spacing={1}>
                                    <Typography variant="body2" color="text.secondary" fontWeight={500}>
                                        {selectedParticipants.length} participant{selectedParticipants.length !== 1 ? 's' : ''} added
                                    </Typography>
                                </Stack>
                                <Stack
                                    sx={{
                                        height: 500,
                                        overflow: 'auto',
                                        '&::-webkit-scrollbar': {
                                            width: '8px',
                                        },
                                        '&::-webkit-scrollbar-track': {
                                            backgroundColor: 'transparent',
                                        },
                                        '&::-webkit-scrollbar-thumb': {
                                            backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                            borderRadius: '4px',
                                        },
                                    }}
                                >
                                    <List disablePadding>
                                        {selectedParticipants.map((participant, index) => (
                                            <ListItem
                                                key={participant.id}
                                                secondaryAction={
                                                    <IconButton
                                                        edge="end"
                                                        aria-label="remove"
                                                        onClick={() => {
                                                            const newParticipants = [...selectedParticipants];
                                                            newParticipants.splice(index, 1);
                                                            setSelectedParticipants(newParticipants);
                                                        }}
                                                    >
                                                        <CloseIcon />
                                                    </IconButton>
                                                }
                                                sx={{
                                                    py: 1,
                                                    borderBottom: '1px solid',
                                                    borderColor: 'divider',
                                                    '&:last-child': {
                                                        borderBottom: 'none'
                                                    }
                                                }}
                                            >
                                                <ListItemAvatar>
                                                    <Avatar
                                                        src={participant.avatar || undefined}
                                                        alt={participant.name}
                                                    >
                                                        {participant.name[0]}
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={participant.name}
                                                    sx={{
                                                        '& .MuiListItemText-primary': {
                                                            fontWeight: 500
                                                        }
                                                    }}
                                                />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Stack>
                            </Stack>
                        )}
                    </Stack>

                    {error && (
                        <Alert severity="error" onClose={() => setError(null)}>
                            {error}
                        </Alert>
                    )}

                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        spacing={2}
                        sx={{
                            mt: 4,
                            position: 'sticky',
                            bottom: 16,
                            pb: 2,
                            pt: 2,
                            bgcolor: 'background.default',
                            borderTop: 1,
                            borderColor: 'divider'
                        }}
                    >
                        <Button
                            variant="outlined"
                            onClick={handleClose}
                            sx={{
                                borderRadius: 2,
                                px: 3,
                                py: 1
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            disabled={isPending}
                            sx={{
                                borderRadius: 2,
                                px: 4,
                                py: 1
                            }}
                        >
                            {isPending ? (
                                <CircularProgress size={24} color="inherit" />
                            ) : (
                                initialSession ? 'Save Changes' : onSubmit ? 'Request Session' : 'Create Session'
                            )}
                        </Button>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}

export default EditSession 