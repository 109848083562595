import { Box, type SxProps } from '@mui/material'
import { type FC } from 'react'

interface ImageProps {
    src: string
    alt: string
    width?: string | number
    height?: string | number
    fit?: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down'
    position?: 'center' | 'top' | 'bottom' | 'left' | 'right'
    loading?: 'eager' | 'lazy'
    sx?: SxProps
    className?: string
    onLoad?: () => void
}

const Image: FC<ImageProps> = ({
    src,
    alt,
    width = '100%',
    height = '100%',
    fit = 'contain',
    position = 'center',
    loading = 'lazy',
    sx,
    className,
    onLoad
}) => (
    <Box
        component="figure"
        display="flex"
        alignItems="center"
        justifyContent="center"
        width={width}
        height={height}
        m={0}
        sx={sx}
        className={className}
    >
        <Box
            component="img"
            src={src}
            alt={alt}
            width="100%"
            height="100%"
            loading={loading}
            onLoad={onLoad}
            sx={{
                objectFit: fit,
                objectPosition: position,
                display: 'block',
                maxWidth: '100%',
                maxHeight: '100%',
            }}
        />
    </Box>
)

export default Image 