import { api } from '../util/api'

interface ConnectAccountResponse {
    accountId: string
    accountLink: string
}

export const createConnectAccount = async (): Promise<ConnectAccountResponse> => {
    return api.post<ConnectAccountResponse>('/stripe/connect/account', {})
}

export const checkAccountStatus = async (accountId: string): Promise<boolean> => {
    return api.get<boolean>(`/stripe/connect/account/status?accountId=${accountId}`)
}

export const createNewAccountLink = async (accountId: string): Promise<string> => {
    return api.post<string>(`/stripe/connect/account/${accountId}/link`, {})
}

export const disconnectAccount = async (): Promise<{ success: boolean, message: string }> => {
    return api.post('/stripe/connect/disconnect', {})
} 