import { useEffect, useRef, useState, useCallback } from 'react'
import { Client } from '@stomp/stompjs'
import SockJS from 'sockjs-client'
import { MessageDto, SendMessageRequest } from '../types/conversation'
import { config } from '../config'
import { useNavigate } from 'react-router-dom'

interface UseWebSocketOptions {
    onMessage?: (message: MessageDto) => void
    onError?: (error: any) => void
    onReadReceipt?: (update: ReadStatusUpdate) => void
    onTypingStatus?: (update: TypingStatusUpdate) => void
}

export interface ReadStatusUpdate {
    conversationId: string
    userId: string
    messageId: string
    readByUser: {
        id: string
        name: string
        avatar?: string
    }
}

export interface TypingStatusUpdate {
    conversationId: string
    userId: string
    userName: string
    isTyping: boolean
}

export const useWebSocket = ({ onMessage, onError, onReadReceipt, onTypingStatus }: UseWebSocketOptions = {}) => {
    const [isConnected, setIsConnected] = useState(false)
    const [error, setError] = useState<Error | null>(null)
    const clientRef = useRef<Client | null>(null)
    const navigate = useNavigate()
    const subscriptions = useRef<Map<string, any>>(new Map())

    useEffect(() => {
        const token = localStorage.getItem('token')
        if (!token) {
            console.debug('No token found, redirecting to login')
            setError(new Error('No authentication token found'))
            navigate('/login')
            return
        }

        // Check if token is expired before attempting connection
        try {
            const tokenData = JSON.parse(atob(token.split('.')[1]))
            const expirationTime = tokenData.exp * 1000 // Convert to milliseconds
            if (Date.now() >= expirationTime) {
                console.debug('Token expired, redirecting to login')
                localStorage.removeItem('token')
                navigate('/login')
                return
            }
        } catch (error) {
            console.error('Error parsing token:', error)
            localStorage.removeItem('token')
            navigate('/login')
            return
        }

        // Log configuration
        console.debug('WebSocket Configuration:', {
            apiUrl: config.apiUrl,
            wsUrl: config.wsUrl,
            brokerUrl: config.wsUrl,
            token: token ? `${token.substring(0, 20)}...` : 'none'
        })

        const client = new Client({
            brokerURL: config.wsUrl,
            webSocketFactory: () => {
                const sockjsUrl = `${config.apiUrl}/ws?access_token=${encodeURIComponent(token)}`
                console.debug('Creating SockJS connection to:', sockjsUrl, 'with broker URL:', config.wsUrl)
                const socket = new SockJS(sockjsUrl)
                
                socket.onopen = () => {
                    console.debug('SockJS: Socket opened')
                    setError(null)
                }
                socket.onclose = (event) => {
                    console.debug('SockJS: Socket closed', event)
                    if (event.code === 1006) {
                        console.error('Abnormal closure - server might be down or network issues')
                        setError(new Error('Connection lost. Please check your internet connection.'))
                    }
                }
                socket.onerror = (event) => {
                    console.error('SockJS: Socket error', event)
                    setError(new Error('WebSocket connection error'))
                }
                
                return socket
            },
            connectHeaders: {
                Authorization: `Bearer ${token}`
            },
            debug: (msg: string) => {
                console.debug('STOMP:', msg)
            },
            reconnectDelay: 5000,
            heartbeatIncoming: 4000,
            heartbeatOutgoing: 4000
        })

        client.onConnect = () => {
            console.debug('Connected to WebSocket')
            setIsConnected(true)
            setError(null)

            // Subscribe to personal message queue
            const messageSubscription = client.subscribe('/user/queue/messages', (message) => {
                try {
                    console.debug('Received WebSocket message:', message)
                    const payload = JSON.parse(message.body)
                    console.debug('Parsed message payload:', payload)
                    onMessage?.(payload)
                } catch (error) {
                    console.error('Error parsing message:', error)
                    onError?.(error)
                }
            })
            subscriptions.current.set('messages', messageSubscription)

            // Subscribe to read receipts
            const readReceiptSubscription = client.subscribe('/user/queue/read-receipts', (message) => {
                try {
                    console.debug('Received read receipt:', message)
                    const payload = JSON.parse(message.body) as ReadStatusUpdate
                    console.debug('Parsed read receipt payload:', payload)
                    onReadReceipt?.(payload)
                } catch (error) {
                    console.error('Error parsing read receipt:', error)
                    onError?.(error)
                }
            })
            subscriptions.current.set('read-receipts', readReceiptSubscription)

            // Subscribe to typing status
            const typingStatusSubscription = client.subscribe('/user/queue/typing-status', (message) => {
                try {
                    console.debug('Received typing status:', message)
                    const payload = JSON.parse(message.body) as TypingStatusUpdate
                    console.debug('Parsed typing status payload:', payload)
                    onTypingStatus?.(payload)
                } catch (error) {
                    console.error('Error parsing typing status:', error)
                    onError?.(error)
                }
            })
            subscriptions.current.set('typing-status', typingStatusSubscription)

            // Subscribe to errors
            const errorSubscription = client.subscribe('/user/queue/errors', (message) => {
                try {
                    console.debug('Received WebSocket error:', message)
                    const payload = JSON.parse(message.body)
                    console.error('WebSocket error payload:', payload)
                    onError?.(payload)
                } catch (error) {
                    console.error('Error parsing error message:', error)
                }
            })
            subscriptions.current.set('errors', errorSubscription)
        }

        client.onStompError = (frame) => {
            console.error('STOMP error:', frame)
            setError(new Error(frame.headers.message))
            onError?.(frame)
            
            if (frame.headers.message?.toLowerCase().includes('jwt') || 
                frame.headers.message?.toLowerCase().includes('token') ||
                frame.headers.message?.toLowerCase().includes('auth')) {
                console.debug('Authentication error, redirecting to login')
                localStorage.removeItem('token')
                navigate('/login')
            }
        }

        client.onWebSocketError = (event) => {
            console.error('WebSocket error:', event)
            setError(new Error('WebSocket connection error'))
            onError?.(event)

            if (event instanceof CloseEvent) {
                console.debug('WebSocket close event:', event.code)
                switch (event.code) {
                    case 1000: // Normal closure
                        break
                    case 1001: // Going away
                        setError(new Error('Server is shutting down'))
                        break
                    case 1006: // Abnormal closure
                        setError(new Error('Connection lost. Please check your internet connection.'))
                        break
                    case 1008: // Policy violation
                    case 1011: // Internal error
                        console.debug('WebSocket error, redirecting to login')
                        localStorage.removeItem('token')
                        navigate('/login')
                        break
                    default:
                        setError(new Error(`WebSocket closed with code ${event.code}`))
                }
            }
        }

        client.onDisconnect = () => {
            console.debug('Disconnected from WebSocket')
            setIsConnected(false)
            
            // Clean up subscriptions
            subscriptions.current.forEach((subscription) => {
                try {
                    subscription.unsubscribe()
                } catch (error) {
                    console.error('Error unsubscribing:', error)
                }
            })
            subscriptions.current.clear()
        }

        clientRef.current = client

        try {
            console.debug('Activating STOMP client...')
            client.activate()
        } catch (error) {
            console.error('Error activating client:', error)
            setError(error instanceof Error ? error : new Error('Failed to connect'))
            onError?.(error)
        }

        return () => {
            console.debug('Cleaning up WebSocket connection')
            if (client.active) {
                client.deactivate()
            }
        }
    }, [onMessage, onError, onReadReceipt, onTypingStatus, navigate])

    const sendMessage = useCallback((conversationId: string, request: SendMessageRequest) => {
        if (!clientRef.current?.active || !isConnected) {
            console.error('WebSocket not connected')
            onError?.({ error: 'WebSocket not connected' })
            return
        }

        try {
            clientRef.current.publish({
                destination: `/app/chat/${conversationId}`,
                body: JSON.stringify(request),
                headers: { 'content-type': 'application/json' }
            })
        } catch (error) {
            console.error('Error sending message:', error)
            onError?.(error)
        }
    }, [isConnected, onError])

    const markMessagesAsRead = useCallback(async (conversationId: string) => {
        try {
            const response = await fetch(`${config.apiUrl}/messages/${conversationId}/read`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            })
            
            if (!response.ok) {
                throw new Error('Failed to mark messages as read')
            }
        } catch (error) {
            console.error('Error marking messages as read:', error)
            onError?.(error)
            throw error
        }
    }, [onError])

    const sendTypingStatus = useCallback((conversationId: string, isTyping: boolean) => {
        if (!clientRef.current?.active || !isConnected) {
            console.error('WebSocket not connected')
            onError?.({ error: 'WebSocket not connected' })
            return
        }

        try {
            clientRef.current.publish({
                destination: '/app/chat/typing',
                body: JSON.stringify({ conversationId, isTyping }),
                headers: { 'content-type': 'application/json' }
            })
        } catch (error) {
            console.error('Error sending typing status:', error)
            onError?.(error)
        }
    }, [isConnected, onError])

    return {
        isConnected,
        error,
        sendMessage,
        markMessagesAsRead,
        sendTypingStatus
    }
}

export default useWebSocket