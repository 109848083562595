import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { api, getCurrentUserId } from '../util/api'
import { ConversationResponse, MessageDto } from '../types/conversation'
import { useNavigate } from 'react-router-dom'
import { routeEnum } from '../routes'

interface SendMessageRequest {
    content: string
}

interface UseConversationOptions {
    enabled?: boolean
}

export const useConversation = (userId: string, options: UseConversationOptions = {}) => {
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const currentUserId = getCurrentUserId()

    const { data: conversation, isLoading, error } = useQuery<ConversationResponse>({
        queryKey: ['conversation', userId],
        queryFn: async () => {
            try {
                // Try to get existing conversation
                return await api.get(`/messages/${userId}`)
            } catch (error) {
                // If no conversation exists, return a temporary conversation object
                if (error instanceof Error && error.message.includes('Conversation not found')) {
                    return {
                        id: `temp-${userId}`,
                        messages: [],
                        participants: [
                            // We'll get the actual user details from the backend when sending the first message
                            { id: userId, name: 'Loading...', avatar: null }
                        ],
                        hasMoreMessages: false
                    }
                }
                throw error
            }
        },
        enabled: !!userId && !!currentUserId && options.enabled !== false,
        staleTime: 0, // Always fetch fresh data
        retry: (failureCount, error) => {
            // Don't retry on authentication errors
            if (error instanceof Error && error.message === 'No authentication token found') {
                navigate(routeEnum.LOGIN)
                return false
            }
            // Retry up to 3 times for other errors
            return failureCount < 3
        }
    })

    const { mutate: sendMessage, isPending: isSending } = useMutation({
        mutationFn: async (content: string) => {
            // If this is a temporary conversation, create a new one
            if (conversation?.id.startsWith('temp-')) {
                return api.post<MessageDto>(`/messages/new/${userId}`, { content } as SendMessageRequest)
            }
            // Otherwise, send to existing conversation
            return api.post<MessageDto>(`/messages/${conversation?.id}`, { content } as SendMessageRequest)
        },
        onMutate: async (content) => {
            // Cancel any outgoing refetches
            await queryClient.cancelQueries({ queryKey: ['conversation', userId] })

            // Snapshot the previous value
            const previousConversation = queryClient.getQueryData<ConversationResponse>(['conversation', userId])

            // Optimistically update the conversation
            if (previousConversation) {
                const optimisticMessage: MessageDto = {
                    id: 'temp-' + Date.now(),
                    content,
                    senderId: currentUserId || '',
                    senderName: previousConversation.participants.find(p => p.id === currentUserId)?.name || '',
                    senderAvatar: previousConversation.participants.find(p => p.id === currentUserId)?.avatar || null,
                    timestamp: new Date().toISOString(),
                    isRead: false
                }

                queryClient.setQueryData<ConversationResponse>(['conversation', userId], {
                    ...previousConversation,
                    messages: [...previousConversation.messages, optimisticMessage]
                })
            }

            return { previousConversation }
        },
        onSuccess: (newMessage, _) => {
            // Update the conversation with the real conversation ID if it was temporary
            if (conversation?.id.startsWith('temp-')) {
                queryClient.invalidateQueries({ queryKey: ['conversation', userId] })
                queryClient.invalidateQueries({ queryKey: ['conversations'] })
            } else {
                queryClient.setQueryData<ConversationResponse>(['conversation', userId], (old) => {
                    if (!old) return old
                    return {
                        ...old,
                        messages: [...old.messages.filter(m => !m.id.startsWith('temp-')), newMessage]
                    }
                })

                queryClient.setQueryData<ConversationResponse[]>(
                    ['conversations'],
                    (old) => {
                        if (!old) return old
                        return old.map(conv => {
                            if (conv.id === conversation?.id) {
                                return {
                                    ...conv,
                                    lastMessage: newMessage
                                }
                            }
                            return conv
                        })
                    }
                )
            }
        },
        onError: (error, _, context) => {
            // Rollback on error
            if (context?.previousConversation) {
                queryClient.setQueryData(['conversation', userId], context.previousConversation)
            }
            if (error instanceof Error && error.message === 'No authentication token found') {
                navigate(routeEnum.LOGIN)
            }
        },
        onSettled: () => {
            // Always refetch after error or success
            queryClient.invalidateQueries({ queryKey: ['conversation', userId] })
        }
    })

    return {
        conversation,
        isLoading,
        error: error?.message,
        hasError: !!error,
        sendMessage,
        isSending,
        currentUserId
    }
} 