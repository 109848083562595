import { useQuery } from '@tanstack/react-query'
import { getSessions, SessionStatus } from '../api/session'

export const useRequestCount = () => {
    const { data: sessions = [] } = useQuery({
        queryKey: ['sessions'],
        queryFn: getSessions
    })

    const requestCount = sessions.filter(session => session.status === SessionStatus.REQUESTED).length

    return { requestCount }
} 