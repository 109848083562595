import { useEffect, useState } from 'react'
import { useRoutes } from 'react-router-dom'
import LoadingScreen from './components/LoadingScreen/LoadingScreen'
import { routes } from './routes'

const App = () => {
    // Initialize presence WebSocket at the app root level
    // usePresence()

    const element = useRoutes(routes)
    const [fontsLoaded, setFontsLoaded] = useState(false)

    useEffect(() => {
        // Check if fonts are already loaded (in case the script finished before React mounted)
        if (document.documentElement.classList.contains('fonts-loaded')) {
            setFontsLoaded(true)
            return
        }

        // Otherwise, wait for the fonts-loaded class
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.target instanceof HTMLElement &&
                    mutation.target.classList.contains('fonts-loaded')) {
                    setFontsLoaded(true)
                    observer.disconnect()
                }
            })
        })

        observer.observe(document.documentElement, {
            attributes: true,
            attributeFilter: ['class']
        })

        // Cleanup
        return () => observer.disconnect()
    }, [])

    if (!fontsLoaded) {
        return <LoadingScreen />
    }

    return (
        <div className="app">
            {element}
        </div>
    )
}

export default App
