import AttachFileIcon from '@mui/icons-material/AttachFile'
import AudioFileIcon from '@mui/icons-material/AudioFile'
import CloseIcon from '@mui/icons-material/Close'
import ImageIcon from '@mui/icons-material/Image'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import SendIcon from '@mui/icons-material/Send'
import VideoFileIcon from '@mui/icons-material/VideoFile'
import { Box, CircularProgress, IconButton, InputBase, Paper, Stack, Typography } from '@mui/material'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useChat } from '../../hooks/useChat'
import { formatFileSize, isAudioFile, isImageFile, isVideoFile, uploadFile } from '../../util/fileUpload'

interface MessageInputProps {
    conversationId: string
    onSend: (content: string, fileInfo?: {
        fileKey: string
        fileName: string
        fileType: string
        fileSize: number
    }) => void
    disabled?: boolean
}

const getFileIcon = (type: string) => {
    if (isImageFile(type)) return <ImageIcon sx={{ fontSize: 20, color: 'success.main' }} />
    if (isAudioFile(type)) return <AudioFileIcon sx={{ fontSize: 20, color: 'primary.main' }} />
    if (isVideoFile(type)) return <VideoFileIcon sx={{ fontSize: 20, color: 'error.main' }} />
    if (type.toLowerCase().includes('pdf')) return <PictureAsPdfIcon sx={{ fontSize: 20, color: 'warning.main' }} />
    return <InsertDriveFileIcon sx={{ fontSize: 20, color: 'text.secondary' }} />
}

const MessageInput = ({ conversationId, onSend, disabled }: MessageInputProps) => {
    const [message, setMessage] = useState('')
    const [isUploading, setIsUploading] = useState(false)
    const [uploadError, setUploadError] = useState<string | null>(null)
    const [selectedFile, setSelectedFile] = useState<File | null>(null)
    const fileInputRef = useRef<HTMLInputElement>(null)
    const lastTypingStatusRef = useRef<boolean>(false)
    const inactivityTimeoutRef = useRef<NodeJS.Timeout>()
    const { sendTypingStatus } = useChat()

    // Clear typing status and timeouts
    const clearTypingStatus = useCallback(() => {
        if (lastTypingStatusRef.current) {
            lastTypingStatusRef.current = false;
            sendTypingStatus(conversationId, false);
        }
        if (inactivityTimeoutRef.current) {
            clearTimeout(inactivityTimeoutRef.current);
        }
    }, [conversationId, sendTypingStatus]);

    // Cleanup effect
    useEffect(() => {
        return () => {
            clearTypingStatus();
        };
    }, [clearTypingStatus]);

    const handleMessageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newMessage = e.target.value;
        setMessage(newMessage);

        // Only handle typing status for non-temporary conversations
        if (!conversationId.startsWith('temp-')) {
            // Clear any existing inactivity timeout
            if (inactivityTimeoutRef.current) {
                clearTimeout(inactivityTimeoutRef.current);
            }

            if (newMessage.length > 0) {
                // Send typing status if we weren't typing before
                if (!lastTypingStatusRef.current) {
                    lastTypingStatusRef.current = true;
                    sendTypingStatus(conversationId, true);
                }

                // Set up a new inactivity timeout (5 seconds)
                inactivityTimeoutRef.current = setTimeout(clearTypingStatus, 5000);
            } else {
                // If message is empty, clear typing status
                clearTypingStatus();
            }
        }
    };

    const handleSend = async () => {
        if (!message.trim() && !selectedFile) return

        // Clear typing status before sending
        clearTypingStatus();

        if (selectedFile) {
            setIsUploading(true)
            setUploadError(null)
            try {
                const response = await uploadFile(selectedFile, 'conversations')
                console.debug('File upload response:', response)
                onSend(message.trim(), {
                    fileKey: response.key,
                    fileName: response.name,
                    fileType: response.type,
                    fileSize: response.size
                })
            } catch (error) {
                setUploadError(error instanceof Error ? error.message : 'Failed to upload file')
                return
            } finally {
                setIsUploading(false)
            }
        } else {
            onSend(message.trim())
        }

        setMessage('')
        setSelectedFile(null)
        if (fileInputRef.current) {
            fileInputRef.current.value = ''
        }
    };

    const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0]
        if (!file) return

        // Reset input value to allow selecting the same file again
        event.target.value = ''

        if (file.size > 25 * 1024 * 1024) { // 10MB limit
            setUploadError('File size must be less than 10MB')
            return
        }

        setUploadError(null)
        setSelectedFile(file)
    }

    return (
        <Stack
            sx={{
                p: 2,
                bgcolor: 'background.paper',
                borderTop: 1,
                borderColor: 'divider',
                flexShrink: 0,
                backdropFilter: 'blur(10px)',
                WebkitBackdropFilter: 'blur(10px)',
                zIndex: 10
            }}
        >
            {selectedFile && (
                <Paper
                    variant="outlined"
                    sx={{
                        p: 1,
                        mb: 1,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1
                    }}
                >
                    {getFileIcon(selectedFile.type)}
                    <Box sx={{ flex: 1 }}>
                        <Typography variant="body2">
                            {selectedFile.name}
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                            {formatFileSize(selectedFile.size)}
                        </Typography>
                    </Box>
                    <IconButton
                        size="small"
                        onClick={() => {
                            setSelectedFile(null)
                            setUploadError(null)
                            if (fileInputRef.current) {
                                fileInputRef.current.value = ''
                            }
                        }}
                        sx={{ color: 'text.secondary' }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Paper>
            )}

            {uploadError && (
                <Typography color="error" variant="caption" sx={{ mb: 1 }}>
                    {uploadError}
                </Typography>
            )}

            <Paper
                variant="outlined"
                sx={{
                    p: 1,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1
                }}
            >
                <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileSelect}
                    style={{ display: 'none' }}
                    accept="image/*,audio/*,.wav,.aif,.aiff,.m4a,.mp3,.wma,.aac,video/*,application/pdf"
                />
                <IconButton
                    onClick={() => fileInputRef.current?.click()}
                    disabled={disabled || isUploading}
                    sx={{ color: 'text.secondary' }}
                >
                    {isUploading ? (
                        <CircularProgress size={24} />
                    ) : (
                        <AttachFileIcon />
                    )}
                </IconButton>
                <InputBase
                    fullWidth
                    multiline
                    maxRows={4}
                    placeholder="Type a message..."
                    value={message}
                    onChange={handleMessageChange}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                            e.preventDefault()
                            handleSend()
                        }
                    }}
                    disabled={disabled || isUploading}
                    sx={{ px: 1 }}
                />
                <IconButton
                    color="primary"
                    onClick={handleSend}
                    disabled={disabled || isUploading || (!message.trim() && !selectedFile)}
                    sx={{
                        opacity: (disabled || isUploading || (!message.trim() && !selectedFile)) ? 0.5 : 1,
                        transition: 'opacity 0.2s'
                    }}
                >
                    <SendIcon />
                </IconButton>
            </Paper>
        </Stack>
    )
}

export default MessageInput 