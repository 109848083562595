import { config } from '../config'

export interface FileUploadResponse {
    key: string
    name: string
    type: string
    size: number
    url: string
}

export const uploadFile = async (file: File, type: string): Promise<FileUploadResponse> => {
    const formData = new FormData()
    formData.append('file', file)

    const token = localStorage.getItem('token')
    if (!token && type !== 'profile-pictures') {
        throw new Error('No authentication token found')
    }

    const headers: Record<string, string> = {}
    if (token) {
        headers['Authorization'] = `Bearer ${token}`
    }

    const response = await fetch(`${config.apiUrl}/files/upload/${type}`, {
        method: 'POST',
        headers,
        body: formData
    })

    if (!response.ok) {
        const error = await response.json()
        throw new Error(error.message || 'Failed to upload file')
    }

    return response.json()
}

export const formatFileSize = (bytes: number): string => {
    if (bytes === 0) return '0 B'
    const k = 1024
    const sizes = ['B', 'KB', 'MB', 'GB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(1))} ${sizes[i]}`
}

export const isImageFile = (type: string): boolean => {
    return type.toLowerCase().startsWith('image/')
}

export const isAudioFile = (type: string): boolean => {
    return type.toLowerCase().startsWith('audio/')
}

export const isVideoFile = (type: string): boolean => {
    return type.toLowerCase().startsWith('video/')
}

/**
 * Generates a tiny placeholder URL for initial loading
 * @param url Original image URL
 * @returns URL for a tiny placeholder image
 */
export const getPlaceholderUrl = (url: string): string => {
    if (!url) return url

    try {
        const urlObj = new URL(url)
        
        // Only add transformation if it's a DigitalOcean Spaces URL
        if (urlObj.hostname.includes('digitaloceanspaces.com')) {
            urlObj.searchParams.set('w', '20') // Tiny width
            urlObj.searchParams.set('h', '20') // Tiny height
            urlObj.searchParams.set('q', '50') // Lower quality is fine for placeholder
            urlObj.searchParams.set('blur', '10') // Add blur effect
            urlObj.searchParams.set('fit', 'cover')
            return urlObj.toString()
        }
        
        return url
    } catch (e) {
        console.warn('Invalid URL for placeholder generation:', url)
        return url
    }
}

/**
 * Generates an optimized thumbnail URL with the specified dimensions
 * @param url Original image URL
 * @param width Desired width
 * @param height Desired height
 * @returns URL with optimization parameters
 */
export const getThumbnailUrl = (url: string, width: number = 100, height: number = 100): string => {
    if (!url) return url

    try {
        const urlObj = new URL(url)
        
        // Only add transformation if it's a DigitalOcean Spaces URL
        if (urlObj.hostname.includes('digitaloceanspaces.com')) {
            // Calculate 2x dimensions for retina displays
            const retinaDimensions = {
                width: Math.min(width * 2, 1500), // Cap at 1500px
                height: Math.min(height * 2, 1500)
            }

            urlObj.searchParams.set('w', retinaDimensions.width.toString())
            urlObj.searchParams.set('h', retinaDimensions.height.toString())
            urlObj.searchParams.set('fit', 'inside') // Preserve aspect ratio
            urlObj.searchParams.set('q', '85') // Good balance of quality and size
            urlObj.searchParams.set('fm', 'webp') // Use WebP format when supported
            urlObj.searchParams.set('auto', 'compress') // Automatic compression
            
            // Add caching headers
            urlObj.searchParams.set('max-age', '31536000') // Cache for 1 year
            urlObj.searchParams.set('s-maxage', '31536000')
            
            return urlObj.toString()
        }
        
        return url
    } catch (e) {
        console.warn('Invalid URL for thumbnail generation:', url)
        return url
    }
} 