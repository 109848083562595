import { ArrowBack } from '@mui/icons-material'
import {
    Alert,
    AppBar,
    Avatar,
    Box,
    Container,
    IconButton,
    Paper,
    Snackbar,
    Stack,
    Toolbar,
    Typography
} from '@mui/material'
import { loadStripe, PaymentRequest } from '@stripe/stripe-js'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { routeEnum } from '../../routes'
import { formatCurrency } from '../../util/format'

// Initialize Stripe
const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY)

// Mock data - In real app, fetch this from API
const mockPaymentDetails = {
    sessionId: 'session_123',
    title: 'Portrait Photography Session',
    photographer: {
        name: 'Sarah Johnson',
        avatar: 'https://randomuser.me/api/portraits/women/44.jpg'
    },
    price: 15000, // in cents
    serviceFee: 1500, // in cents
    total: 16500, // in cents
    date: '2024-04-15T14:00:00Z',
    location: 'Central Park, New York'
}

const Payment = () => {
    const { sessionId } = useParams()
    const navigate = useNavigate()
    const [isProcessing, setIsProcessing] = useState(false)
    const [error, setError] = useState<string | null>(null)
    const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(null)
    const [canMakePayment, setCanMakePayment] = useState(false)

    console.log('isprocessing', isProcessing)
    
    useEffect(() => {
        const initializePaymentRequest = async () => {
            const stripe = await stripePromise

            if (!stripe) {
                setError('Failed to load payment system')
                return
            }

            const total = mockPaymentDetails.price + mockPaymentDetails.serviceFee

            const pr = stripe.paymentRequest({
                country: 'US',
                currency: 'usd',
                total: {
                    label: mockPaymentDetails.title,
                    amount: total,
                },
                requestPayerName: true,
                requestPayerEmail: true,
            })

            // Check if Apple Pay is available
            const result = await pr.canMakePayment()
            if (result && result.applePay) {
                setPaymentRequest(pr)
                setCanMakePayment(true)
            }

            // Handle payment success
            pr.on('paymentmethod', async (event: any) => {
                setIsProcessing(true)
                try {
                    // TODO: Send to your server to process payment
                    console.log('Processing payment...', event.paymentMethod)

                    // For now, just simulate success
                    await new Promise(resolve => setTimeout(resolve, 2000))

                    event.complete('success')
                    navigate(`${routeEnum.SESSION.replace(':sessionId', sessionId || '')}`)
                } catch (error) {
                    console.error('Payment failed:', error)
                    event.complete('fail')
                    setError('Payment failed. Please try again.')
                    setIsProcessing(false)
                }
            })
        }

        initializePaymentRequest()
    }, [sessionId, navigate])

    const handleBack = () => {
        navigate(`${routeEnum.SESSION.replace(':sessionId', sessionId || '')}`)
    }

    const total = mockPaymentDetails.price + mockPaymentDetails.serviceFee

    return (
        <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
            <AppBar position="static" elevation={0} sx={{ bgcolor: 'background.paper' }}>
                <Toolbar>
                    <IconButton edge="start" onClick={handleBack} aria-label="back">
                        <ArrowBack />
                    </IconButton>
                    <Typography variant="h6" sx={{ ml: 2, color: 'text.primary' }}>
                        Payment Details
                    </Typography>
                </Toolbar>
            </AppBar>

            <Container maxWidth="sm" sx={{ flex: 1, py: 3 }}>
                <Stack spacing={3}>
                    <Paper elevation={0} sx={{ p: 3 }}>
                        <Stack spacing={2}>
                            <Typography variant="h6">{mockPaymentDetails.title}</Typography>
                            <Stack direction="row" spacing={2} alignItems="center">
                                <Avatar
                                    src={mockPaymentDetails.photographer.avatar}
                                    alt={mockPaymentDetails.photographer.name}
                                />
                                <Typography>{mockPaymentDetails.photographer.name}</Typography>
                            </Stack>
                            <Typography variant="body2" color="text.secondary">
                                {new Date(mockPaymentDetails.date).toLocaleDateString()} • {mockPaymentDetails.location}
                            </Typography>
                        </Stack>
                    </Paper>

                    <Paper elevation={0} sx={{ p: 3 }}>
                        <Stack spacing={2}>
                            <Typography variant="h6">Payment Summary</Typography>
                            <Stack spacing={1}>
                                <Stack direction="row" justifyContent="space-between">
                                    <Typography>Session Fee</Typography>
                                    <Typography>{formatCurrency(mockPaymentDetails.price / 100)}</Typography>
                                </Stack>
                                <Stack direction="row" justifyContent="space-between">
                                    <Typography>Service Fee</Typography>
                                    <Typography>{formatCurrency(mockPaymentDetails.serviceFee / 100)}</Typography>
                                </Stack>
                                <Box sx={{ height: 1, bgcolor: 'divider', my: 1 }} />
                                <Stack direction="row" justifyContent="space-between">
                                    <Typography fontWeight="bold">Total</Typography>
                                    <Typography fontWeight="bold">{formatCurrency(total / 100)}</Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Paper>
                </Stack>
            </Container>

            <Paper
                elevation={0}
                sx={{
                    p: 2,
                    borderTop: 1,
                    borderColor: 'divider',
                    position: 'sticky',
                    bottom: 0,
                    bgcolor: 'background.paper',
                    zIndex: 1
                }}
            >
                {!canMakePayment && (
                    <Alert severity="info" sx={{ mb: 2 }}>
                        Apple Pay is not available. Please use a different payment method.
                    </Alert>
                )}
                {paymentRequest && (
                    <div id="payment-request-button"></div>
                )}
            </Paper>

            <Snackbar
                open={!!error}
                autoHideDuration={6000}
                onClose={() => setError(null)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={() => setError(null)} severity="error">
                    {error}
                </Alert>
            </Snackbar>
        </Box>
    )
}

export default Payment 