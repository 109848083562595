import CloseIcon from '@mui/icons-material/Close'
import {
    Box,
    Dialog,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    ListSubheader,
    Radio,
    Stack,
    Typography
} from '@mui/material'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'

export interface TimeZone {
    name: string
    shortName: string
    offset: number
    ianaId?: string
}

interface TimeZoneModalProps {
    open: boolean
    onClose: () => void
    selectedTimeZone: TimeZone
    onTimeZoneSelect: (timeZone: TimeZone) => void
}

export const timeZones: { [key: string]: TimeZone[] } = {
    'US/CANADA': [
        { name: 'Pacific Time', shortName: 'PST', offset: -8, ianaId: 'America/Los_Angeles' },
        { name: 'Mountain Time', shortName: 'MST', offset: -7, ianaId: 'America/Denver' },
        { name: 'Central Time', shortName: 'CST', offset: -6, ianaId: 'America/Chicago' },
        { name: 'Eastern Time', shortName: 'EST', offset: -5, ianaId: 'America/New_York' },
        { name: 'Alaska Time', shortName: 'AKST', offset: -9, ianaId: 'America/Anchorage' },
        { name: 'Arizona Time', shortName: 'MST', offset: -7, ianaId: 'America/Phoenix' },
        { name: 'Newfoundland Time', shortName: 'NST', offset: -3.5, ianaId: 'America/St_Johns' },
        { name: 'Hawaii Time', shortName: 'HST', offset: -10, ianaId: 'Pacific/Honolulu' }
    ],
    'AMERICA': [
        { name: 'America/Adak', shortName: 'HST', offset: -10, ianaId: 'America/Adak' },
        { name: 'America/Anchorage', shortName: 'AKST', offset: -9, ianaId: 'America/Anchorage' },
        { name: 'America/Anguilla', shortName: 'AST', offset: -4, ianaId: 'America/Anguilla' },
        { name: 'America/Antigua', shortName: 'AST', offset: -4, ianaId: 'America/Antigua' }
    ]
}

// Function to detect user's time zone
export const detectUserTimeZone = (): TimeZone => {
    try {
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

        // Search through our time zones to find a match
        for (const [_, zones] of Object.entries(timeZones)) {
            const matchedZone = zones.find(zone => zone.ianaId === userTimeZone)
            if (matchedZone) {
                return matchedZone
            }
        }

        // If no exact match, try to match by offset
        const now = new Date()
        const userOffset = -now.getTimezoneOffset() / 60 // Convert to hours and invert sign

        // Find the first zone with matching offset
        for (const [_, zones] of Object.entries(timeZones)) {
            const matchedZone = zones.find(zone => zone.offset === userOffset)
            if (matchedZone) {
                return matchedZone
            }
        }

        // Default to Eastern Time if no match found
        return timeZones['US/CANADA'][3] // Eastern Time
    } catch (error) {
        // Default to Eastern Time if detection fails
        return timeZones['US/CANADA'][3] // Eastern Time
    }
}

const TimeZoneModal = ({ open, onClose, selectedTimeZone, onTimeZoneSelect }: TimeZoneModalProps) => {
    const [currentTime, setCurrentTime] = useState(new Date())

    useEffect(() => {
        // Update time every minute
        const interval = setInterval(() => {
            setCurrentTime(new Date())
        }, 60000)

        return () => clearInterval(interval)
    }, [])

    const getTimeInTimeZone = (offset: number) => {
        const localTime = currentTime
        const localOffset = localTime.getTimezoneOffset()
        const targetTime = new Date(localTime.getTime() + (offset * 60 + localOffset) * 60000)
        return format(targetTime, 'h:mm a')
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullScreen
            PaperProps={{
                sx: {
                    bgcolor: 'background.default'
                }
            }}
        >
            <Stack
                width="100%"
                height="100vh"
                sx={{
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                {/* Header */}
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                    p={2}
                    borderBottom={1}
                    borderColor="divider"
                    sx={{ flexShrink: 0 }}
                >
                    <Stack direction="row" spacing={2} alignItems="center">
                        <IconButton
                            edge="start"
                            onClick={onClose}
                            sx={{ color: 'primary.main' }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" fontWeight={500}>
                            Time zone
                        </Typography>
                    </Stack>
                    <Typography
                        variant="body1"
                        sx={{
                            color: 'primary.main',
                            cursor: 'pointer',
                            '&:hover': { textDecoration: 'underline' }
                        }}
                        onClick={onClose}
                    >
                        Done
                    </Typography>
                </Stack>

                {/* Content */}
                <Box sx={{ flex: 1, overflow: 'auto' }}>
                    {Object.entries(timeZones).map(([region, zones]) => (
                        <List
                            key={region}
                            sx={{
                                width: '100%',
                                bgcolor: 'background.paper',
                                '& .MuiListSubheader-root': {
                                    bgcolor: 'background.default',
                                    py: 1,
                                    px: 2,
                                    lineHeight: '1.5',
                                    fontSize: '0.875rem',
                                    fontWeight: 500,
                                    color: 'text.secondary'
                                }
                            }}
                            subheader={
                                <ListSubheader>{region}</ListSubheader>
                            }
                        >
                            {zones.map((zone) => (
                                <ListItem
                                    key={zone.name}
                                    disablePadding
                                    secondaryAction={
                                        <Typography variant="body2" color="text.secondary">
                                            {getTimeInTimeZone(zone.offset)}
                                        </Typography>
                                    }
                                >
                                    <ListItemButton
                                        onClick={() => onTimeZoneSelect(zone)}
                                        sx={{ pr: 10 }}
                                    >
                                        <Radio
                                            checked={selectedTimeZone.name === zone.name}
                                            sx={{ mr: 1 }}
                                        />
                                        <ListItemText
                                            primary={zone.name}
                                        />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    ))}
                </Box>
            </Stack>
        </Dialog>
    )
}

export default TimeZoneModal 