import { keyframes } from '@emotion/react'
import CloseIcon from '@mui/icons-material/Close'
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary'
import {
    Avatar,
    AvatarGroup,
    Box,
    Button,
    CircularProgress,
    IconButton,
    Modal,
    Paper,
    Stack,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material'
import { format } from 'date-fns'
import { useCallback, useEffect, useRef, useState } from 'react'
import type { UserSearchResult } from '../../api/user'
import MediaModal from '../../components/MediaModal/MediaModal'
import MediaPreview from '../../components/MediaPreview/MediaPreview'
import MessageBubble from '../../components/MessageBubble/MessageBubble'
import MessageInput from '../../components/MessageInput/MessageInput'
import UserAvatar from '../../components/UserAvatar/UserAvatar'
import { useAuth } from '../../hooks/useAuth'
import { useChat } from '../../hooks/useChat'
import { useConversation } from '../../hooks/useConversation'
import { MediaItem } from '../../hooks/useMedia'
import type { ReadStatusUpdate, TypingStatusUpdate } from '../../hooks/useWebSocket'
import { MessageDto, ParticipantDto } from '../../types/conversation'
import { getCurrentUserId } from '../../util/api'
import { formatFileSize } from '../../util/fileUpload'

const bounceKeyframes = keyframes`
  0%, 80%, 100% { transform: translateY(0); }
  40% { transform: translateY(-4px); }
`

interface TypingUser {
    name: string;
}

const formatMessageDate = (date: Date) => {
    const today = new Date()
    const yesterday = new Date(today)
    yesterday.setDate(yesterday.getDate() - 1)

    if (date.toDateString() === today.toDateString()) {
        return 'Today'
    } else if (date.toDateString() === yesterday.toDateString()) {
        return 'Yesterday'
    } else {
        return format(date, 'MMMM d, yyyy')
    }
}

const DateDivider = ({ date }: { date: Date }) => (
    <Box
        sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            margin: '24px 0 8px 0 !important',
        }}
    >
        <Typography
            align="center"
            variant="caption"
            sx={{
                color: '#8e8e93',
                fontSize: '12px',
                fontWeight: 500,
                letterSpacing: '0.5px',
                bgcolor: 'rgba(142, 142, 147, 0.1)',
                px: 2,
                py: 0.5,
                borderRadius: 1
            }}
        >
            {formatMessageDate(date)}
        </Typography>
    </Box>
)

interface MessageProps {
    conversationId: string
    open: boolean
    onClose: () => void
    user?: UserSearchResult
    title?: string
    participants?: {
        id: string
        name: string
        avatar?: string | null
        role: string
    }[]
    onSend?: (content: string, fileInfo?: {
        fileKey: string
        fileName: string
        fileType: string
        fileSize: number
    }) => void
}

const Message = ({ conversationId, open, onClose, user, title, participants, onSend }: MessageProps) => {
    console.debug('Message: Component rendered with props:', { conversationId, open, user })
    const currentUserId = getCurrentUserId()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    // Only fetch conversation if it's not a temporary one
    const isTemporary = conversationId.startsWith('temp-')
    const { conversation, isLoading, error } = useConversation(conversationId, {
        enabled: !isTemporary
    })
    const { isAuthenticated } = useAuth()
    const messagesEndRef = useRef<HTMLDivElement>(null)
    const messagesContainerRef = useRef<HTMLDivElement>(null)
    const [shouldScrollToBottom, setShouldScrollToBottom] = useState(true)
    const [isMessagesVisible, setIsMessagesVisible] = useState(false)
    const [messages, setMessages] = useState<MessageDto[]>([])
    const [typingUsers, setTypingUsers] = useState<Record<string, TypingUser>>({})
    const typingTimeoutRef = useRef<Record<string, NodeJS.Timeout>>({})
    const [isMediaModalOpen, setIsMediaModalOpen] = useState(false)
    const [selectedMedia, setSelectedMedia] = useState<MediaItem | null>(null)
    const [hasMoreMessages, setHasMoreMessages] = useState(false)
    const [page, setPage] = useState(1)
    const [isLoadingMore, setIsLoadingMore] = useState(false)

    const handleNewMessage = useCallback((message: MessageDto) => {
        setMessages(prev => {
            if (prev.some(m => m.id === message.id)) {
                return prev
            }
            const newMessages = [...prev, message].sort((a, b) => {
                const dateA = new Date(a.timestamp).getTime()
                const dateB = new Date(b.timestamp).getTime()
                return dateA - dateB // Ascending order (oldest to newest)
            })
            return newMessages
        })
        setShouldScrollToBottom(true)
    }, [])

    const handleReadReceipt = useCallback((update: ReadStatusUpdate) => {
        setMessages(prev => {
            // Find the last message from the current user
            const lastMessageFromCurrentUser = [...prev]
                .reverse()
                .find(m => m.senderId === currentUserId);

            return prev.map(message => {
                // Clear read receipts from all messages except the last one
                if (message.id !== lastMessageFromCurrentUser?.id) {
                    return {
                        ...message,
                        readBy: null
                    };
                }

                // Update read status for the last message
                if (message.id === update.messageId && message.senderId === currentUserId) {
                    const readByUser: ParticipantDto = {
                        id: update.readByUser.id,
                        name: update.readByUser.name,
                        avatar: update.readByUser.avatar ?? null
                    };
                    
                    const updatedReadBy = message.readBy 
                        ? [...message.readBy, readByUser]
                        : [readByUser];
                    
                    return {
                        ...message,
                        isRead: true,
                        readBy: updatedReadBy
                    };
                }

                return message;
            });
        });
    }, [currentUserId]);

    const handleError = useCallback((error: any) => {
        console.error('WebSocket error:', error)
    }, [])

    const handleTypingStatus = useCallback((update: TypingStatusUpdate) => {
        console.log('Typing status update:', update);
        if (update.isTyping) {
            setTypingUsers((prevUsers: Record<string, TypingUser>) => {
                const newUsers = { ...prevUsers };
                newUsers[update.userId] = { name: update.userName };
                console.log('Updated typing users:', newUsers);
                return newUsers;
            });
            setShouldScrollToBottom(true);
        } else {
            setTypingUsers((prevUsers) => {
                const { [update.userId]: removed, ...rest } = prevUsers;
                return rest;
            });
        }
    }, []);

    // Remove handleTypingStart since we're handling scroll in handleTypingStatus
    const { isConnected, sendMessage, markMessagesAsRead, sendTypingStatus } = useChat({
        onMessage: !isTemporary ? handleNewMessage : undefined,
        onError: !isTemporary ? handleError : undefined,
        onReadReceipt: !isTemporary ? handleReadReceipt : undefined,
        onTypingStatus: !isTemporary ? handleTypingStatus : undefined
    })

    useEffect(() => {
        if (conversation?.messages) {
            console.debug('[Initial Load] Conversation update:', {
                messageCount: conversation.messages.length,
                hasMoreMessages: conversation.hasMoreMessages,
                messages: conversation.messages
            });
            // Sort messages by timestamp in ascending order (oldest first)
            const sortedMessages = [...conversation.messages].sort((a, b) => {
                const dateA = new Date(a.timestamp).getTime();
                const dateB = new Date(b.timestamp).getTime();
                return dateA - dateB; // Ascending order (oldest first)
            });
            setMessages(sortedMessages);
            setHasMoreMessages(!!conversation.hasMoreMessages);
            setPage(1);
        }
    }, [conversation]);

    useEffect(() => {
        if (!isLoading && conversation && !isMessagesVisible) {
            const timer = setTimeout(() => {
                setIsMessagesVisible(true)
                if (messagesEndRef.current) {
                    // Use smooth scrolling only for initial load
                    messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
                }
            }, 100)
            return () => clearTimeout(timer)
        }
    }, [isLoading, conversation, isMessagesVisible])

    useEffect(() => {
        if (shouldScrollToBottom && messagesEndRef.current) {
            const scrollToBottom = () => {
                const container = messagesContainerRef.current
                if (container) {
                    container.scrollTop = container.scrollHeight
                }
                messagesEndRef.current?.scrollIntoView({ behavior: 'auto' })
            }

            // Immediate scroll
            scrollToBottom()

            // Scroll after a frame
            requestAnimationFrame(() => {
                scrollToBottom()
                // Double-check scroll after a short delay
                setTimeout(() => {
                    scrollToBottom()
                }, 50)
            })
        }
    }, [shouldScrollToBottom, messages, typingUsers])

    useEffect(() => {
        const container = messagesContainerRef.current
        if (!container) return

        const handleScroll = () => {
            const { scrollTop, scrollHeight, clientHeight } = container
            const isNearBottom = scrollHeight - (scrollTop + clientHeight) < 150
            // If we're near the bottom, enable auto-scrolling
            if (isNearBottom) {
                setShouldScrollToBottom(true)
            } else {
                setShouldScrollToBottom(false)  // Only set to false when scrolling up
            }
        }

        container.addEventListener('scroll', handleScroll)
        return () => container.removeEventListener('scroll', handleScroll)
    }, [])

    useEffect(() => {
        if (isConnected && conversation?.id && messages.some(m => !m.isRead && m.senderId !== currentUserId)) {
            markMessagesAsRead(conversation.id)
        }
    }, [isConnected, conversation?.id, messages, currentUserId, markMessagesAsRead])

    // Mark messages as read when viewing conversation
    useEffect(() => {
        if (isConnected && conversation?.id && open) {
            markMessagesAsRead(conversation.id)
        }
    }, [isConnected, conversation?.id, open, messages, markMessagesAsRead])

    useEffect(() => {
        return () => {
            Object.values(typingTimeoutRef.current).forEach(clearTimeout)
        }
    }, [])

    const clearTypingStatus = useCallback(() => {
        if (isConnected && conversation?.id) {
            sendTypingStatus(conversation.id, false)
        }
    }, [isConnected, conversation?.id, sendTypingStatus])

    const shouldShowDateDivider = (message: MessageDto, prevMessage?: MessageDto) => {
        if (!prevMessage) return true

        const messageDate = new Date(message.timestamp)
        const prevMessageDate = new Date(prevMessage.timestamp)

        return messageDate.toDateString() !== prevMessageDate.toDateString()
    }

    const handleMediaClick = (message: MessageDto) => {
        if (message.fileUrl && message.fileName && message.fileType) {
            setSelectedMedia({
                id: message.id,
                url: message.fileUrl,
                name: message.fileName,
                type: message.fileType,
                date: message.timestamp,
                size: formatFileSize(message.fileSize || 0)
            })
        }
    }

    if (!conversationId || !isAuthenticated) {
        onClose()
        return null
    }

    const handleSend = async (content: string, fileInfo?: {
        fileKey: string
        fileName: string
        fileType: string
        fileSize: number
    }) => {
        if (isTemporary) {
            // For temporary conversations, we don't need to use WebSocket
            // The conversation will be created when the first message is sent
            onSend?.(content, fileInfo)
            return
        }

        if (isConnected) {
            clearTypingStatus()
            sendMessage(conversationId, {
                content,
                fileKey: fileInfo?.fileKey,
                fileName: fileInfo?.fileName,
                fileType: fileInfo?.fileType,
                fileSize: fileInfo?.fileSize
            })
            // Ensure we scroll to bottom when sending any message
            setShouldScrollToBottom(true)
        }
    }

    const renderHeader = () => (
        <Stack
            direction="column"
            sx={{
                borderBottom: 1,
                borderColor: 'divider',
                bgcolor: 'background.paper',
                flexShrink: 0,
                backdropFilter: 'blur(10px)',
                WebkitBackdropFilter: 'blur(10px)',
                zIndex: 10,
                py: 2
            }}
        >
            <Stack
                direction="row"
                alignItems="center"
                spacing={1.5}
                sx={{
                    px: 2,
                }}
            >
                <IconButton onClick={onClose} edge="start" size="small">
                    <CloseIcon />
                </IconButton>
                {title ? (
                    <Stack flex={1} spacing={0.75}>
                        <Typography
                            variant="subtitle1"
                            sx={{
                                fontWeight: 600,
                                fontSize: '1.25rem',
                                lineHeight: 1.2,
                                display: '-webkit-box',
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: 'vertical',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}
                        >
                            {title}
                        </Typography>
                        {participants && (
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <AvatarGroup
                                    max={4}
                                    sx={{
                                        '& .MuiAvatar-root': {
                                            width: 20,
                                            height: 20,
                                            fontSize: 11,
                                            border: '1.5px solid',
                                            borderColor: 'background.paper'
                                        }
                                    }}
                                >
                                    {participants.map((participant) => (
                                        <Avatar
                                            key={participant.id}
                                            alt={participant.name}
                                            src={participant.avatar || undefined}
                                            sx={{
                                                bgcolor: 'primary.main'
                                            }}
                                        >
                                            {participant.name[0]}
                                        </Avatar>
                                    ))}
                                </AvatarGroup>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    sx={{ fontSize: '0.8125rem' }}
                                >
                                    {participants.length} participant{participants.length !== 1 ? 's' : ''}
                                </Typography>
                            </Stack>
                        )}
                    </Stack>
                ) : user ? (
                    <Stack direction="row" alignItems="center" spacing={1.5} flex={1}>
                        <UserAvatar
                            src={user.avatar}
                            alt={user.name}
                            userId={user.id}
                            size={32}
                            noBorder
                        />
                        <Typography variant="subtitle1" sx={{ fontSize: '1rem' }}>
                            {user.name}
                        </Typography>
                    </Stack>
                ) : isLoading ? (
                    <Typography variant="subtitle1" sx={{ flex: 1, fontSize: '1rem' }}>
                        Loading...
                    </Typography>
                ) : error ? (
                    <Typography variant="subtitle1" color="error" sx={{ flex: 1, fontSize: '1rem' }}>
                        Error loading conversation
                    </Typography>
                ) : (
                    <Typography variant="subtitle1" sx={{ flex: 1, fontSize: '1rem' }}>
                        Unknown User
                    </Typography>
                )}
                {!isTemporary && (
                    <IconButton onClick={() => setIsMediaModalOpen(true)} size="small">
                        <PhotoLibraryIcon />
                    </IconButton>
                )}
            </Stack>
        </Stack>
    )

    return (
        <Modal
            open={open}
            onClose={onClose}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <Paper
                sx={{
                    width: '100%',
                    height: '100%',
                    maxWidth: isMobile ? '100%' : 600,
                    maxHeight: isMobile ? '100%' : '90vh',
                    margin: isMobile ? 0 : 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    overflow: 'hidden',
                    borderRadius: isMobile ? 0 : 2
                }}
            >
                {renderHeader()}

                {isTemporary ? (
                    // For new conversations, show empty state
                    <Stack flex={1} minHeight={0}>
                        <Stack
                            flex={1}
                            alignItems="center"
                            justifyContent="center"
                            spacing={2}
                            px={3}
                        >
                            <Typography variant="body1" color="text.secondary" textAlign="center">
                                Start a conversation with {user?.name}
                            </Typography>
                        </Stack>
                        <MessageInput
                            conversationId={conversationId}
                            onSend={handleSend}
                        />
                    </Stack>
                ) : (
                    // For existing conversations, show messages
                    <Stack flex={1} minHeight={0}>
                        {isLoading ? (
                            <Stack
                                flex={1}
                                alignItems="center"
                                justifyContent="center"
                                spacing={2}
                            >
                                <CircularProgress />
                            </Stack>
                        ) : error ? (
                            <Stack
                                flex={1}
                                alignItems="center"
                                justifyContent="center"
                                spacing={2}
                                px={3}
                            >
                                <Typography variant="body1" color="error" textAlign="center">
                                    Failed to load messages
                                </Typography>
                            </Stack>
                        ) : (
                            <>
                                {/* Messages Area Container */}
                                <Stack
                                    ref={messagesContainerRef}
                                    flex={1}
                                    spacing={2}
                                    p={2}
                                    sx={{
                                        overflowY: 'auto',
                                        scrollBehavior: 'smooth'
                                    }}
                                >
                                    {/* Show Previous Messages Button */}
                                    {hasMoreMessages && messages.length > 0 && (
                                        <Button
                                            variant="text"
                                            size="small"
                                            disabled={isLoadingMore}
                                            onClick={async () => {
                                                console.debug('[Load More] Starting to load more messages:', {
                                                    currentPage: page,
                                                    nextPage: page + 1,
                                                    currentMessageCount: messages.length,
                                                    currentMessages: messages
                                                });

                                                setShouldScrollToBottom(false);
                                                setIsLoadingMore(true);
                                                try {
                                                    const nextPage = page + 1;
                                                    const response = await fetch(`${import.meta.env.VITE_API_URL}/messages/${conversationId}?page=${nextPage}&size=10`, {
                                                        headers: {
                                                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                                                        }
                                                    });

                                                    if (!response.ok) throw new Error('Failed to load messages');
                                                    const data = await response.json();

                                                    console.debug('[Load More] Received response:', {
                                                        newMessageCount: data.messages.length,
                                                        hasMore: data.hasMoreMessages,
                                                        newMessages: data.messages
                                                    });

                                                    // Sort new messages by timestamp in ascending order
                                                    const sortedNewMessages = [...data.messages].sort((a: MessageDto, b: MessageDto): number => {
                                                        const dateA = new Date(a.timestamp).getTime();
                                                        const dateB = new Date(b.timestamp).getTime();
                                                        return dateA - dateB; // Ascending order (oldest first)
                                                    });

                                                    // Add older messages at the beginning
                                                    setMessages(prev => [...sortedNewMessages, ...prev]);
                                                    setHasMoreMessages(data.hasMoreMessages);
                                                    setPage(nextPage);
                                                } catch (error) {
                                                    console.error('[Load More] Error loading messages:', error);
                                                } finally {
                                                    setIsLoadingMore(false);
                                                }
                                            }}
                                            sx={{ mb: 2 }}
                                        >
                                            {isLoadingMore ? 'Loading...' : 'Show Previous Messages'}
                                        </Button>
                                    )}
                                    {messages.map((message, index) => {
                                        const prevMessage = messages[index - 1]
                                        const showDateDivider = shouldShowDateDivider(message, prevMessage)

                                        return (
                                            <Stack key={message.id} spacing={1}>
                                                {showDateDivider && (
                                                    <DateDivider
                                                        date={new Date(message.timestamp)}
                                                    />
                                                )}
                                                <MessageBubble
                                                    message={message}
                                                    isCurrentUser={message.senderId === currentUserId}
                                                    onMediaClick={handleMediaClick}
                                                    onImageLoad={() => {
                                                        // Only auto-scroll for new images in recent messages
                                                        const isRecentMessage = index >= messages.length - 10;
                                                        if (isRecentMessage) {
                                                            setShouldScrollToBottom(true);
                                                            // Also try to scroll immediately
                                                            const container = messagesContainerRef.current;
                                                            if (container) {
                                                                requestAnimationFrame(() => {
                                                                    container.scrollTop = container.scrollHeight;
                                                                    messagesEndRef.current?.scrollIntoView({ behavior: 'auto' });
                                                                });
                                                            }
                                                        }
                                                    }}
                                                />
                                            </Stack>
                                        )
                                    })}
                                    {/* Typing Indicator */}
                                    {Object.keys(typingUsers).length > 0 && (
                                        <Stack
                                            direction="row"
                                            spacing={1}
                                            sx={{
                                                width: '100%',
                                                mb: 2,
                                                justifyContent: 'flex-start',
                                                px: 0.8
                                            }}
                                        >
                                            <Box sx={{ pt: 0.5, flexShrink: 0 }}>
                                                {Object.entries(typingUsers)[0] && (
                                                    <UserAvatar
                                                        key={Object.entries(typingUsers)[0][0]}
                                                        userId={Object.entries(typingUsers)[0][0]}
                                                        alt={Object.entries(typingUsers)[0][1].name}
                                                        size={32}
                                                        noBorder
                                                    />
                                                )}
                                            </Box>
                                            <Box
                                                sx={{
                                                    position: 'relative',
                                                    display: 'inline-flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    gap: 0.6,
                                                    bgcolor: '#e6e5eb',
                                                    borderRadius: '18px',
                                                    minWidth: '65px',
                                                    maxWidth: '70%',
                                                    height: '35px',
                                                    boxShadow: '0 1px 1px rgba(0, 0, 0, 0.05)',
                                                    zIndex: 1,
                                                    top: '3px',
                                                    px: 2,
                                                    py: 1.25,
                                                    wordBreak: 'break-word',
                                                    whiteSpace: 'pre-wrap',
                                                    overflowWrap: 'break-word',
                                                    '&::before, &::after': {
                                                        content: '""',
                                                        position: 'absolute',
                                                        background: '#e6e5eb',
                                                        borderRadius: '50%',
                                                        width: '14px',
                                                        height: '14px'
                                                    }
                                                }}
                                            >
                                                {[0, 1, 2].map((i) => (
                                                    <Box
                                                        key={i}
                                                        sx={{
                                                            width: 8,
                                                            height: 8,
                                                            bgcolor: '#8e8e93',
                                                            borderRadius: '50%',
                                                            animation: `${bounceKeyframes} 1s infinite`,
                                                            animationDelay: `${i * 0.15}s`,
                                                            opacity: 0.4,
                                                            zIndex: 1
                                                        }}
                                                    />
                                                ))}
                                            </Box>
                                        </Stack>
                                    )}
                                    <div ref={messagesEndRef} />
                                </Stack>

                                {/* Input Area */}
                                <Stack sx={{ flexShrink: 0, bgcolor: 'background.paper' }}>
                                    <MessageInput
                                        conversationId={conversationId}
                                        onSend={handleSend}
                                    />
                                </Stack>
                            </>
                        )}
                    </Stack>
                )}

                {/* Media Modal */}
                {!isTemporary && (
                    <MediaModal
                        open={isMediaModalOpen}
                        onClose={() => setIsMediaModalOpen(false)}
                        conversationId={conversationId}
                    />
                )}

                {/* Media Preview Modal */}
                <MediaPreview
                    open={!!selectedMedia}
                    onClose={() => setSelectedMedia(null)}
                    item={selectedMedia}
                />
            </Paper>
        </Modal>
    )
}

export default Message 