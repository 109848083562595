import MenuIcon from '@mui/icons-material/Menu'
import {
    Box,
    IconButton,
    Stack
} from '@mui/material'
import Image from '../../components/Image/Image'
import { useState } from 'react'
import Drawer from '../Drawer/Drawer'

const Header = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)

    const handleDrawerToggle = () => {
        setIsDrawerOpen(!isDrawerOpen)
    }

    return (
        <Stack
            pr={3}
            pl={4.5}
            py={1.5}
            top={0}
            zIndex={1100}
            borderBottom={1}
            position="sticky"
            borderColor="divider"
            justifyContent="space-between"
            alignItems="center"
            bgcolor="background.default"
            direction="row"
        >
            <Box flex={1} maxWidth={35}>
                <Image
                    src="/assets/img/artistlink-badge.svg"
                    alt="ArtistLink Logo"
                    height={35}
                />
            </Box>
            <Box>
                <IconButton
                    color="inherit"
                    aria-label="open menu"
                    onClick={handleDrawerToggle}
                    sx={{
                        padding: 1.5,
                        '& .MuiSvgIcon-root': {
                            fontSize: 32
                        }
                    }}
                >
                    <MenuIcon />
                </IconButton>
            </Box>
            <Drawer
                open={isDrawerOpen}
                onClose={() => setIsDrawerOpen(false)}
            />
        </Stack>
    )
}

export default Header
