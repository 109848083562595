import AudioFileIcon from '@mui/icons-material/AudioFile'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ImageIcon from '@mui/icons-material/Image'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import VideoFileIcon from '@mui/icons-material/VideoFile'
import { Avatar, AvatarGroup, ListItem, ListItemAvatar, ListItemButton, ListItemText, Skeleton, Stack, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { format, isToday } from 'date-fns'
import { getSessions } from '../../api/session'
import { ConversationDto } from '../../types/conversation'
import { getCurrentUserId } from '../../util/api'
import { isAudioFile, isImageFile, isVideoFile } from '../../util/fileUpload'
import UserAvatar from '../UserAvatar/UserAvatar'

interface ConversationListItemProps {
    conversation: ConversationDto
    onClick: () => void
    children?: React.ReactNode
}

const ConversationListItem = ({ conversation, onClick, children }: ConversationListItemProps) => {
    const currentUserId = getCurrentUserId()
    const otherParticipant = conversation.participants.find(p => p.id !== currentUserId)
    const lastMessage = conversation.lastMessage

    // Fetch sessions to check if this is a session chat
    const { data: sessions = [], isLoading: isLoadingSessions } = useQuery({
        queryKey: ['sessions'],
        queryFn: getSessions,
        staleTime: 30000 // Cache for 30 seconds to prevent too many refetches
    })

    // Find if this conversation is a session chat
    const session = sessions.find(s => s.chatId === conversation.id)

    if (!otherParticipant && !session) return null

    const formatDate = (timestamp: string) => {
        const date = new Date(timestamp)
        if (isToday(date)) {
            return format(date, 'h:mm a')
        }
        return format(date, 'M/d/yy')
    }

    const getFileIcon = (fileType: string) => {
        if (isImageFile(fileType)) return <ImageIcon fontSize="small" />
        if (isAudioFile(fileType)) return <AudioFileIcon fontSize="small" />
        if (isVideoFile(fileType)) return <VideoFileIcon fontSize="small" />
        return <InsertDriveFileIcon fontSize="small" />
    }

    const getMessagePreview = () => {
        if (!lastMessage) return ''

        if (lastMessage.fileType && lastMessage.fileName) {
            return (
                <Stack
                    component="span"
                    direction="row"
                    spacing={0.5}
                    alignItems="center"
                >
                    {getFileIcon(lastMessage.fileType)}
                    <Typography
                        component="span"
                        variant="body2"
                        color="text.secondary"
                        noWrap
                        sx={{
                            fontWeight: conversation.unreadCount > 0 ? 500 : 400,
                        }}
                    >
                        {lastMessage.fileName}
                    </Typography>
                </Stack>
            )
        }

        return (
            <Typography
                component="span"
                variant="body2"
                color="text.secondary"
                noWrap
                sx={{
                    fontWeight: conversation.unreadCount > 0 ? 500 : 400,
                }}
            >
                {lastMessage.content || ''}
            </Typography>
        )
    }

    // Show loading state while sessions are being fetched
    if (isLoadingSessions) {
        return (
            <ListItemButton disabled>
                <ListItem disablePadding>
                    <ListItemAvatar>
                        <Skeleton variant="circular" width={40} height={40} />
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <Stack direction="row" alignItems="center" justifyContent="space-between">
                                <Skeleton width={120} />
                                <Skeleton width={60} />
                            </Stack>
                        }
                        secondary={<Skeleton width={200} />}
                    />
                </ListItem>
            </ListItemButton>
        )
    }

    return (
        <ListItemButton onClick={onClick}>
            <ListItem disablePadding>
                <ListItemAvatar sx={{ minWidth: session ? 52 : 48, mr: 2 }}>
                    {session ? (
                        <AvatarGroup
                            max={2}
                            sx={{
                                '& .MuiAvatar-root': {
                                    width: 40,
                                    height: 40,
                                    fontSize: 16,
                                    border: '2px solid',
                                    borderColor: 'background.paper',
                                },
                                '.MuiAvatarGroup-avatar': {
                                    '&:first-of-type': {
                                        marginLeft: -2
                                    }
                                }
                            }}
                        >
                            {session.participants.map((participant) => (
                                <Avatar
                                    key={participant.id}
                                    alt={participant.name}
                                    src={participant.avatar || undefined}
                                    sx={{
                                        bgcolor: 'primary.main'
                                    }}
                                >
                                    {participant.name[0]}
                                </Avatar>
                            ))}
                        </AvatarGroup>
                    ) : (
                        <UserAvatar
                            src={otherParticipant?.avatar}
                            alt={otherParticipant?.name}
                            userId={otherParticipant?.id}
                            size={40}
                            noBorder
                        />
                    )}
                </ListItemAvatar>
                <ListItemText
                    primary={
                        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                            <Typography
                                component="span"
                                sx={{
                                    fontWeight: session ? 600 : 400,
                                    color: session ? 'primary.main' : 'text.primary',
                                    fontSize: '0.9375rem'
                                }}
                                noWrap
                            >
                                {children || (session ? session.title : otherParticipant?.name)}
                            </Typography>
                            <Stack direction="row" alignItems="center" spacing={1} flexShrink={0}>
                                {lastMessage && (
                                    <Typography
                                        component="span"
                                        variant="caption"
                                        color="text.secondary"
                                        sx={{ fontSize: '0.75rem' }}
                                    >
                                        {formatDate(lastMessage.timestamp)}
                                    </Typography>
                                )}
                                <ChevronRightIcon
                                    sx={{
                                        fontSize: 18,
                                        color: 'text.disabled',
                                        opacity: 0.5
                                    }}
                                />
                            </Stack>
                        </Stack>
                    }
                    secondary={
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{
                                fontSize: '0.8125rem',
                                mt: 0.25
                            }}
                            noWrap
                        >
                            {getMessagePreview()}
                        </Typography>
                    }
                />
            </ListItem>
        </ListItemButton>
    )
}

export default ConversationListItem 