import { useState, useCallback } from 'react'
import { api } from '../util/api'
import { MessageDto } from '../types/conversation'
import { formatFileSize } from '../util/fileUpload'

export interface MediaItem {
    id: string
    type: string
    name: string
    date: string
    size: string
    url: string | null
}

export type SortField = 'type' | 'name' | 'date' | 'size'
export type SortDirection = 'asc' | 'desc'

export const useMedia = (conversationId?: string) => {
    const [mediaItems, setMediaItems] = useState<MediaItem[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState<Error | null>(null)
    const [sortField, setSortField] = useState<SortField>('date')
    const [sortDirection, setSortDirection] = useState<SortDirection>('desc')
    const [searchQuery, setSearchQuery] = useState('')

    const fetchMediaItems = useCallback(async () => {
        setIsLoading(true)
        setError(null)

        try {
            let messages: MessageDto[]
            if (conversationId) {
                // Fetch media for specific conversation
                messages = await api.get<MessageDto[]>(`/messages/${conversationId}/media`)
            } else {
                // Fetch all media
                messages = await api.get<MessageDto[]>('/messages/media')
            }

            const items = messages
                .filter(message => message.fileKey && message.fileName && message.fileType)
                .map(message => ({
                    id: message.id,
                    type: message.fileType!.toUpperCase(),
                    name: message.fileName!,
                    date: message.timestamp,
                    size: formatFileSize(message.fileSize || 0),
                    url: message.fileUrl || null
                }))

            setMediaItems(items)
        } catch (err) {
            console.error('Error fetching media:', err)
            setError(err instanceof Error ? err : new Error('Failed to fetch media'))
        } finally {
            setIsLoading(false)
        }
    }, [conversationId])

    const handleSort = (field: SortField) => {
        if (sortField === field) {
            setSortDirection(prev => prev === 'asc' ? 'desc' : 'asc')
        } else {
            setSortField(field)
            setSortDirection('asc')
        }
    }

    // Apply sorting and filtering
    const sortedAndFilteredItems = [...mediaItems]
        .filter(item => {
            if (!searchQuery) return true
            const searchLower = searchQuery.toLowerCase()
            return (
                item.name.toLowerCase().includes(searchLower) ||
                item.type.toLowerCase().includes(searchLower)
            )
        })
        .sort((a, b) => {
            const direction = sortDirection === 'asc' ? 1 : -1
            switch (sortField) {
                case 'type':
                    return direction * a.type.localeCompare(b.type)
                case 'name':
                    return direction * a.name.localeCompare(b.name)
                case 'date':
                    return direction * (new Date(a.date).getTime() - new Date(b.date).getTime())
                case 'size':
                    return direction * (parseInt(a.size) - parseInt(b.size))
                default:
                    return 0
            }
        })

    return {
        mediaItems: sortedAndFilteredItems,
        isLoading,
        error,
        sortField,
        sortDirection,
        searchQuery,
        handleSort,
        setSearchQuery,
        fetchMediaItems
    }
}

export default useMedia 