import AudioFileIcon from '@mui/icons-material/AudioFile'
import DownloadIcon from '@mui/icons-material/Download'
import ImageIcon from '@mui/icons-material/Image'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import VideoFileIcon from '@mui/icons-material/VideoFile'
import { Box, CircularProgress, IconButton, Stack, Typography } from '@mui/material'
import { format } from 'date-fns'
import { useState } from 'react'
import { MessageDto } from '../../types/conversation'
import { formatFileSize, getPlaceholderUrl, getThumbnailUrl, isAudioFile, isImageFile, isVideoFile } from '../../util/fileUpload'
import Image from '../Image/Image'
import UserAvatar from '../UserAvatar/UserAvatar'
import { CollaboratorAvatarGroup } from '../common/StyledAvatarGroup'

interface MessageBubbleProps {
    message: MessageDto
    isCurrentUser: boolean
    nextMessage?: MessageDto
    onMediaClick?: (message: MessageDto) => void
    onImageLoad?: () => void
}

const getIcon = (type: string) => {
    if (isImageFile(type)) return <ImageIcon />
    if (isAudioFile(type)) return <AudioFileIcon />
    if (isVideoFile(type)) return <VideoFileIcon />
    if (type.toLowerCase().includes('pdf')) return <PictureAsPdfIcon />
    return <InsertDriveFileIcon />
}

const MessageBubble = ({ message, isCurrentUser, nextMessage, onMediaClick, onImageLoad }: MessageBubbleProps) => {
    const [isImageLoading, setIsImageLoading] = useState(true)
    const [isPlaceholderLoaded, setIsPlaceholderLoaded] = useState(false)
    const hasFile = message.fileKey && message.fileName && message.fileType
    const currentDate = new Date(message.timestamp)

    const handleDownload = () => {
        if (message.fileUrl) {
            window.open(message.fileUrl, '_blank')
        }
    }

    const handleMediaClick = () => {
        if (onMediaClick && (isImageFile(message.fileType!) || isAudioFile(message.fileType!))) {
            onMediaClick(message)
        }
    }

    const handleImageLoad = () => {
        setIsImageLoading(false)
        setIsPlaceholderLoaded(true)
        onImageLoad?.()
    }

    const renderFilePreview = () => {
        if (!hasFile) return null

        if (isImageFile(message.fileType!)) {
            const imageUrl = message.fileUrl!
            const placeholderUrl = getPlaceholderUrl(imageUrl)
            const optimizedUrl = getThumbnailUrl(imageUrl, 760, 760)

            return (
                <Box
                    onClick={handleMediaClick}
                    sx={{
                        position: 'relative',
                        maxWidth: {
                            xs: '260px',
                            sm: '360px'
                        },
                        minWidth: '140px',
                        height: isImageLoading ? '180px' : 'auto',
                        maxHeight: '360px',
                        borderRadius: 1,
                        overflow: 'hidden',
                        bgcolor: 'action.hover',
                        cursor: 'pointer',
                        transition: 'all 0.2s ease-in-out',
                        '&:hover': {
                            transform: 'scale(1.02)'
                        },
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: isCurrentUser ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.05)'
                    }}
                >
                    {isImageLoading && isPlaceholderLoaded && (
                        <Image
                            src={placeholderUrl}
                            alt="Loading..."
                            fit="cover"
                            loading="eager"
                            sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                filter: 'blur(10px)',
                                transform: 'scale(1.1)',
                                zIndex: 1
                            }}
                        />
                    )}

                    {isImageLoading && (
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                zIndex: 2
                            }}
                        >
                            <CircularProgress
                                size={32}
                                sx={{
                                    color: isCurrentUser ? 'rgba(255, 255, 255, 0.8)' : 'primary.main'
                                }}
                            />
                        </Box>
                    )}

                    <Image
                        src={optimizedUrl}
                        alt={message.fileName || 'Image attachment'}
                        fit="contain"
                        loading="lazy"
                        onLoad={handleImageLoad}
                        sx={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                            opacity: isImageLoading ? 0 : 1,
                            transition: 'opacity 0.3s ease-in-out',
                            zIndex: 3
                        }}
                    />
                </Box>
            )
        }

        return (
            <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                onClick={handleMediaClick}
                sx={{
                    p: 1.5,
                    mb: 1,
                    borderRadius: 1,
                    bgcolor: isCurrentUser ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.05)',
                    cursor: isAudioFile(message.fileType!) ? 'pointer' : 'default',
                    '&:hover': isAudioFile(message.fileType!) ? {
                        bgcolor: isCurrentUser ? 'rgba(255, 255, 255, 0.15)' : 'rgba(0, 0, 0, 0.08)'
                    } : {}
                }}
            >
                <Typography variant="h6" sx={{ fontSize: '24px' }}>
                    {getIcon(message.fileType!)}
                </Typography>
                <Stack flex={1} minWidth={0}>
                    <Typography variant="body2" noWrap>
                        {message.fileName}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                        {formatFileSize(message.fileSize!)}
                    </Typography>
                </Stack>
                <IconButton
                    onClick={(e) => {
                        e.stopPropagation()
                        handleDownload()
                    }}
                    size="small"
                    sx={{
                        color: isCurrentUser ? 'white' : 'primary.main',
                        '&:hover': {
                            bgcolor: isCurrentUser ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.05)'
                        }
                    }}
                >
                    <DownloadIcon />
                </IconButton>
            </Stack>
        )
    }

    return (
        <Stack
            direction="row"
            spacing={1}
            sx={{
                width: '100%',
                mb: 2,
                justifyContent: isCurrentUser ? 'flex-end' : 'flex-start',
                px: 0.75
            }}
        >
            {/* Avatar - Only show for other users */}
            {!isCurrentUser && (
                <Box sx={{ pt: 0.5, flexShrink: 0 }}>
                    <UserAvatar
                        src={message.senderAvatar}
                        alt={message.senderName}
                        userId={message.senderId}
                        size={32}
                        noBorder
                    />
                </Box>
            )}

            {/* Message Content */}
            <Stack
                spacing={0.5}
                sx={{
                    flex: '0 1 auto',
                    maxWidth: '75%',
                    alignItems: isCurrentUser ? 'flex-end' : 'flex-start'
                }}
            >
                {/* Header: Name and Time */}
                {!isCurrentUser && (
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography
                            variant="body2"
                            sx={{
                                fontWeight: 500,
                                color: 'text.primary',
                                lineHeight: 1,
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            {message.senderName}
                        </Typography>
                        <Typography
                            variant="caption"
                            sx={{
                                color: 'text.secondary',
                                fontSize: '11px',
                                lineHeight: 1,
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            {format(currentDate, 'h:mm a')}
                        </Typography>
                    </Stack>
                )}

                {/* Message Bubble */}
                <Box
                    sx={{
                        display: 'inline-block',
                        maxWidth: '100%',
                        bgcolor: isCurrentUser ? '#000000' : '#e6e5eb',
                        color: isCurrentUser ? 'white' : '#000000',
                        borderRadius: '18px',
                        px: 2,
                        py: 1.25,
                        '& .MuiTypography-caption': {
                            color: isCurrentUser ? 'rgba(255, 255, 255, 0.7)' : '#6d6d72'
                        }
                    }}
                >
                    {renderFilePreview()}
                    {message.content && (
                        <Typography
                            variant="body1"
                            className="selectable-text"
                            sx={{
                                overflowWrap: 'break-word',
                                wordBreak: 'break-word',
                                whiteSpace: 'pre-wrap',
                                fontSize: '16px',
                                lineHeight: 1.3,
                                mt: hasFile ? 2 : 0
                            }}
                        >
                            {message.content}
                        </Typography>
                    )}
                </Box>

                {/* Read Receipts - Only show for current user's messages and if it's the last message */}
                {isCurrentUser && !nextMessage && message.readBy && message.readBy.length > 0 && (
                    <Box
                        sx={{
                            mt: 0.5,
                            display: 'flex',
                            justifyContent: 'flex-end',
                            height: !nextMessage ? 'auto' : 0,
                            opacity: !nextMessage ? 1 : 0,
                            overflow: 'hidden',
                            transition: 'opacity 0.3s ease-in-out'
                        }}
                    >
                        <CollaboratorAvatarGroup max={5}>
                            {/* Ensure we only show each user once */}
                            {Array.from(new Set(message.readBy.map(user => user.id))).map(userId => {
                                const user = message.readBy!.find(u => u.id === userId)!
                                return (
                                    <UserAvatar
                                        key={user.id}
                                        src={user.avatar}
                                        alt={user.name}
                                        size={16}
                                        noBorder
                                    />
                                )
                            })}
                        </CollaboratorAvatarGroup>
                    </Box>
                )}
            </Stack>
        </Stack>
    )
}

export default MessageBubble 