import {
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography
} from '@mui/material'
import AudioFileIcon from '@mui/icons-material/AudioFile'
import ImageIcon from '@mui/icons-material/Image'
import VideoFileIcon from '@mui/icons-material/VideoFile'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import PhotoIcon from '@mui/icons-material/Photo'
import GifIcon from '@mui/icons-material/Gif'
import AudiotrackIcon from '@mui/icons-material/Audiotrack'
import MusicNoteIcon from '@mui/icons-material/MusicNote'
import MovieIcon from '@mui/icons-material/Movie'
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo'
import { format } from 'date-fns'
import { MediaItem, SortField, SortDirection } from '../../hooks/useMedia'
import { isImageFile, isAudioFile, isVideoFile } from '../../util/fileUpload'

interface MediaTableProps {
    mediaItems: MediaItem[]
    isLoading: boolean
    sortField: SortField
    sortDirection: SortDirection
    handleSort: (field: SortField) => void
    onItemClick: (item: MediaItem) => void
    containerSx?: any
}

const FileTypeIcon = ({ type }: { type: string }) => {
    const fileType = type.toLowerCase()

    // Image types
    if (fileType.includes('jpeg') || fileType.includes('jpg')) {
        return <PhotoIcon sx={{ color: 'success.main' }} />
    }
    if (fileType.includes('png')) {
        return <ImageIcon sx={{ color: 'success.main' }} />
    }
    if (fileType.includes('gif')) {
        return <GifIcon sx={{ color: 'success.main' }} />
    }
    if (isImageFile(type)) {
        return <ImageIcon sx={{ color: 'success.main' }} />
    }

    // Audio types
    if (fileType.includes('mp3') || fileType.includes('wav')) {
        return <MusicNoteIcon sx={{ color: 'primary.main' }} />
    }
    if (fileType.includes('mpeg') || fileType.includes('mpga')) {
        return <AudiotrackIcon sx={{ color: 'primary.main' }} />
    }
    if (isAudioFile(type)) {
        return <AudioFileIcon sx={{ color: 'primary.main' }} />
    }

    // Video types
    if (fileType.includes('mp4') || fileType.includes('mpeg4')) {
        return <MovieIcon sx={{ color: 'error.main' }} />
    }
    if (fileType.includes('webm') || fileType.includes('mov')) {
        return <OndemandVideoIcon sx={{ color: 'error.main' }} />
    }
    if (isVideoFile(type)) {
        return <VideoFileIcon sx={{ color: 'error.main' }} />
    }

    // Document types
    if (fileType.includes('pdf')) {
        return <PictureAsPdfIcon sx={{ color: 'warning.main' }} />
    }

    // Default
    return <InsertDriveFileIcon sx={{ color: 'text.secondary' }} />
}

export const MediaTable = ({
    mediaItems,
    isLoading,
    sortField,
    sortDirection,
    handleSort,
    onItemClick,
    containerSx = {}
}: MediaTableProps) => {
    return (
        <TableContainer
            sx={{
                flex: 1,
                overflowY: 'auto',
                WebkitOverflowScrolling: 'touch',
                '&::-webkit-scrollbar': {
                    width: '8px'
                },
                '&::-webkit-scrollbar-track': {
                    background: 'transparent'
                },
                '&::-webkit-scrollbar-thumb': {
                    background: '#888',
                    borderRadius: '4px',
                    '&:hover': {
                        background: '#666'
                    }
                },
                ...containerSx
            }}
        >
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell width="5%">
                            <TableSortLabel
                                active={sortField === 'type'}
                                direction={sortField === 'type' ? sortDirection : 'asc'}
                                onClick={() => handleSort('type')}
                            >
                                Type
                            </TableSortLabel>
                        </TableCell>
                        <TableCell width="40%">
                            <TableSortLabel
                                active={sortField === 'name'}
                                direction={sortField === 'name' ? sortDirection : 'asc'}
                                onClick={() => handleSort('name')}
                            >
                                Name
                            </TableSortLabel>
                        </TableCell>
                        <TableCell width="30%">
                            <TableSortLabel
                                active={sortField === 'date'}
                                direction={sortField === 'date' ? sortDirection : 'asc'}
                                onClick={() => handleSort('date')}
                            >
                                Time
                            </TableSortLabel>
                        </TableCell>
                        <TableCell width="25%">
                            <TableSortLabel
                                active={sortField === 'size'}
                                direction={sortField === 'size' ? sortDirection : 'asc'}
                                onClick={() => handleSort('size')}
                            >
                                Size
                            </TableSortLabel>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {isLoading ? (
                        <TableRow>
                            <TableCell colSpan={4} align="center">
                                <Typography color="text.secondary" sx={{ py: 4 }}>
                                    Loading...
                                </Typography>
                            </TableCell>
                        </TableRow>
                    ) : mediaItems.length === 0 ? (
                        <TableRow>
                            <TableCell colSpan={4} align="center">
                                <Typography color="text.secondary" sx={{ py: 4 }}>
                                    No files found
                                </Typography>
                            </TableCell>
                        </TableRow>
                    ) : mediaItems.map((item) => (
                        <TableRow
                            key={item.id}
                            hover
                            sx={{
                                cursor: 'pointer',
                                '&:hover': {
                                    bgcolor: 'action.hover'
                                }
                            }}
                            onClick={() => onItemClick(item)}
                        >
                            <TableCell>
                                <Stack direction="row" alignItems="center" spacing={1}>
                                    <FileTypeIcon type={item.type} />
                                </Stack>
                            </TableCell>
                            <TableCell>
                                <Typography 
                                    variant="body2" 
                                    sx={{ 
                                        wordBreak: 'break-word',
                                        display: '-webkit-box',
                                        WebkitLineClamp: 2,
                                        WebkitBoxOrient: 'vertical',
                                        overflow: 'hidden',
                                        lineHeight: 1.2
                                    }}
                                >
                                    {item.name}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Stack>
                                    <Typography variant="body2" color="text.secondary">
                                        {format(new Date(item.date), 'MMM d, yyyy')}
                                    </Typography>
                                    <Typography variant="caption" color="text.secondary">
                                        {format(new Date(item.date), 'h:mm a')}
                                    </Typography>
                                </Stack>
                            </TableCell>
                            <TableCell>
                                <Typography variant="body2" color="text.secondary">
                                    {item.size}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default MediaTable 