import LogoutIcon from '@mui/icons-material/Logout'
import MessageIcon from '@mui/icons-material/Message'
import PermMediaIcon from '@mui/icons-material/PermMedia'
import PersonIcon from '@mui/icons-material/Person'
import AlbumIcon from '@mui/icons-material/Album'
import ShowChartIcon from '@mui/icons-material/ShowChart'
import PublicIcon from '@mui/icons-material/Public'
import {
    Badge,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Drawer as MuiDrawer,
    Stack,
    Typography
} from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { getCurrentUser } from '../../api/user'
import UserAvatar from '../../components/UserAvatar/UserAvatar'
import { useAuth } from '../../hooks/useAuth'
import { useUnreadCount } from '../../hooks/useUnreadCount'
import { routeEnum } from '../../routes'

interface DrawerProps {
    open: boolean
    onClose: () => void
}

export const Drawer = ({ open, onClose }: DrawerProps) => {
    const { logout } = useAuth()
    const navigate = useNavigate()
    const { data: user } = useQuery({
        queryKey: ['currentUser'],
        queryFn: getCurrentUser
    })
    const { unreadCount } = useUnreadCount()

    const handleLogout = () => {
        logout()
        onClose()
    }

    const menuItems = [
        {
            icon: <ShowChartIcon />,
            text: 'Dashboard',
            onClick: () => {
                navigate(routeEnum.DASHBOARD)
                onClose()
            }
        },
        {
            icon: <PublicIcon />,
            text: 'Discovery',
            onClick: () => {
                navigate(routeEnum.DISCOVERY)
                onClose()
            }
        },
        {
            icon: (
                <Badge 
                    badgeContent={unreadCount} 
                    color="primary"
                    max={99}
                    sx={{
                        '& .MuiBadge-badge': {
                            right: -3,
                            top: 3
                        }
                    }}
                >
                    <MessageIcon />
                </Badge>
            ),
            text: 'Messages',
            onClick: () => {
                navigate(routeEnum.MESSAGES)
                onClose()
            }
        },
        {
            icon: <AlbumIcon />,
            text: 'Sessions',
            onClick: () => {
                navigate(routeEnum.SESSIONS)
                onClose()
            }
        },
        {
            icon: <PermMediaIcon />,
            text: 'Media',
            onClick: () => {
                navigate(routeEnum.MEDIA)
                onClose()
            }
        },
        {
            icon: <PersonIcon />,
            text: 'Profile',
            onClick: () => {
                navigate(routeEnum.PROFILE)
                onClose()
            }
        }
    ]

    return (
        <MuiDrawer
            anchor="right"
            open={open}
            onClose={onClose}
            sx={{
                '& .MuiDrawer-paper': {
                    width: 280,
                    boxSizing: 'border-box',
                    bgcolor: 'background.default',
                    borderRadius: 0
                }
            }}
        >
            <Stack
                sx={{
                    height: '100%',
                    p: 2
                }}
                spacing={2}
            >
                {/* User Header */}
                <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    sx={{
                        pb: 2,
                        borderBottom: 1,
                        borderColor: 'divider'
                    }}
                >
                    <UserAvatar
                        src={user?.avatar}
                        alt={user?.name}
                        userId={user?.id}
                        size={40}
                        showOnlineStatus={false}
                    />
                    <Typography
                        variant="subtitle1"
                        sx={{
                            fontWeight: 500,
                            flex: 1,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                        }}
                    >
                        {user?.name}
                    </Typography>
                </Stack>

                {/* Main Menu Items */}
                <List sx={{ flex: 1 }}>
                    {menuItems.map((item, index) => (
                        <ListItem key={index} disablePadding>
                            <ListItemButton onClick={item.onClick}>
                                <ListItemIcon>
                                    {item.icon}
                                </ListItemIcon>
                                <ListItemText primary={item.text} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>

                {/* Logout Button */}
                <List>
                    <ListItem disablePadding>
                        <ListItemButton onClick={handleLogout}>
                            <ListItemIcon>
                                <LogoutIcon />
                            </ListItemIcon>
                            <ListItemText primary="Logout" />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Stack>
        </MuiDrawer>
    )
}

export default Drawer
