import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    Grid,
    IconButton,
    Stack,
    Switch,
    Tab,
    Tabs,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { format } from 'date-fns'
import { useState } from 'react'
import TimeZoneModal, { detectUserTimeZone } from './TimeZoneModal'

interface AvailabilityModalProps {
    open: boolean
    onClose: () => void
    onSave?: (data: {
        selectedDays: number[]
        dayTimeRanges: { [key: number]: { start: number; end: number } }
        selectedTimeZone: any
        useSameHours: boolean
    }) => void
}

interface TimeRange {
    start: number;
    end: number;
}

// Add a new interface to store availability by day
interface DayAvailability {
    [key: number]: number[]; // day of week (0-6) -> array of hours
}

const AvailabilityModal = ({ open, onClose, onSave }: AvailabilityModalProps) => {
    const [selectedDate, setSelectedDate] = useState<Date | null>(new Date())
    const [selectedTimeSlots, setSelectedTimeSlots] = useState<DayAvailability>({})
    const [selectedDays, setSelectedDays] = useState<number[]>([1, 2, 3, 4, 5]) // Mon-Fri by default
    const [useSameHours, setUseSameHours] = useState(true)
    const [timeZoneModalOpen, setTimeZoneModalOpen] = useState(false)
    const [selectedTimeZone, setSelectedTimeZone] = useState(detectUserTimeZone())
    const [dayTimeRanges, setDayTimeRanges] = useState<{ [key: number]: TimeRange }>({
        1: { start: 12, end: 20 }, // 12 PM to 8 PM
        2: { start: 12, end: 20 },
        3: { start: 12, end: 20 },
        4: { start: 12, end: 20 },
        5: { start: 12, end: 20 }
    })
    const [activeTab, setActiveTab] = useState<number>(0)
    const [timePickerView, setTimePickerView] = useState<'start' | 'end' | null>(null)
    const [tempTimeValue, setTempTimeValue] = useState<{ hour: number, period: string }>({ hour: 12, period: 'PM' })
    const [currentEditingDay, setCurrentEditingDay] = useState<number | null>(null)
    const hours = Array.from({ length: 12 }, (_, i) => i + 1)
    const periods = ['AM', 'PM']

    const days = [
        { value: 0, label: 'Sun' },
        { value: 1, label: 'Mon' },
        { value: 2, label: 'Tue' },
        { value: 3, label: 'Wed' },
        { value: 4, label: 'Thu' },
        { value: 5, label: 'Fri' },
        { value: 6, label: 'Sat' }
    ]

    const formatHour = (hour: number) => {
        if (hour === 0) return '12 AM'
        if (hour < 12) return `${hour} AM`
        if (hour === 12) return '12 PM'
        return `${hour - 12} PM`
    }

    const handleTimeSelect = (type: 'start' | 'end', adjustedHour: number, targetDay?: number) => {
        if (useSameHours) {
            // Update all selected days
            const newRanges = { ...dayTimeRanges }
            selectedDays.forEach(d => {
                newRanges[d] = {
                    ...newRanges[d],
                    [type]: adjustedHour
                }
                // Update availability for each day
                setSelectedTimeSlots(prev => ({
                    ...prev,
                    [d]: Array.from(
                        { length: newRanges[d].end - newRanges[d].start + 1 },
                        (_, i) => newRanges[d].start + i
                    )
                }))
            })
            setDayTimeRanges(newRanges)
        } else {
            // Use the targetDay instead of selectedDays[0]
            const day = targetDay ?? selectedDays[0]
            setDayTimeRanges(prev => {
                const newRange = {
                    ...prev,
                    [day]: {
                        ...prev[day],
                        [type]: adjustedHour
                    }
                }
                // Update availability for this day
                setSelectedTimeSlots(prevSlots => ({
                    ...prevSlots,
                    [day]: Array.from(
                        { length: newRange[day].end - newRange[day].start + 1 },
                        (_, i) => newRange[day].start + i
                    )
                }))
                return newRange
            })
        }
    }

    const handleDayToggle = (day: number) => {
        setSelectedDays(prev => {
            const isSelected = prev.includes(day)
            if (isSelected) {
                // Remove day and its time range
                const newDayTimeRanges = { ...dayTimeRanges }
                delete newDayTimeRanges[day]
                setDayTimeRanges(newDayTimeRanges)

                // Remove availability for this day
                setSelectedTimeSlots(prev => {
                    const newAvailability = { ...prev }
                    delete newAvailability[day]
                    return newAvailability
                })

                return prev.filter(d => d !== day)
            } else {
                // Add day with default time range
                const timeRange = { start: 12, end: 20 }
                setDayTimeRanges(prev => ({
                    ...prev,
                    [day]: timeRange
                }))

                // Add availability for this day
                setSelectedTimeSlots(prev => ({
                    ...prev,
                    [day]: Array.from({ length: timeRange.end - timeRange.start + 1 }, (_, i) => timeRange.start + i)
                }))

                return [...prev, day].sort((a, b) => a - b)
            }
        })
    }

    const handleTimeSlotClick = (hour: number) => {
        if (!selectedDate) return;

        const day = selectedDate.getDay();
        setSelectedTimeSlots(prev => {
            const daySlots = prev[day] || [];
            const newDaySlots = daySlots.includes(hour)
                ? daySlots.filter(h => h !== hour)
                : [...daySlots, hour].sort((a, b) => a - b);

            return {
                ...prev,
                [day]: newDaySlots
            };
        });
    }

    const handleTimePickerOpen = (type: 'start' | 'end', currentValue: number, day: number) => {
        const hour = currentValue % 12 || 12
        const period = currentValue >= 12 ? 'PM' : 'AM'
        setTempTimeValue({ hour, period })
        setCurrentEditingDay(day)
        setTimePickerView(type)
    }

    const handleTempTimeSelect = (hour: number, period: string) => {
        setTempTimeValue({ hour, period })
    }

    const handleSaveTime = (type: 'start' | 'end') => {
        const { hour, period } = tempTimeValue
        const adjustedHour = period === 'PM' && hour !== 12 ? hour + 12 : (period === 'AM' && hour === 12 ? 0 : hour)
        if (currentEditingDay !== null) {
            handleTimeSelect(type, adjustedHour, currentEditingDay)
        }
        setTimePickerView(null)
        setCurrentEditingDay(null)
    }

    const renderTimePicker = (type: 'start' | 'end') => (
        <Dialog
            open={timePickerView === type && currentEditingDay !== null}
            onClose={() => {
                setTimePickerView(null)
                setCurrentEditingDay(null)
            }}
            PaperProps={{
                sx: {
                    borderRadius: 3,
                    width: '100%',
                    maxWidth: 320,
                    bgcolor: 'background.paper'
                }
            }}
        >
            <DialogContent sx={{ p: 3 }}>
                <Stack spacing={3}>
                    <Typography variant="h6" align="center" fontWeight={500}>
                        Select {type === 'start' ? 'Start' : 'End'} Time for {days.find(d => d.value === currentEditingDay)?.label}
                    </Typography>
                    <Stack spacing={3}>
                        <Box sx={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(3, 1fr)',
                            gap: 2
                        }}>
                            {hours.map((hour) => (
                                <Button
                                    key={hour}
                                    variant={tempTimeValue.hour === hour ? "contained" : "outlined"}
                                    onClick={() => handleTempTimeSelect(hour, tempTimeValue.period)}
                                    sx={{
                                        height: 56,
                                        fontSize: '1.25rem',
                                        borderRadius: 2,
                                        borderColor: 'divider'
                                    }}
                                >
                                    {hour}
                                </Button>
                            ))}
                        </Box>
                        <ToggleButtonGroup
                            value={tempTimeValue.period}
                            exclusive
                            onChange={(_, newPeriod) => {
                                if (newPeriod) {
                                    handleTempTimeSelect(tempTimeValue.hour, newPeriod)
                                }
                            }}
                            fullWidth
                            sx={{
                                '& .MuiToggleButton-root': {
                                    py: 1.5,
                                    fontSize: '1rem'
                                }
                            }}
                        >
                            {periods.map((period) => (
                                <ToggleButton
                                    key={period}
                                    value={period}
                                >
                                    {period}
                                </ToggleButton>
                            ))}
                        </ToggleButtonGroup>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                        <Button
                            fullWidth
                            variant="outlined"
                            onClick={() => {
                                setTimePickerView(null)
                                setCurrentEditingDay(null)
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={() => handleSaveTime(type)}
                        >
                            Save
                        </Button>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    )

    const renderQuickSetup = () => (
        <Stack spacing={4} sx={{
            height: '100%',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Box sx={{
                flex: 1,
                overflowY: 'auto',
                px: 3,
                pt: 2,
                pb: 2,
                '&::-webkit-scrollbar': {
                    width: '8px'
                },
                '&::-webkit-scrollbar-track': {
                    backgroundColor: 'transparent'
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                    borderRadius: '4px'
                }
            }}>
                <Stack spacing={2}>
                    {/* Day selector */}
                    <Stack spacing={1.5}>
                        <Typography variant="subtitle1" fontWeight={500}>
                            Available days
                        </Typography>
                        <Stack direction="row" spacing={1} justifyContent="space-between">
                            {days.map(day => (
                                <Button
                                    key={day.value}
                                    onClick={() => handleDayToggle(day.value)}
                                    sx={{
                                        width: 48,
                                        height: 48,
                                        minWidth: 'unset',
                                        borderRadius: '50%',
                                        bgcolor: selectedDays.includes(day.value) ? 'primary.main' : 'transparent',
                                        color: selectedDays.includes(day.value) ? 'white' : 'text.primary',
                                        border: t => `1px solid ${selectedDays.includes(day.value) ? 'transparent' : t.palette.divider}`,
                                        '&:hover': {
                                            bgcolor: selectedDays.includes(day.value) ? 'primary.dark' : 'action.hover'
                                        }
                                    }}
                                >
                                    {day.label}
                                </Button>
                            ))}
                        </Stack>
                    </Stack>

                    {/* Use same hours toggle */}
                    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ py: 1 }}>
                        <Typography>Use same hours for all days</Typography>
                        <ToggleButton
                            value="check"
                            selected={useSameHours}
                            onChange={() => setUseSameHours(!useSameHours)}
                            sx={{
                                border: 'none',
                                '&.Mui-selected': {
                                    bgcolor: 'transparent'
                                }
                            }}
                        >
                            <Switch checked={useSameHours} />
                        </ToggleButton>
                    </Stack>

                    {/* Time ranges */}
                    {useSameHours ? (
                        <Stack spacing={1.5}>
                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                <Typography variant="subtitle1" fontWeight={500}>Hours</Typography>
                                <Button
                                    onClick={() => setTimeZoneModalOpen(true)}
                                    variant="text"
                                    sx={{
                                        color: 'primary.main',
                                        p: 0,
                                        minWidth: 'unset',
                                        '&:hover': {
                                            bgcolor: 'transparent',
                                            textDecoration: 'underline'
                                        }
                                    }}
                                    endIcon={<EditIcon sx={{ fontSize: 16 }} />}
                                >
                                    {selectedTimeZone.shortName ? `${selectedTimeZone.name.split(' - ')[0]} (${selectedTimeZone.shortName})` : selectedTimeZone.name}
                                </Button>
                            </Stack>
                            <Typography variant="body1" sx={{ color: 'text.primary' }}>
                                {selectedDays.length === 1
                                    ? days.find(d => d.value === selectedDays[0])?.label
                                    : selectedDays.length === 5 && selectedDays.every(d => d >= 1 && d <= 5)
                                        ? "Monday - Friday"
                                        : selectedDays.length === 7
                                            ? "Every day"
                                            : selectedDays
                                                .map(d => days.find(day => day.value === d)?.label)
                                                .join(", ")}
                            </Typography>
                            <Stack direction="row" spacing={2} alignItems="center">
                                <Button
                                    variant="outlined"
                                    fullWidth
                                    onClick={() => handleTimePickerOpen('start', dayTimeRanges[selectedDays[0]]?.start || 12, selectedDays[0])}
                                    sx={{ py: 1 }}
                                >
                                    {formatHour(dayTimeRanges[selectedDays[0]]?.start || 12)}
                                </Button>
                                <Typography>to</Typography>
                                <Button
                                    variant="outlined"
                                    fullWidth
                                    onClick={() => handleTimePickerOpen('end', dayTimeRanges[selectedDays[0]]?.end || 20, selectedDays[0])}
                                    sx={{ py: 1 }}
                                >
                                    {formatHour(dayTimeRanges[selectedDays[0]]?.end || 20)}
                                </Button>
                            </Stack>
                        </Stack>
                    ) : (
                        <Stack spacing={2}>
                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                <Typography variant="subtitle1" fontWeight={500}>Hours by day</Typography>
                                <Button
                                    onClick={() => setTimeZoneModalOpen(true)}
                                    variant="text"
                                    sx={{
                                        color: 'primary.main',
                                        p: 0,
                                        minWidth: 'unset',
                                        '&:hover': {
                                            bgcolor: 'transparent',
                                            textDecoration: 'underline'
                                        }
                                    }}
                                    endIcon={<EditIcon sx={{ fontSize: 16 }} />}
                                >
                                    {selectedTimeZone.shortName ? `${selectedTimeZone.name.split(' - ')[0]} (${selectedTimeZone.shortName})` : selectedTimeZone.name}
                                </Button>
                            </Stack>
                            {selectedDays.map(day => (
                                <Stack key={day} direction="row" spacing={2} alignItems="center">
                                    <Typography sx={{ width: 100 }}>{days.find(d => d.value === day)?.label}</Typography>
                                    <Button
                                        variant="outlined"
                                        fullWidth
                                        onClick={() => handleTimePickerOpen('start', dayTimeRanges[day]?.start || 12, day)}
                                        sx={{ py: 1 }}
                                    >
                                        {formatHour(dayTimeRanges[day]?.start || 12)}
                                    </Button>
                                    <Typography>to</Typography>
                                    <Button
                                        variant="outlined"
                                        fullWidth
                                        onClick={() => handleTimePickerOpen('end', dayTimeRanges[day]?.end || 20, day)}
                                        sx={{ py: 1 }}
                                    >
                                        {formatHour(dayTimeRanges[day]?.end || 20)}
                                    </Button>
                                </Stack>
                            ))}
                        </Stack>
                    )}
                </Stack>
            </Box>
            {renderTimePicker('start')}
            {renderTimePicker('end')}
        </Stack>
    )

    const renderFineTune = () => (
        <Stack spacing={3} sx={{
            height: '100%',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Box sx={{
                flex: 1,
                overflowY: 'auto',
                px: 2,
                pt: 2,
                pb: 2,
                '&::-webkit-scrollbar': {
                    width: '8px'
                },
                '&::-webkit-scrollbar-track': {
                    backgroundColor: 'transparent'
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                    borderRadius: '4px'
                }
            }}>
                <Stack spacing={2}>
                    <Box sx={{ flexShrink: 0 }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DateCalendar
                                value={selectedDate}
                                onChange={(newDate) => setSelectedDate(newDate)}
                                showDaysOutsideCurrentMonth
                                fixedWeekNumber={6}
                                sx={{
                                    width: '100%',
                                    height: 'auto',
                                    '& .MuiDayCalendar-header': {
                                        justifyContent: 'space-between',
                                        paddingLeft: '12px',
                                        paddingRight: '12px',
                                        '& .MuiTypography-root': {
                                            color: 'text.secondary',
                                            fontWeight: 500
                                        }
                                    },
                                    '& .MuiDayCalendar-weekContainer': {
                                        justifyContent: 'space-between',
                                        margin: '1px 0',
                                        paddingLeft: '12px',
                                        paddingRight: '12px'
                                    },
                                    '& .MuiPickersCalendarHeader-root': {
                                        paddingLeft: 2,
                                        paddingRight: 2,
                                        marginTop: 0,
                                        marginBottom: 0,
                                        minHeight: '32px'
                                    },
                                    '& .MuiDayCalendar-monthContainer': {
                                        minHeight: 'unset'
                                    },
                                    '& .MuiPickersDay-root': {
                                        width: '36px',
                                        height: '36px',
                                        fontSize: '0.875rem',
                                        margin: '1px',
                                        borderRadius: '50%',
                                        '&.Mui-selected': {
                                            bgcolor: 'primary.main',
                                            '&:hover': {
                                                bgcolor: 'primary.dark'
                                            }
                                        }
                                    },
                                    '& .MuiTypography-root': {
                                        fontSize: '0.875rem'
                                    },
                                    '& .MuiPickersCalendarHeader-label': {
                                        fontSize: '1rem',
                                        fontWeight: 500,
                                        color: 'text.primary'
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </Box>

                    {selectedDate && (
                        <>
                            <Typography variant="subtitle1" sx={{ fontWeight: 500, mb: 1 }}>
                                Available Hours for {format(selectedDate, 'EEEE, MMMM d')}
                            </Typography>

                            <Grid
                                container
                                spacing={1.5}
                                sx={{
                                    maxWidth: 400,
                                    mx: 'auto'
                                }}
                            >
                                {Array.from({ length: 24 }, (_, i) => i).map((hour) => (
                                    <Grid item xs={4} key={hour}>
                                        <Button
                                            variant={selectedTimeSlots[selectedDate.getDay()]?.includes(hour) ? "contained" : "outlined"}
                                            fullWidth
                                            onClick={() => handleTimeSlotClick(hour)}
                                            sx={{
                                                py: 1,
                                                borderColor: 'divider',
                                                color: selectedTimeSlots[selectedDate.getDay()]?.includes(hour) ? 'white' : 'text.primary',
                                                '&:hover': {
                                                    bgcolor: selectedTimeSlots[selectedDate.getDay()]?.includes(hour) ? 'primary.dark' : 'action.hover'
                                                }
                                            }}
                                        >
                                            {formatHour(hour)}
                                        </Button>
                                    </Grid>
                                ))}
                            </Grid>
                        </>
                    )}
                </Stack>
            </Box>
        </Stack>
    )

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullScreen
            PaperProps={{
                sx: {
                    bgcolor: 'background.default'
                }
            }}
        >
            <Stack
                width="100%"
                height="100vh"
                sx={{
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                {/* Fixed Header */}
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                    p={2}
                    borderBottom={1}
                    borderColor="divider"
                    sx={{ flexShrink: 0 }}
                >
                    <Typography variant="h6" fontWeight={500}>
                        Manage Availability
                    </Typography>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Stack>

                {/* Tabs */}
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        value={activeTab}
                        onChange={(_, newValue) => setActiveTab(newValue)}
                        variant="fullWidth"
                        indicatorColor="primary"
                        textColor="primary"
                        sx={{
                            '& .MuiTabs-flexContainer': {
                                justifyContent: 'center'
                            },
                            '& .MuiTab-root': {
                                flex: 1,
                                maxWidth: 'none',
                                fontSize: '1rem',
                                textTransform: 'none'
                            }
                        }}
                    >
                        <Tab label="Availability" />
                        <Tab label="Date Overrides" sx={{ display: 'none' }} />
                    </Tabs>
                </Box>

                {/* Main Content Container */}
                <Stack
                    width="100%"
                    maxWidth={1200}
                    mx="auto"
                    sx={{
                        flex: 1,
                        minHeight: 0,
                        overflow: 'hidden'
                    }}
                >
                    {activeTab === 0 ? renderQuickSetup() : renderFineTune()}
                </Stack>

                {/* Fixed Footer */}
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    width="100%"
                    p={2}
                    borderTop={1}
                    borderColor="divider"
                    sx={{ flexShrink: 0 }}
                >
                    <Button
                        variant="text"
                        onClick={onClose}
                        sx={{ color: 'text.primary' }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        sx={{
                            bgcolor: 'black',
                            color: 'white',
                            minWidth: '120px',
                            '&:hover': {
                                bgcolor: 'rgba(0, 0, 0, 0.8)'
                            }
                        }}
                        onClick={() => {
                            if (onSave) {
                                onSave({
                                    selectedDays,
                                    dayTimeRanges,
                                    selectedTimeZone,
                                    useSameHours
                                })
                            }
                            onClose()
                        }}
                    >
                        Done
                    </Button>
                </Stack>
            </Stack>

            <TimeZoneModal
                open={timeZoneModalOpen}
                onClose={() => setTimeZoneModalOpen(false)}
                selectedTimeZone={selectedTimeZone}
                onTimeZoneSelect={(zone) => {
                    setSelectedTimeZone(zone)
                    setTimeZoneModalOpen(false)
                }}
            />
        </Dialog>
    )
}

export default AvailabilityModal 