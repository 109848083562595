import { createContext, useContext, useState, type FC, type ReactNode } from 'react'
import { type PaletteMode } from '@mui/material'

interface ThemeContextType {
    mode: PaletteMode
    toggleColorMode: () => void
}

const ThemeContext = createContext<ThemeContextType>({
    mode: 'light',
    toggleColorMode: () => undefined,
})

export const useTheme = () => useContext(ThemeContext)

interface ThemeProviderProps {
    children: ReactNode
}

export const ThemeProvider: FC<ThemeProviderProps> = ({ children }) => {
    const [mode, setMode] = useState<PaletteMode>('light')

    const toggleColorMode = () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'))
    }

    return (
        <ThemeContext.Provider value={{ mode, toggleColorMode }}>
            {children}
        </ThemeContext.Provider>
    )
} 