import CloseIcon from '@mui/icons-material/Close'
import { Box, IconButton, Stack } from '@mui/material'
import Image from '../Image/Image'

interface ModalHeaderProps {
    onClose: () => void
}

const ModalHeader = ({ onClose }: ModalHeaderProps) => {
    return (
        <Stack
            pr={3}
            pl={4.5}
            py={1.5}
            top={0}
            zIndex={1100}
            borderBottom={1}
            position="sticky"
            borderColor="divider"
            justifyContent="space-between"
            alignItems="center"
            bgcolor="background.default"
            direction="row"
        >
            <Box flex={1} maxWidth={35}>
                <Image
                    src="/assets/img/artistlink-badge.svg"
                    alt="ArtistLink Logo"
                    height={35}
                />
            </Box>
            <Box>
                <IconButton
                    color="inherit"
                    aria-label="close modal"
                    onClick={onClose}
                    sx={{
                        padding: 1.5,
                        '& .MuiSvgIcon-root': {
                            fontSize: 32
                        }
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </Box>
        </Stack>
    )
}

export default ModalHeader 