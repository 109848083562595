import { useCallback, useState, useRef, useEffect } from 'react'
import { MessageDto, SendMessageRequest } from '../types/conversation'
import { useWebSocket, ReadStatusUpdate, TypingStatusUpdate } from './useWebSocket'

interface UseChatOptions {
    onMessage?: (message: MessageDto) => void
    onError?: (error: any) => void
    onReadReceipt?: (update: ReadStatusUpdate) => void
    onTypingStatus?: (update: TypingStatusUpdate) => void
    onTypingStart?: (userId: string, userName: string) => void
}

export const useChat = ({ onMessage, onError, onReadReceipt, onTypingStatus, onTypingStart }: UseChatOptions = {}) => {
    const [isConnected, setIsConnected] = useState(false)
    const [error, setError] = useState<Error | null>(null)
    const typingTimeoutRef = useRef<Record<string, NodeJS.Timeout>>({})
    const typingUsersRef = useRef<Set<string>>(new Set())

    const handleMessage = useCallback((message: MessageDto) => {
        console.debug('Received WebSocket message:', message)
        onMessage?.(message)
    }, [onMessage])

    const handleError = useCallback((error: any) => {
        console.error('Chat error:', error)
        setError(error instanceof Error ? error : new Error('Chat error'))
        onError?.(error)
    }, [onError])

    const handleReadReceipt = useCallback((update: ReadStatusUpdate) => {
        console.debug('Received read receipt:', update)
        onReadReceipt?.(update)
    }, [onReadReceipt])

    const handleTypingStatus = useCallback((update: TypingStatusUpdate) => {
        console.debug('Received typing status:', update)
        
        // If user starts typing and wasn't typing before, trigger onTypingStart
        if (update.isTyping && !typingUsersRef.current.has(update.userId)) {
            typingUsersRef.current.add(update.userId)
            onTypingStart?.(update.userId, update.userName)
        }
        
        // If user stops typing, remove from tracking set
        if (!update.isTyping) {
            typingUsersRef.current.delete(update.userId)
        }

        onTypingStatus?.(update)
    }, [onTypingStatus, onTypingStart])

    const { 
        isConnected: wsConnected, 
        error: wsError, 
        sendMessage: wsSendMessage,
        markMessagesAsRead: wsMarkMessagesAsRead,
        sendTypingStatus: wsSendTypingStatus
    } = useWebSocket({
        onMessage: handleMessage,
        onError: handleError,
        onReadReceipt: handleReadReceipt,
        onTypingStatus: handleTypingStatus
    })

    // Update local connection state based on WebSocket state
    useEffect(() => {
        setIsConnected(wsConnected)
        if (wsError) {
            setError(wsError)
        }
    }, [wsConnected, wsError])

    const sendMessage = useCallback((conversationId: string, request: SendMessageRequest) => {
        if (!wsConnected) {
            const error = new Error('Chat not connected')
            handleError(error)
            return
        }

        try {
            console.debug('Sending message to conversation:', conversationId)
            console.debug('Message request:', {
                content: request.content,
                fileKey: request.fileKey,
                fileName: request.fileName,
                fileType: request.fileType,
                fileSize: request.fileSize
            })
            
            wsSendMessage(conversationId, request)
            console.debug('Message sent successfully')
        } catch (error) {
            console.error('Error sending message:', error)
            handleError(error)
        }
    }, [wsConnected, wsSendMessage, handleError])

    const markMessagesAsRead = useCallback(async (conversationId: string) => {
        try {
            console.debug('Marking messages as read for conversation:', conversationId)
            await wsMarkMessagesAsRead(conversationId)
            console.debug('Successfully marked messages as read')
        } catch (error) {
            console.error('Error marking messages as read:', error)
            handleError(error)
        }
    }, [wsMarkMessagesAsRead, handleError])

    const sendTypingStatus = useCallback((conversationId: string, isTyping: boolean) => {
        if (!wsConnected) {
            console.error('Chat not connected')
            return
        }

        try {
            console.debug('Sending typing status:', { conversationId, isTyping })
            wsSendTypingStatus(conversationId, isTyping)
            console.debug('Typing status sent successfully')
        } catch (error) {
            console.error('Error sending typing status:', error)
            handleError(error)
        }
    }, [wsConnected, wsSendTypingStatus, handleError])

    // Cleanup typing timeouts and tracking on unmount
    useEffect(() => {
        return () => {
            Object.values(typingTimeoutRef.current).forEach(clearTimeout)
            typingUsersRef.current.clear()
        }
    }, [])

    return {
        isConnected,
        error,
        sendMessage,
        markMessagesAsRead,
        sendTypingStatus
    }
}

export default useChat 